import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import RemoveIcon from '@mui/icons-material/Remove';
import './CustomSearch.css';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { icons } from '../../base/constants/ImageConstants';
import { useDispatch, useSelector } from 'react-redux';
import * as Type from '../../Redux/Types';
export const CustomSearch = () => {
  const [expanded, setExpanded] = React.useState('');
  const dispatch = useDispatch();
  const filtersData = useSelector((state) => state?.inventoryReducer?.filtersData);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          '.Mui-expanded & > .collapsIconWrapper': {
            display: 'none',
          },
          '.expandIconWrapper': {
            display: 'none',
          },
          '.Mui-expanded & > .expandIconWrapper': {
            display: 'block',
          },
        }}
      >
        <div className='expandIconWrapper'>
          <RemoveIcon />
        </div>
        <div className='collapsIconWrapper'>
          <AddIcon />
        </div>
      </Box>
    );
  };
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '10px',
    backgroundColor: '#fff',
    border: '1px solid #E0E0E0',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    height: '46px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(5),
      width: '180px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '55px',
      width: '290px',
    },
  }));
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#333',
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#000',
    fontSize: '14px',
    fontWeight: 400,
    '& .MuiInputBase-input': {
      padding: '7px 8px 8px 0px',
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '31ch',
      },
    },
  }));
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role='presentation'
      className='customize_box'
    >
      <img
        onClick={() => setState({ ...state, [anchor]: false })}
        src={icons.closeIcon}
        alt=''
        className='close_btn'
      />
      <h2>Customize your search </h2>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<CustomExpandIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>BRAND</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{ color: 'red', fontStyle: 'italic' }}
            onClick={() => {
              dispatch({ type: Type.SET_BRAND_FILTERS, payload: '' });
              setExpanded(false);
            }}
            onChange={handleChange('panel2')}
          >
            Reset
          </div>
          {filtersData?.brand?.map((brand, index) => (
            <Typography key={index}>
              <hr />
              <div
                onClick={(e) => {
                  dispatch({ type: Type.SET_BRAND_FILTERS, payload: e.target.textContent });
                  setExpanded(false);
                }}
                onChange={handleChange('panel1')}
              >
                {brand}
              </div>
            </Typography>
          ))}
          {/* 
          <Typography>Ajanta</Typography>
          <Typography>Opal</Typography>
          <Typography>Seiko</Typography> */}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<CustomExpandIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography>MODEL</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{ color: 'red', fontStyle: 'italic' }}
            onClick={() => {
              dispatch({ type: Type.SET_MODEL_FILTERS, payload: '' });
              setExpanded(false);
            }}
            onChange={handleChange('panel2')}
          >
            Reset
          </div>
          {filtersData?.model?.map((model, index) => (
            <Typography key={index}>
              <hr />
              <div
                onClick={(e) => {
                  dispatch({ type: Type.SET_MODEL_FILTERS, payload: e.target.innerHTML });
                  setExpanded(false);
                }}
                onChange={handleChange('panel2')}
              >
                {model}
              </div>
            </Typography>
          ))}
          {/* <Typography>Ajanta</Typography>
          <Typography>Opal</Typography>
          <Typography>Seiko</Typography> */}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<CustomExpandIcon />}
          aria-controls='panel3a-content'
          id='panel3a-header'
        >
          <Typography>CASE SIZE</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{ color: 'red', fontStyle: 'italic' }}
            onClick={() => {
              dispatch({ type: Type.SET_CASE_SIZE_FILTERS, payload: '' });
              setExpanded(false);
            }}
            onChange={handleChange('panel2')}
          >
            Reset
          </div>
          {filtersData?.case_size?.map((caseSize, index) => (
            <Typography key={index}>
              <hr />
              <div
                onClick={(e) => {
                  dispatch({ type: Type.SET_CASE_SIZE_FILTERS, payload: e.target.innerHTML });
                  setExpanded(false);
                }}
              >
                {caseSize}
              </div>
            </Typography>
          ))}
          {/* <Typography>Ajanta</Typography>
          <Typography>Opal</Typography>
          <Typography>Seiko</Typography> */}
        </AccordionDetails>
      </Accordion>
      {/* <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<CustomExpandIcon />}
          aria-controls='panel4a-content'
          id='panel4a-header'
        >
          <Typography>BAND MATERIAL</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Lorem ipsum dolor sit amet, consectetur</Typography>
        </AccordionDetails>
      </Accordion> */}

      <Search className='search_mob' sx={{ display: { xs: '', md: 'flex' } }}>
        <SearchIconWrapper>
          <img src={icons.SearchIcon} className='' alt='Search' />
        </SearchIconWrapper>
        <StyledInputBase placeholder='Search here...' inputProps={{ 'aria-label': 'search' }} />
      </Search>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} className='btn2'>
            {' '}
            Customize search
          </Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};
