import React from 'react';
import Slider from 'react-slick';
import { icons } from '../../base/constants/ImageConstants';
import './ProductSlider.css';
export const ProductSlider = ({ sliderImages, setImage }) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    arrows: sliderImages?.length >= 4 ? true : false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: sliderImages?.length >= 4 ? true : false
        },
      },
      {
        breakpoint: 767,
        settings: {
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          arrows: sliderImages?.length >= 3 ? true : false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: sliderImages?.length >= 2 ? true : false,
        },
      },
    ],

    verticalSwiping: true,
  };

  return (
    <div className='vertical_slider'>
      <Slider {...settings}>
        {sliderImages &&
          Object.values(sliderImages)?.map((data, index) => (
            <div key={index}>
              <h3>
                <img src={data?.images || icons.ProductWatch} alt='' onClick={()=>setImage(data?.images)}/>{' '}
              </h3>
            </div>
          ))}
        {/* <div>
          <h3>
            <img src={icons.ProductWatch3} alt='' />
          </h3>
        </div>
        <div>
          <h3>
            <img src={icons.ProductWatch4} alt='' />
          </h3>
        </div>
        <div>
          <h3>
            <img src={icons.ProductWatch5} alt='' />
          </h3>
        </div>
        <div>
          <h3>
            <img src={icons.ProductWatch6} alt='' />
          </h3>
        </div> */}
      </Slider>
    </div>
  );
};
