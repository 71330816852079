import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { INVENTORY_LIST_URL } from '../../base/constants/apiEndPoints';
import { icons } from '../../base/constants/ImageConstants';
import { AddToFavourite } from '../../base/utils/utils';
import { fetchFavoriteData } from '../../Redux/Actions';
import { RESET_STATE } from '../../Redux/Types';
import './Favorite.css';
export const Favorites = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state?.inventoryReducer?.favoriteData?.results);
  const isRefresh = useSelector((state) => state?.appReducer?.refresh);

  const getFavoriteData = () => {
    dispatch(
      fetchFavoriteData(INVENTORY_LIST_URL, {
        ordering: 'saved',
        feed: 'live',
      }),
    );
  };
  useEffect(() => {
    getFavoriteData();
    return () => {
      dispatch({ type: RESET_STATE });
    };
  }, [dispatch, isRefresh]);
  return (
    <div className='container favorites'>
      <div className='row'>
        {data?.length > 0 ? (
          data?.map((data) => (
            <div key={data?.id} className='col-lg-3 col-md-3 col-sm-6 col-6'>
              <div className='card' onClick={() => navigate(`/productview/watch/${data?.id}`)}>
                <div className='card_img'>
                  <img src={data?.main_image || icons.Drop1} className='watch_img' alt='Watch' />
                </div>
                <a href='#'>
                  <div className='card_body text-left'>
                    <h5>
                      {data?.brand || 'H. Moser & Cie.'} | {data?.model || '3804-1205'}
                    </h5>
                    {/* <h6>Pioneer Tourbillon</h6> */}
                    {/* <p>{data?.model || '3804-1205'}</p> */}
                    <h3>
                      {data?.city || 'Ca'} | {data?.state || 'Whittier'}{' '}
                    </h3>
                    <span>${Math.round(data?.ask_price)?.toLocaleString('en-US') || 0}</span>
                  </div>
                </a>
              </div>
              <img
                src={data?.is_saved ? icons.Filled_Bookmark : icons.Bookmark}
                className='bookmark_img'
                alt='Bookmark'
                onClick={() => AddToFavourite(data?.id, true, navigate)}
              />
              <img
                src={data?.is_featured ? icons.StarIcon : icons.StarIcon2}
                className='star_icon'
                alt=''
              />
            </div>
          ))
        ) : (
          <h3 className='no_favourites'>No favorites found !</h3>
        )}
      </div>
    </div>
  );
};
