import * as React from 'react';
import { icons } from '../../base/constants/ImageConstants';
import './CropImage.css';
import { CustomizedDialogs } from './CustomizedDialogs';

export const CropImage = ({ setImg, page }) => {
  const [imageToCrop, setImageToCrop] = React.useState(undefined);

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        const image = reader.result;

        setImageToCrop(image);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const setRemoveImage = () => {
    setImageToCrop(undefined);
  };

  return (
    <div className='app'>
      <input type='file' accept='image/*' onChange={onUploadFile} />
      <img src={icons.Pencil} alt='edit' onChange={onUploadFile} />

      {imageToCrop !== undefined && (
        <CustomizedDialogs
          imageToCrop={imageToCrop}
          setRemoveImage={setRemoveImage}
          setImg={setImg}
          page={page}
        />
      )}
    </div>
  );
};
