import { combineReducers } from 'redux';
import { appReducer } from './AppReducer';
import { userReducer } from './UserReducer';
import { inventoryReducer } from './InventoryReducer';
import { brandsReducer } from './BrandsReducer';
import { landingReducer } from './LandingReducer';
import { productViewReducer } from './ProductViewReducer';
import { formReducer } from './FormReducer';
const rootReducer = combineReducers({
  appReducer,
  userReducer,
  inventoryReducer,
  brandsReducer,
  landingReducer,
  productViewReducer,
  formReducer,
});
export default rootReducer;
