import axios from 'axios';
import { loader } from '../../Redux/Actions/AppAction';
import { token } from '../constants/AppConstant';
import store from '../../Redux/Store';
import { refreshToken } from '../utils/utils';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const configuration = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data: boundary=add-random-characters',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': [
      'Origin',
      'Accept',
      'X-Requested-With',
      'Content-Type',
      'Authorization',
    ],
  },
};
const instance = axios.create({ configuration });
instance.interceptors.request.use(
  (config) => {
    config?.method === 'post' && store.dispatch(loader(true));
    const authToken = localStorage.getItem(token) || null;
    let newHeaders = config.headers;
    if (authToken) {
      newHeaders = Object.assign({}, newHeaders, {
        Authorization: `Bearer ${authToken}`,
      });
    }
    return Object.assign({}, config, { headers: newHeaders });
  },
  (error) => Promise.reject(error),
);
instance.interceptors.response.use(
  (response) => {
    store.dispatch(loader(false));
    return response;
  },
  async (error) => {
    store.dispatch(loader(false));
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const authToken = await refreshToken();
      localStorage.setItem(token, authToken);
      return instance(originalRequest);
    }
    return Promise.reject(error?.response);
  },
);
const getAxios = () => instance;

export default getAxios;
