import { InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import './AutoComplete.css';

export const AutoComplete = ({ data, error, field, form, label, placeholder }) => {
  const [suggestions, setSuggestions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    const query = e.target.value?.trim();
    form.setFieldValue(field.name, value);
    setValue(query);
    if (query.length >= 1) {
      const filterSuggestions = data.filter((suggestion) =>
        suggestion.toLowerCase().startsWith(query.toLowerCase()),
      );
      setSuggestions(filterSuggestions);
      setSuggestionsActive(true);
    } else {
      setSuggestionsActive(false);
    }
  };
  const handleClick = (e) => {
    setSuggestions([]);
    setValue(e.target.innerText);
    form.setFieldValue(field.name, e.target.innerText);
    setSuggestionsActive(false);
  };

  function setFormValue(val) {
    if (val) {
      setValue(val);
      form?.setFieldValue(field?.name, val);
    }
  }

  function handleIncrementDecrement(keyCode = 0, values = []) {
    if (values?.length === 0 || !value || keyCode === 0) {
      return;
    }

    if (![38, 40, 13].includes(keyCode)) {
      return;
    }

    if (value && keyCode === 13) {
      setFormValue(value);
      setSuggestionsActive(false);
    }

    if (values.includes(value)) {
      const idx = values.findIndex((s) => s === value);
      if (idx !== -1) {
        let updatedIdx = idx;
        if (keyCode === 38) {
          updatedIdx -= 1;
        }
        if (keyCode === 40) {
          updatedIdx += 1;
        }
        const foundValue = values[updatedIdx];
        if (!foundValue) {
          return;
        }
        setFormValue(foundValue);
      }
    }

    if (!values.includes(value)) {
      const foundValue = values[0];
      if (!foundValue) {
        return;
      }
      setFormValue(foundValue);
    }
  }

  const handleKeyDown = (e) => {
    handleIncrementDecrement(e.keyCode, suggestions);
  };

  const Suggestions = () => {
    return (
      <>
        {suggestions.length > 0 && (
          <ul className='suggestions'>
            {suggestions.map((suggestion, index) => {
              return (
                <li
                  className={index === suggestionIndex ? 'active' : ''}
                  key={index}
                  onClick={handleClick}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        )}
      </>
    );
  };

  return (
    <div className='autocomplete'>
      <InputLabel error={error} className='inputLabel' htmlFor='outlined-adornment-amount'>
        Brand
      </InputLabel>
      <OutlinedInput
        id={field.name}
        value={value}
        error={error}
        label={label}
        onBlur={() => {
          const valuefound = document.querySelector(`#${field.name}`)?.value;
          if (!valuefound) {
            form.setFieldTouched(field.name, true);
            form.setFieldError(field.name, `Enter a valid URL`);
          }
        }}
        placeholder={placeholder}
        startAdornment={<InputAdornment position='start' />}
        onChange={(e) => {
          handleChange(e);
        }}
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
      />
      {suggestionsActive && <Suggestions />}
    </div>
  );
};
