import {
  CLOSE_DETAIL_MODAL,
  CLOSE_MODAL,
  HANDLE_SCROLL_DIALOG,
  OPEN_DETAIL_MODAL,
  OPEN_LOGIN_MODAL,
  OPEN_MODAL,
  REFRESH,
  SET_LOADING,
  STORE_LOCATION,
} from '../Types';

const initialState = {
  title: '',
  message: '',
  refresh: false,
  isLoading: false,
  showLoginModal: false,
  showModal: false,
  showScrollModal: false,
  showDetailModal: false,
  action: '',
  location: '',
};
export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        showModal: true,
        title: action.title,
        message: action.message,
        success: action.success,
        showButtons: action.showButtons,
        data: action.data,
        action: action.action,
      };
    case OPEN_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: action.payload,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
      };
    case REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case HANDLE_SCROLL_DIALOG:
      return {
        ...state,
        showScrollModal: !state.showScrollModal,
      };
    case OPEN_DETAIL_MODAL:
      return {
        ...state,
        showDetailModal: true,
        title: action.title,
        message: action.message,
        success: action.success,
        showButtons: action.showButtons,
        data: action.data,
        action: action.action,
      };
    case CLOSE_DETAIL_MODAL:
      return {
        ...state,
        showDetailModal: false,
      };
    case STORE_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    default:
      return state;
  }
};
