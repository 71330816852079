import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_BEST_DEAL_URL } from '../../base/constants/apiEndPoints';
import { icons } from '../../base/constants/ImageConstants';
import * as Path from '../../base/constants/RoutePath';
import { AddToFavourite } from '../../base/utils/utils';
import { getBestDeal } from '../../Redux/Actions';
import { BestDealSlider } from '../MobileView';
import './BestDeal.css';

export const BestDeal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bestDealData = useSelector((state) => state?.landingReducer?.bestDealData);
  const isRefresh = useSelector((state) => state.appReducer.refresh);
  const navigateRoutes = (route) => {
    navigate(route);
  };
  useEffect(() => {
    const getBestDealData = async () => {
      await dispatch(getBestDeal(GET_BEST_DEAL_URL));
    };
    getBestDealData();
  }, [isRefresh]);

  return (
    <section className='best_del'>
      <Container className='just_droped '>
        <h2>Most Popular</h2>
        <p className='hub_heading'>
          Browse through pre-owned watches listed recently on POST and start offer!
        </p>
        <div className='row just_drop_mob_hide'>
          {bestDealData?.slice(0, 4)?.map((data) => (
            <div key={data?.id} className='col-lg-3 col-md-3 col-12'>
              <div
                className='card'
                onClick={() => navigateRoutes(`/productview/watch/${data?.id}`)}
              >
                <div className='card_img'>
                  <img src={data?.main_image || icons.Drop1} className='watch_img' alt='Watch' />
                </div>
                <a>
                  <div className='card_body text-left'>
                    <h5
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigateRoutes(`/productview/watch/${data?.id}`)}
                    >
                      {data?.brand} | {data?.model || '126610LV'}
                    </h5>
                    {/* <h6>Rolex Submariner Date</h6> */}
                    {/* <p>{data?.model || '126610LV'}</p> */}
                    <h3>
                      {data?.state || 'Ca'} | {data?.city || 'Whittier'}
                    </h3>
                    <span>${Math.round(data?.ask_price)?.toLocaleString() || '22,133'}</span>
                  </div>
                </a>
              </div>
              <img
                src={data?.is_saved ? icons.Filled_Bookmark : icons.Bookmark}
                className='bookmark_img'
                alt='Bookmark'
                onClick={() => AddToFavourite(data?.id, true, navigate)}
              />
              {/* <img src={icons.StarIcon2} className='star_icon' alt='' /> */}
            </div>
          ))}
          <div className='col-lg-12 inventory_btn'>
            <button onClick={() => navigateRoutes(Path.INVENTORY_PAGE)}>See More</button>
          </div>
        </div>
        <div className='drop_slider'>
          <BestDealSlider />
          <div className='col-lg-12 inventory_btn '>
            <button onClick={() => navigateRoutes(Path.INVENTORY_PAGE)}>See More </button>
          </div>
        </div>
      </Container>
    </section>
  );
};
