import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { ImageCropper } from './ImgPreview';
import './CropImage.css';
import { updateProfilePicAPI } from '../../Redux/Actions/UserAction';
import { UPDATE_PROFILE_PIC_URL } from '../../base/constants/apiEndPoints';
import { UPDATE_PROFILE_PIC_TITLE } from '../../base/constants/modalTitles';
import { blobToFile } from '../../base/utils/stringUtils';
import { openModal, pageRefresh } from '../../Redux/Actions/AppAction';
import { useDispatch, useSelector } from 'react-redux';
import { DragDrop } from '../DragDrop';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const CustomizedDialogs = ({
  imageToCrop,
  setRemoveImage,
  setImg,
  page,
  onBlob,
  files,
  uploadedFiles,
  setImageToCrop,
  setUploadedFiles,
  imageName,
  setImageName,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [croppedImage, setCroppedImage] = React.useState(undefined);
  const [blob, setBlob] = React.useState(undefined);
  const isRefresh = useSelector((state) => state.appReducer.refresh);
  React.useEffect(() => {}, [isRefresh]);
  const handleSaveChange = () => {
    if (uploadedFiles?.length > files?.length) {
      const modalResponse = {
        title: 'Image Select',
        success: false,
        message: `Please crop all images`,
      };
      dispatch(openModal(modalResponse));
    } else {
      setOpen(false);
      setRemoveImage();
    }
  };
  const handleClose = () => {
    setOpen(false);
    setRemoveImage();
  };
  const onUploadFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const image = reader.result;
        setImageName(file.path);
        setImageToCrop(image);
        dispatch(pageRefresh());
      });
      reader.readAsDataURL(file);
    }
  };
  const onUploadFileFromDialog = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const image = reader.result;
        setImageToCrop(image);
        setImageName(file[0].path);
        dispatch(pageRefresh());
      });
      reader.readAsDataURL(file[0]);
    }
  };
  const handleFileChange = async (acceptedFiles) => {
    acceptedFiles.map(async (img) => {
      const result = await uploadedFiles.every((item) => {
        if (img.path === item.path) {
          const modalResponse = {
            title: 'Image Select',
            success: false,
            message: `${img.path} is already uploaded`,
          };
          dispatch(openModal(modalResponse));
          return false;
        } else return true;
      });
      if (result) {
        uploadedFiles.push(img);
      }
    });
    onUploadFileFromDialog(acceptedFiles);
  };
  const handleFileUpload = async () => {
    if (page === 'my-account') {
      const formData = new FormData();
      const file = blobToFile(blob, 'profile.png');
      formData.append('profile_pic', file);
      handleClose();
      const response = await updateProfilePicAPI(UPDATE_PROFILE_PIC_URL, formData);
      if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
        const filterResponse = {
          title: UPDATE_PROFILE_PIC_TITLE,
          message: response?.data?.message,
          success: true,
        };
        await dispatch(openModal(filterResponse));
        await dispatch(pageRefresh());
      } else {
        const filterResponse = {
          title: UPDATE_PROFILE_PIC_TITLE,
          message: response?.data?.message,
          success: false,
        };
        dispatch(openModal(filterResponse));
        await dispatch(pageRefresh());
      }
    } else if (page === 'register') {
      // setImg(croppedImage);
      // handleClose();
    } else if (page === 'appraisal') {
      // handleClose();
    }
  };
  const deleteImg = (index) => {
    const updatedArray = uploadedFiles.filter((x, i) => i !== index);
    setUploadedFiles(updatedArray);
  };
  const selectedCropped = () => {
    page === 'my-account'
      ? setImg(croppedImage)
      : page === 'register'
      ? onBlob(blob)
      : page === 'appraisal'
      ? onBlob(blob)
      : '';
    handleFileUpload();
  };
  return (
    <>
      <div className='custom_dialog'>
        <BootstrapDialog
          // onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          PaperProps={{
            sx: {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
            Add Image
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography>
              <div>
                <ImageCropper
                  imageToCrop={imageToCrop}
                  onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
                  onCropBlob={(blob) => setBlob(blob)}
                  page={page}
                  imageName={imageName}
                />
              </div>
              {croppedImage && (
                <div
                  className={
                    page === 'register' || page === 'appraisal'
                      ? 'cropped_img'
                      : 'cropped_img_profile'
                  }
                >
                  <h2>Cropped Image</h2>
                  <img alt='Cropped Img' src={croppedImage} />
                </div>
              )}
            </Typography>
          </DialogContent>
          {uploadedFiles?.length > 0 && <h6 className='image-preview'>Image Preview</h6>}
          <div className='preview-container'>
            {uploadedFiles?.length > 0 && (
              <div className='preview_img'>
                {uploadedFiles?.map((img, key) => {
                  return (
                    <>
                      <img
                        key={key}
                        // onMouseEnter={(e) => onShowPreview(e)}
                        // onMouseLeave={() => setShowPreview('')}
                        src={window.URL.createObjectURL(img)}
                        alt='priviewImg'
                        onClick={() => onUploadFile(img)}
                      />
                      <CloseIcon className='cross_img' onClick={() => deleteImg(key)} />
                    </>
                  );
                })}
              </div>
            )}
            {uploadedFiles?.length >= 0 && uploadedFiles?.length < 6 && (
              <div style={{ cursor: 'pointer' }}>
                <DragDrop
                  modalOpen={true}
                  fileCount={uploadedFiles}
                  handleFileChange={handleFileChange}
                />
              </div>
            )}
          </div>
          <DialogActions>
            <Button
              disabled={files?.length === 6}
              autoFocus
              onClick={() => croppedImage && selectedCropped()}
            >
              {page === 'my-account' ? 'Save changes' : 'Upload'}
            </Button>
            {(page === 'register' || page === 'appraisal') && (
              <Button autoFocus onClick={() => handleSaveChange()}>
                Save changes
              </Button>
            )}
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};
