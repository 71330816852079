import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { icons } from '../../base/constants/ImageConstants';
import { HomeBanner, JustDropped, BrandFocus, Inventory, BestDeal } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Path from '../../base/constants/RoutePath';
import { isAuthenticated } from '../../base/utils/utils';
import { TransactionSlider } from '../../components/TransactionSlider/TransactionSlider';
import './Landing.css';
import Plyr from '../../components/Video';

export const Landing = () => {
  const navigate = useNavigate();
  const isRefresh = useSelector((state) => state?.appReducer?.refresh);
  useEffect(() => {}, [isRefresh]);
  const handleNavigate = () => {
    if (isAuthenticated()) {
      navigate(Path.APPRAISAL_PAGE);
    } else {
      navigate(Path.REGISTER_PAGE);
    }
  };
  return (
    <div>
      <HomeBanner />
      <div className='home_content'>
        <JustDropped />
        <div className='purpose'>
          <div className='container'>
            <div className='purposecontain d-md-block d-none'>
              <h2>Maximum exposure</h2>
              <p>minimal effort</p>
              <button onClick={handleNavigate}>
                Get your free appraisal <img src={icons.Arrowright} alt='Arrow' />
              </button>
            </div>
            <div className='purposecontain d-md-none d-block'>
              <h2>Maximum exposure</h2>
              <p>minimal effort</p>
              <button onClick={handleNavigate}>
                Get your free appraisal <img src={icons.Arrowright} alt='Arrow' />{' '}
              </button>
              <div className='purpose_mob mt-2 d-md-none d-block'>
                <img src={icons.Purpose} alt='Purpose' />
              </div>
            </div>
          </div>
        </div>
        <BrandFocus />
        <div className='about_section '>
          <Container className='videio_full_width'>
            <h2 className='text-left pl-md-0 '>About Post</h2>
            <div className='about_video mt-md-3 mt-0'>
              <Plyr
                className='d-md-none d-block'
                source={{
                  type: 'video',
                  sources: [
                    {
                      src: 'https://watch-prod.s3.us-east-2.amazonaws.com/videos/Albert-Final-Video-comp.mp4',
                    },
                  ],
                }}
              />
            </div>
          </Container>
        </div>

        <div className='watch_banner1 mt-md-4 mb-md-0 mb-3 mt-2'>
          {/* ------------------- Desktop View Start ------------------- */}
          <Container>
            <Row>
              <Col
                md={{ span: 6, offset: 1 }}
                lg={{ span: 6, offset: 1 }}
                xl={{ span: 5, offset: 2 }}
              >
                <div className='banner_form1'>
                  <h2>
                    the best price <span> for your watch</span>{' '}
                  </h2>

                  <div className='ml-lg-3'>
                    <button
                      type='submit'
                      className='button_form1 d-md-block d-none'
                      onClick={handleNavigate}
                    >
                      Get your free appraisal <img src={icons.Arrow} alt='Arrow' />
                    </button>

                    <button
                      type='submit'
                      className='button_form1 d-md-none d-inline-block'
                      onClick={handleNavigate}
                    >
                      <span className='d-md-inline-block d-none'>Get your </span> Free appraisal{' '}
                      <img src={icons.Arrow} alt='Arrow' />
                    </button>
                  </div>
                </div>
              </Col>

              <div className='mob_banner d-md-block d-none'>
                <img src={icons.Mobile2} alt='reconditioning' />
              </div>

              <div className='mob_banner d-md-none d-block'>
                <img src={icons.Mobile3} alt='reconditioning' />
              </div>
            </Row>
          </Container>
        </div>

        <Inventory />
        <div className='purpose_complicatedbackground'>
          <div className='purpose_complicated'>
            <div className='container'>
              <Row>
                <Col md={6} lg={6} className='text-center'>
                  <h2 className='d-md-block d-none'>
                    AUDEMARS PIGUET <br /> ROYAL OAK 15416CE BLACK <br /> &quot;SKELETON
                    CERAMIC&quot;{' '}
                  </h2>
                  <h2 className='d-md-none d-block'>
                    AUDEMARS PIGUET <br /> ROYAL OAK 15416CE BLACK <br /> &quot;SKELETON
                    CERAMIC&quot;{' '}
                  </h2>
                  <p>UNAGED LUXURY</p>
                  <button className='mt-md-2' onClick={handleNavigate}>
                    Free appraisal
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className='about_section condition_section'>
          <Container className='videio_full_width'>
            <h2 className='text-left pl-md-0 record_heading '>Reconditioning</h2>
            <div className='about_video mt-md-0 mt-0'>
              <Plyr
                className='d-md-block d-none'
                source={{
                  type: 'video',
                  sources: [
                    {
                      src: 'https://watch-prod.s3.us-east-2.amazonaws.com/videos/WhatsApp+Video+2022-07-19+at+3.52.49+PM.mp4',
                    },
                  ],
                  poster: icons.MobVideoImg,
                  tracks: {
                    kind: 'metadata',
                  },
                }}
              />
            </div>
          </Container>
        </div>
        <BestDeal />
        <div className='friend_section'>
          <div className='container'>
            <h2 className='mb-3'>Recent Transactions</h2>
            <TransactionSlider />
          </div>
        </div>
      </div>
    </div>
  );
};
