import { Skeleton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { icons } from '../../base/constants/ImageConstants';
import * as PATH from '../../base/constants/RoutePath';
import './SyncSlider.css';

export const SyncSlider = ({ productData }) => {
  const [isLoading, setLoading] = React.useState(true);
  const [nav1, setNav1] = React.useState(null);
  const [nav2, setNav2] = React.useState(null);
  let slider1 = [];
  let slider2 = [];

  React.useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  return (
    <div className='slider-container vertical_top'>
      <div className='inventory'>
        <Link to={PATH.INVENTORY_PAGE}>Browse</Link>
        <img src={icons.RightArrow} alt='' style={{ width: '6px' }} />
        <h6>{productData?.brand}</h6>
      </div>
      {isLoading && <Skeleton variant='rectangular' width={486} height={500} />}
      <Slider
        asNavFor={nav2}
        ref={(slider) => (slider1 = slider)}
        className={isLoading ? 'slider_hide' : 'thumbnail_slider_main'}
      >
        {productData?.images &&
          productData?.images?.map((data, index) => (
            <div key={index}>
              <img
                onLoad={() => setLoading(false)}
                src={data?.images || icons.Drop18}
                className='watch_img'
                alt='Watch'
              />
            </div>
          ))}
      </Slider>
      <Slider
        asNavFor={nav1}
        ref={(slider) => (slider2 = slider)}
        slidesToShow={productData?.images?.length && productData?.images?.length - 1}
        swipeToSlide={true}
        focusOnSelect={true}
        arrows={true}
        className={isLoading ? 'slider_hide' : 'thumbnail_slider'}
      >
        {productData?.images &&
          productData?.images?.map((data, index) => (
            <div key={index}>
              <img
                onLoad={() => setLoading(false)}
                src={data?.images || icons.Drop18}
                className='thumbnail_img'
                alt='Watch'
              />
            </div>
          ))}
      </Slider>
    </div>
  );
};
