import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_PAGE } from '../../base/constants/RoutePath';
import './404.css';
export const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className='notfound-container'>
      <h2>Oops! Page not found.</h2>
      <h1>404</h1>
      <p>{`We can't find the page you're looking for.`}</p>
      <button className='container-button' onClick={() => navigate(DASHBOARD_PAGE)}>
        GO BACK HOME
      </button>
    </div>
  );
};
