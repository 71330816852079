import React from 'react';
import './style.css';
import { icons } from '../../base/constants/ImageConstants';
import { Form, Formik } from 'formik';
import { contactUsSchema } from '../../base/validations';
import { TextArea, TextInput } from '../../components';
import { postFormData } from '../../Redux/Actions';
import { CONTACT_US_URL } from '../../base/constants/apiEndPoints';
import { useDispatch } from 'react-redux';
import { responseHandler } from '../../base/utils/utils';
import { CONTACT_US } from '../../base/constants/modalTitles';
export const Contact = () => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
  };
  const dispatch = useDispatch();
  const onSubmit = async (values, submitProps) => {
    const formBody = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email.toLowerCase(),
      subjects: values.subject,
      message: values.message,
    };
    const response = await dispatch(postFormData(CONTACT_US_URL, formBody));
    responseHandler(CONTACT_US, response);
    submitProps.setSubmitting(false);
    submitProps.resetForm();
  };
  return (
    <div>
      <div className='sec_contact_area'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 col-12 '>
              <div className='contact_left'>
                <h3>Leave a Message</h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={contactUsSchema}
                  onSubmit={onSubmit}
                >
                  <Form className='contact_form' autoComplete='off'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <TextInput
                            label='First Name'
                            name='firstName'
                            type='text'
                            placeholder='First Name'
                          />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <TextInput
                            label='Last Name'
                            name='lastName'
                            type='text'
                            placeholder='Last Name'
                          />
                        </div>
                      </div>

                      <div className='col-md-12'>
                        <div className='form-group'>
                          <TextInput
                            label='Email Address'
                            name='email'
                            type='email'
                            placeholder='Email Address'
                          />
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <TextInput
                            label='Subject'
                            name='subject'
                            type='text'
                            placeholder='Subject'
                          />
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <TextArea
                            label='Message'
                            name='message'
                            placeholder='Write a Message....'
                            className='form-control'
                          />
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <button type='submit' className='submit_btn'>
                          {' '}
                          Submit{' '}
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            <div className='col-md-4 col-12'>
              <div className='contact_right'>
                <h3>Our Information</h3>
                <div className='our_info'>
                  <div className='ourmail_icon'>
                    <img src={icons.MailIcon} alt='mail icon' />
                  </div>
                  <div className='our_mail'>
                    <span>Mail Us</span>
                    <a href='mailto: contact@postyourwatches.com'>contact@postyourwatches.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
