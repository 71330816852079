import React, { useCallback, useState } from 'react';
import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
import { icons } from '../../base/constants/ImageConstants';
import CloseIcon from '@mui/icons-material/Close';
import { Button, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { registerSchema } from '../../base/validations';
import { DragDrop } from '../../components/DragDrop/DragDrop';
import { Link, useNavigate } from 'react-router-dom';
import { AutoComplete, CustomButton } from '../../components';
import { manuFacturerOptions } from '../../base/constants/manufactureList';
import { useDispatch } from 'react-redux';
import { responseHandler } from '../../base/utils/utils';
import { APPRAISAL, VERIFICATION_CODE, ZIP_CODE } from '../../base/constants/modalTitles';
import {
  CHECK_PHONE_NUMBER_URL,
  REGISTER_URL,
  SEND_OTP_URL,
  VERIFY_OTP_URL,
  ZIPCODE_VERIFY_URL,
} from '../../base/constants/apiEndPoints';
import { postFormData, zipCodeVerify } from '../../Redux/Actions';
import { openModal } from '../../Redux/Actions/AppAction';
import * as PATH from '../../base/constants/RoutePath';
import './Register.css';
import { useEffect } from 'react';
import { CustomizedDialogs } from '../../components/CropImage/CustomizedDialogs';
// import { blobToFile } from '../../base/utils/stringUtils';
import { resizeFile } from '../../components/CropImage/ImageResize';
import { MyNumberInput } from '../../components/MyNumberInput/MyNumberInput';

const Radio = ({ ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <input type='radio' {...field} {...props} />
    </>
  );
};
const TextInput = ({ ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      {meta.touched && meta.error ? (
        <input
          className='form-control'
          style={{
            borderColor: 'red',
          }}
          {...field}
          {...props}
        />
      ) : (
        <input className='form-control' {...field} {...props} />
      )}
      {meta.touched && meta.error ? (
        <div
          className='error'
          style={{
            color: 'red',
            borderColor: 'red',
            fontSize: '11px',
            marginTop: '5px',
          }}
        >
          {meta.error}
        </div>
      ) : null}
    </>
  );
};

export const Register = () => {
  // const [showPassword, setShowPassword] = useState(false);
  // const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [files, setFiles] = useState([]);
  const [isFilesError, setIsFilesError] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [otpverify, setOtpverify] = useState(false);
  const [isVerifiedZip, setIsVerifiedZip] = useState(false);
  const [fieldhide, setFieldhide] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imageToCrop, setImageToCrop] = React.useState(undefined);
  const [imageName, setImageName] = React.useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onUploadFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const image = reader.result;
        setImageToCrop(image);
      });
      reader.readAsDataURL(file[0]);
    }
  };
  const setRemoveImage = () => {
    setImageToCrop(undefined);
  };

  const onselectCrop = (forwardImg) => {
    uploadedFiles.map((img, index) => {
      if (img?.path === forwardImg?.name) {
        uploadedFiles[index + 1]
          ? setImageName(uploadedFiles[index + 1]?.path)
          : uploadedFiles[index - 1]
          ? setImageName(uploadedFiles[index - 1]?.path)
          : setImageName(uploadedFiles[index]?.path);
        var reader = new window.FileReader();
        reader.readAsDataURL(
          uploadedFiles[index + 1]
            ? uploadedFiles[index + 1]
            : uploadedFiles[index - 1]
            ? uploadedFiles[index - 1]
            : uploadedFiles[index],
        );
        reader.onloadend = function () {
          var base64data = reader.result;
          setImageToCrop(base64data);
        };
        uploadedFiles?.splice(index, 1);
      }
    });
    setFiles((old) => [...old, forwardImg]);
  };

  const initialValues = {
    brand: '',
    model: '',
    year: '',
    serialNumber: '',
    papers: '',
    box: '',
    condition: '',
    askingPrice: '',
    images: [],
    fname: '',
    lname: '',
    email: '',
    phone: '',
    password: '',
    verify_OTP: '',
    confirmPassword: '',
    zipCode: '',
    state_name: '',
  };
  const onSubmit = async (values, submitProps) => {
    if (isVerified) {
      setOtpverify(false);
      var formData = new FormData();

      formData.append('first_name', values.fname);
      formData.append('last_name', values.lname);
      formData.append('city', values.city);
      formData.append('zip_code', values.zipCode);
      formData.append('password', values.phone);
      formData.append('confirm_password', values.phone);
      formData.append('brand', values.brand);
      formData.append('phone_number', `${'+1'}${values.phone}`);
      formData.append('model', values.model);
      formData.append('year', values.year);
      formData.append('serial', values.serialNumber);
      formData.append('papers', values.papers);
      formData.append('box', values.box);
      formData.append('condition', values.condition);
      formData.append('ask_price', values.askingPrice);
      formData.append('email', values.email.toLowerCase());
      formData.append('state', values.state);
      formData.append('country', values.country);
      for (let img of files) {
        // let fImg = blobToFile(img, 'xyz.png');
        const image = await resizeFile(img);
        formData.append('images', image);
      }
      const response = await dispatch(postFormData(REGISTER_URL, formData));
      if (response?.status === 200 || response?.status === 201) {
        const filterResponse = {
          title: APPRAISAL,
          message: (
            <>
              <h4 style={{ fontSize: 22, fontFamily: 'Gotham regular' }}>
                Your login information will be:-
              </h4>
              <p style={{ fontSize: 18, fontFamily: 'Gotham regular', margin: 10 }}>
                Email: {values.email}
              </p>
              <p style={{ fontSize: 18, fontFamily: 'Gotham regular' }}>
                Password: Phone number ({values.phone})
              </p>
            </>
          ),
          success: true,
        };
        await dispatch(openModal(filterResponse));
        navigate(PATH.LOGIN_PAGE);
        submitProps.setSubmitting(false);
        submitProps.resetForm();
      }
      if (response?.data?.status_code === 400) {
        // var appraisalData = new FormData();
        // appraisalData.append('brand', values.brand);
        // appraisalData.append('model', values.model);
        // appraisalData.append('year', values.year);
        // appraisalData.append('serial', values.serialNumber);
        // appraisalData.append('papers', values.papers);
        // appraisalData.append('box', values.box);
        // appraisalData.append('condition', values.condition);
        // appraisalData.append('ask_price', values.askingPrice);
        // for (let img of files) {
        //   const image = await resizeFile(img);
        //   appraisalData.append('images', image);
        // }
        const filterResponse = {
          title: APPRAISAL,
          // message: response?.data?.errors?.IsEmailExist
          //   ? `Email already exists, Do you want to login?`
          //   : `${response?.data?.errors?.non_field_errors[0]}`,
          message: response?.data?.errors?.non_field_errors[0],
          showButtons: false,
          success: false,
        };
        dispatch(openModal(filterResponse));
        // dispatch(handleAppraisalData(appraisalData));
      }
      setFieldhide(false);
      setIsVerifiedZip(false);
    } else {
      setIsVerifiedZip(false);
    }
  };
  // const onShowPreview = (event) => {
  //   setShowPreview(event.target.src);
  // };

  const deleteImg = (index) => {
    const updatedArray = files.filter((x, i) => i !== index);
    setFiles(updatedArray);
  };

  const handleFileChange = async (acceptedFiles) => {
    // let blobImg = acceptedFiles.map((img) => img);
    // setFiles((oldArray) => [...oldArray, ...acceptedFiles]);
    setUploadedFiles(acceptedFiles);
    setImageName(acceptedFiles[0]?.path);
    onUploadFile(acceptedFiles);
  };
  const sendOTP = async (phoneNumber, error) => {
    if (!phoneNumber || error) {
      const filterResponse = {
        title: VERIFICATION_CODE,
        message: 'Please enter valid phone number',
        success: false,
      };
      await dispatch(openModal(filterResponse));
    } else {
      const data = {
        phone_number: `${'+1'}${phoneNumber}`,
      };
      const apiResponse = await dispatch(postFormData(CHECK_PHONE_NUMBER_URL, data));
      if (!apiResponse?.data?.is_user_exist) {
        const response = await dispatch(postFormData(SEND_OTP_URL, data));
        responseHandler(VERIFICATION_CODE, response);
        if (response?.status === 200) {
          setOtpverify(true);
        }
      } else {
        const modalResponse = {
          title: VERIFICATION_CODE,
          message: apiResponse?.data?.message,
          success: false,
        };
        dispatch(openModal(modalResponse));
      }
    }
  };
  const verifyOTP = async (phoneNumber, OTP) => {
    if (!OTP) {
      const filterResponse = {
        title: VERIFICATION_CODE,
        message: 'Please enter phone verification code',
        success: false,
      };
      await dispatch(openModal(filterResponse));
    } else {
      const data = {
        phone_number: `${'+1'}${phoneNumber}`,
        code: OTP,
      };
      const response = await dispatch(postFormData(VERIFY_OTP_URL, data));
      responseHandler(VERIFICATION_CODE, response);
      if (response?.data?.status_code === 200) {
        setFieldhide(true);
        setIsVerified(true);
      } else {
        setFieldhide(false);
        await setIsVerified(false);
      }
    }
  };

  const handleFormSubmit = () => {
    if (files.length < 3) {
      setIsFilesError(true);
      return;
    }
    if (files.length > 3 && files.length > 6) {
      setIsFilesError(true);
      return;
    }
    setIsFilesError(false);
  };

  useEffect(() => {
    if (files.length >= 3) {
      setIsFilesError(false);
      return;
    }
  }, [files]);

  useCallback(() => {
    handleFormSubmit();
  }, [files]);

  const Select = ({ ...props }) => {
    const [field, meta] = useField(props);
    return (
      <div>
        {meta.touched && meta.error ? (
          <select
            {...field}
            {...props}
            style={{
              borderColor: 'red',
              fontSize: '11px',
              marginTop: '5px',
            }}
          />
        ) : (
          <select {...field} {...props} />
        )}
        {meta.touched && meta.error ? (
          <div
            className='error'
            style={{
              color: 'red',
              fontSize: '11px',
              marginTop: '5px',
            }}
          >
            {meta.error}
          </div>
        ) : null}
      </div>
    );
  };

  const onZipChange = async (val, setFieldValue) => {
    const formData = new FormData();
    if (val.length > 4 && val.length < 6) {
      formData.append('zip_code', parseInt(val));
      const zipCode = await dispatch(zipCodeVerify(ZIPCODE_VERIFY_URL, formData));
      if (zipCode?.status_code === 200) {
        await setFieldValue('state', zipCode.data.state_name);
        await setFieldValue('city', zipCode.data.city);
        setIsVerifiedZip(true);
      } else {
        const filterResponse = {
          title: ZIP_CODE,
          message: zipCode?.data?.message,
          success: false,
        };
        dispatch(openModal(filterResponse));
      }
    } else {
      await setFieldValue('state', '');
      await setFieldValue('city', '');
      setIsVerifiedZip(false);
    }
  };

  return (
    <div>
      <div className='appraisel'>
        <div className='container'>
          <div className='appraisel_top'>
            <img
              src={icons.LongArrow}
              alt=''
              style={{ width: '14px', height: '14px', cursor: 'pointer' }}
              onClick={() => navigate('/')}
            />
            <Link to='/'>Home</Link>
            <img src={icons.RightArrow} alt='' style={{ width: '6px', marginLeft: '10px' }} />
            <h6>Appraisal</h6>
          </div>
        </div>
      </div>
      <section className='appraisel_banner'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-12 '>
              <h1>Get your offer</h1>
              <p>
                Share a few details about your Watch <br /> and well give you a real offer right
                now.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='additional'>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={registerSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, handleChange, dirty, isValid, setFieldValue }) => (
            <Form className='formBody' autoComplete='off'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='additinal_box'>
                      <h2>We need some additional info</h2>
                      <p>
                        To provide an accurate offer, please provide correct information of your
                        watch.
                      </p>
                      <Field
                        name='brand'
                        component={AutoComplete}
                        data={manuFacturerOptions}
                        label='Brand'
                        error={errors.brand && touched.brand}
                      />
                      <ErrorMessage name='brand'>
                        {(msg) => (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '11px',
                              marginTop: '5px',
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                      <InputLabel className='inputLabel' htmlFor='outlined-adornment-amount'>
                        Model
                      </InputLabel>
                      <Field name='model'>
                        {({ field }) => (
                          <OutlinedInput
                            startAdornment={<InputAdornment position='start'></InputAdornment>}
                            label='Model'
                            error={errors.model && touched.model}
                            {...field}
                          />
                        )}
                      </Field>
                      {errors.model && touched.model && (
                        <div
                          className='error'
                          style={{
                            color: 'red',
                            borderColor: 'red',
                            fontSize: '11px',
                          }}
                        >
                          {errors.model}
                        </div>
                      )}

                      <InputLabel className='inputLabel' htmlFor='outlined-adornment-amount'>
                        Year
                      </InputLabel>
                      <Field name='year'>
                        {({ field }) => (
                          <OutlinedInput
                            startAdornment={<InputAdornment position='start'></InputAdornment>}
                            label='Year'
                            error={errors.year && touched.year}
                            {...field}
                          />
                        )}
                      </Field>
                      <ErrorMessage name='year'>
                        {(msg) => (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '11px',
                              marginTop: '5px',
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                      <InputLabel className='inputLabel' htmlFor='outlined-adornment-amount'>
                        Reference
                      </InputLabel>
                      <Field name='serialNumber'>
                        {({ field }) => (
                          <OutlinedInput
                            startAdornment={<InputAdornment position='start'></InputAdornment>}
                            error={errors.serialNumber && touched.serialNumber}
                            label='Reference'
                            {...field}
                          />
                        )}
                      </Field>
                      <ErrorMessage name='serialNumber'>
                        {(msg) => (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '11px',
                              marginTop: '5px',
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className='appraisal_radio'>
                      <h5>Papers</h5>
                      <label>
                        <Radio name='papers' value='YES' id={0} />
                        <span className='right'>YES</span>
                      </label>
                      <label>
                        <Radio name='papers' value='NO' id={1} />
                        <span className='left'>NO</span>
                      </label>
                      {errors.papers && touched.papers && (
                        <div
                          className='error'
                          style={{
                            color: 'red',
                            borderColor: 'red',
                            fontSize: '11px',
                          }}
                        >
                          {errors.papers}
                        </div>
                      )}
                    </div>

                    <div className='appraisal_radio'>
                      <h5>Box</h5>
                      <label>
                        <Radio name='box' value='YES' />
                        <span className='right'>YES</span>
                      </label>
                      <label>
                        <Radio name='box' value='NO' />
                        <span className='left'>NO</span>
                      </label>
                      {errors.box && touched.box && (
                        <div
                          className='error'
                          style={{
                            color: 'red',
                            borderColor: 'red',
                            fontSize: '11px',
                          }}
                        >
                          {errors.box}
                        </div>
                      )}
                    </div>
                    <div className='appraisal_radio'>
                      <h5>Condition</h5>
                      <label>
                        <Radio name='condition' value='Poor' />
                        <span className='right'>Poor</span>
                      </label>
                      <label>
                        <Radio name='condition' value='Average' />
                        <span className='left_right'>Average</span>
                      </label>
                      <label>
                        <Radio name='condition' value='Good' />
                        <span className='right_left'>Good</span>
                      </label>
                      <label>
                        <Radio name='condition' value='Excellent' />
                        <span className='left'>Excellent</span>
                      </label>
                      {errors.condition && touched.condition && (
                        <div
                          className='error'
                          style={{
                            color: 'red',
                            borderColor: 'red',
                            fontSize: '11px',
                          }}
                        >
                          {errors.condition}
                        </div>
                      )}
                    </div>
                    <div className='asking'>
                      <div className='asking_price'>
                        <h4>What is your asking price ?</h4>
                        <h5>Currency</h5>
                      </div>
                      <div className='form-group'>
                        {/* <TextInput type='text' name='askingPrice' className='form-control' /> */}
                        <MyNumberInput
                          placeholder=''
                          className='form-control'
                          value={values.askingPrice}
                          onValueChange={(val) => setFieldValue('askingPrice', val.floatValue)}
                        />
                        <span>Usd</span>
                      </div>
                    </div>
                    <div className='drag_drop'>
                      <h5>Add photos</h5>
                      <div className='drop_box'>
                        <div className='row'>
                          <div className='col-lg-4 col-sm-5 col-12'>
                            <div className='choose_file'>
                              <p>
                                The more photos you include, the more <br /> accurate your valuation
                                will be.
                              </p>
                              <div className={files.length > 0 ? 'drag_zone' : ''}>
                                <div className='newDragDrop'>
                                  <Field
                                    type='file'
                                    component={DragDrop}
                                    name='images'
                                    fileCount={files}
                                    handleFileChange={handleFileChange}
                                  />
                                </div>
                                {imageToCrop && (
                                  <CustomizedDialogs
                                    imageToCrop={imageToCrop}
                                    setRemoveImage={setRemoveImage}
                                    page='register'
                                    onBlob={onselectCrop}
                                    files={files}
                                    setFiles={setFiles}
                                    uploadedFiles={uploadedFiles}
                                    setImageToCrop={setImageToCrop}
                                    setUploadedFiles={setUploadedFiles}
                                    imageName={imageName}
                                    setImageName={setImageName}
                                  />
                                )}
                              </div>
                              {/* <img src={icons.Drag} /> */}
                            </div>{' '}
                            {files.length > 0 && (
                              <div className='preview_img'>
                                <h6>Preview</h6>
                                {files?.map((img, key) => {
                                  return (
                                    <div key={key} className='prewi_imge'>
                                      <img src={window.URL.createObjectURL(img)} alt='priviewImg' />
                                      <CloseIcon
                                        className='cross_img'
                                        onClick={() => deleteImg(key)}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            {isFilesError && (
                              <div
                                className='error'
                                style={{
                                  color: 'red',
                                  borderColor: 'red',
                                  fontSize: '11px',
                                }}
                              >
                                <span>*Required</span>
                              </div>
                            )}
                          </div>
                          <div className='col-lg-8 col-sm-7 col-12'>
                            <div className='example_photo'>
                              <h6>Example Photos</h6>
                              {/* <img src={showPreview === '' ? icons.Drop1 : showPreview} /> */}
                              <div className='row'>
                                <div className='col-md-4 col-sm-4 col-4'>
                                  <img src={icons.example_image_1} className='img-fluid' />
                                </div>
                                <div className='col-md-4 col-sm-4 col-4'>
                                  <img src={icons.example_image_2} className='img-fluid' />
                                </div>
                                <div className='col-md-4 col-sm-4 col-4'>
                                  <img src={icons.example_image_3} className='img-fluid' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form_contact'>
                      <h5>Contact</h5>
                      <p>
                        {' '}
                        <img src={icons.Secure} alt='' /> Your Information is kept private and
                        secure
                      </p>
                      <div className='row'>
                        <div className='col-md-6 col-sm-6 col-8 '>
                          <div className='input_field'>
                            <div className='mb-3 usa_input'>
                              <label className='form-label'>
                                Phone<span>*</span>
                              </label>
                              <div className='position-relative'>
                                {fieldhide ? (
                                  <img
                                    src={fieldhide ? icons.approvel_image : fieldhide}
                                    className='verified'
                                    alt='Verified'
                                  />
                                ) : (
                                  <Button
                                    className='sendOTP'
                                    disabled={!values.phone || errors.phone}
                                    onClick={() => sendOTP(values.phone, errors.phone)}
                                  >
                                    {otpverify ? 'Re-send Code' : 'send Code '}
                                  </Button>
                                )}
                                <TextInput
                                  type='tel'
                                  inputmode='numeric'
                                  pattern='[0-9]*'
                                  name='phone'
                                  value={values?.phone?.trim()}
                                />
                                <img src={icons.Usa} alt='' />
                              </div>
                              {otpverify && (
                                <div className='position-relative'>
                                  {fieldhide ? (
                                    <h6 className='name_Verified'>Verified</h6>
                                  ) : (
                                    <>
                                      <TextInput
                                        className='inputOTP'
                                        type='text'
                                        name='verify_OTP'
                                        value={values?.verify_OTP?.trim()}
                                        placeholder='Verification Code'
                                      />
                                      <Button
                                        className='verifyOTP'
                                        onClick={() => verifyOTP(values.phone, values.verify_OTP)}
                                      >
                                        Verify
                                      </Button>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-4 z_index'></div>
                        <div className='col-md-6 col-sm-6 col-12'>
                          <div className='input_field'>
                            <div className='mb-3'>
                              <label className='form-label'>
                                First Name<span>*</span>
                              </label>
                              <TextInput type='text' name='fname' value={values?.fname?.trim()} />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-12'>
                          <div className='input_field'>
                            <div className='mb-3'>
                              <label className='form-label'>
                                Last Name<span>*</span>
                              </label>
                              <TextInput type='text' name='lname' value={values?.lname?.trim()} />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-12'>
                          <div className='input_field'>
                            <div className='mb-3'>
                              <label className='form-label'>
                                Email<span>*</span>
                              </label>
                              <TextInput value={values?.email?.trim()} type='text' name='email' />
                            </div>
                          </div>
                        </div>
                        {/* 
                        <div className='col-md-6 col-sm-6 col-12'>
                          <div className='input_field'>
                            <div className='mb-3 pass_field'>
                              <label className='form-label'>
                                Password<span>*</span>
                              </label>
                              <div className='position-relative'>
                                {showPassword ? (
                                  <img
                                    src={icons.Conpass}
                                    alt=''
                                    onClick={() => setShowPassword(false)}
                                  />
                                ) : (
                                  <img
                                    src={icons.Pass}
                                    alt=''
                                    onClick={() => setShowPassword(true)}
                                  />
                                )}
                                <TextInput
                                  type={showPassword ? 'text' : 'password'}
                                  name='password'
                                  autoComplete='off'
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                        {/* <div className='col-md-6 col-sm-6 col-12'>
                          <div className='input_field pass_field'>
                            <div className='mb-3'>
                              <label className='form-label'>
                                Confirm password<span>*</span>
                              </label>

                              <div className='position-relative'>
                                {showConfirmPassword ? (
                                  <img
                                    src={icons.Conpass}
                                    alt=''
                                    onClick={() => setConfirmShowPassword(false)}
                                  />
                                ) : (
                                  <img
                                    src={icons.Pass}
                                    alt=''
                                    onClick={() => setConfirmShowPassword(true)}
                                  />
                                )}
                                <TextInput
                                  type={showConfirmPassword ? 'text' : 'password'}
                                  name='confirmPassword'
                                  autoComplete='off'
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className='col-md-6 col-sm-6 col-12'>
                          <div className='input_field'>
                            <div className='mb-3'>
                              <label className='form-label'>
                                Select Country<span>*</span>
                              </label>
                              <Select
                                id='disabledSelect'
                                name='country'
                                className='form-select form-control'
                              >
                                <option value=''>Select Country</option>
                                <option value='us'>United States</option>
                              </Select>
                            </div>
                          </div>
                        </div>

                        <div className='col-md-6 col-sm-6 col-12'>
                          <div className='input_field'>
                            <div className='mb-3'>
                              <label className='form-label'>
                                Zip Code<span>*</span>
                              </label>
                              <TextInput
                                type='text'
                                name='zipCode'
                                value={values.zipCode}
                                onChange={(e) => {
                                  handleChange(e);
                                  onZipChange(e.target.value, setFieldValue);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {isVerifiedZip && (
                          <>
                            <div className='col-md-6 col-sm-6 col-12'>
                              <div className='input_field'>
                                <div className='mb-3'>
                                  <label className='form-label'>State Name</label>
                                  <TextInput
                                    type='text'
                                    name='state'
                                    disabled
                                    placeholder='State'
                                    value={values.state}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-sm-6 col-12'>
                              <div className='input_field'>
                                <div className='mb-3'>
                                  <label className='form-label'>City Name</label>
                                  <TextInput
                                    type='text'
                                    disabled
                                    value={values.city}
                                    name='city'
                                    placeholder='City'
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className='col-md-6 col-sm-6 col-12'></div>
                        <div className='col-md-6 col-sm-6 col-12'>
                          {/* <button
                            className={
                              !(dirty && isValid)
                                ? 'form_disable_button'
                                : 'form_contact_button mb-0'
                            }
                            type='submit'
                            disabled={!(dirty && isValid)}
                            onClick={() => handleFormSubmit()}
                          >
                            Get My Quote
                          </button> */}
                          <CustomButton
                            type='submit'
                            disabled={!(dirty && isValid)}
                            className={
                              !(dirty && isValid)
                                ? 'form_disable_button'
                                : 'form_contact_button mb-0'
                            }
                            title='Get My Quote'
                            handleFormSubmit={handleFormSubmit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </div>
  );
};
