import * as TYPE from '../Types';

const initialState = {
  userData: [],
  userWatches: [],
  isEmailExist: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    case TYPE.GET_USER_WATCHES:
      return {
        ...state,
        userWatches: action.payload,
      };
    case TYPE.VALIDATE_USER_EMAIL:
      return {
        ...state,
        isEmailExist: action.payload,
      };
    default:
      return state;
  }
};
