import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { handleBidderValidation } from '../../Redux/Actions';
import { VALIDATE_BIDDER_URL } from '../../base/constants/apiEndPoints';
import { openDetailModal } from '../../Redux/Actions/AppAction';
import { LoginDrawers } from './LoginDrawers';
import { CCC_COMPLETE, ICO_ACCEPT, Regular } from '../../base/constants/AppConstant';
import { MAKE_AN_OFFER } from '../../base/constants/modalTitles';
import { isAuthenticated } from '../../base/utils/utils';
import { MyNumberInput } from '../MyNumberInput/MyNumberInput';

export const MakeOfferDrawer = ({ watch_id, watch_type, onRefresh, userData }) => {
  const TYPE = watch_type;
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    right: false,
  });
  const [offerPrice, setOfferPrice] = React.useState('');
  const handleMakeAnOffer = async () => {
    const data = { offer_price: offerPrice?.toString().replace(/,/g, ''), watches: watch_id };
    const response = await dispatch(
      handleBidderValidation(VALIDATE_BIDDER_URL, watch_id, {
        email: userData?.email,
        phone_number: userData?.phone_number,
      }),
    );
    if (response?.data?.status_code === 400) {
      const filterResponse = {
        title: `$${offerPrice}`,
        action: MAKE_AN_OFFER,
        message: response?.data?.errors?.non_field_errors[0],
        showButtons: userData?.user_type === null ? false : true,
        success: userData?.user_type === null ? false : 'pending',
        data,
      };
      dispatch(openDetailModal(filterResponse));
    } else if (response?.data?.status_code === 200) {
      const filterResponse = {
        title: `$${offerPrice}`,
        action: MAKE_AN_OFFER,
        // message: `Your current win rate is ${winRate}%`,
        showButtons: userData?.user_type === null ? false : true,
        success: userData?.user_type === null ? false : 'pending',
        data,
      };
      dispatch(openDetailModal(filterResponse));
    }
    onRefresh();
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (userData?.user_type === Regular) {
      const filterResponse = {
        // title: MAKE_AN_OFFER,
        message: 'You are not assigned dealer, probably auction will be available after 48 hrs',
        success: false,
      };
      dispatch(openDetailModal(filterResponse));
    } else if (TYPE === CCC_COMPLETE) {
      const filterResponse = {
        // title: MAKE_AN_OFFER,
        message: 'Watch is sold',
        success: false,
      };
      dispatch(openDetailModal(filterResponse));
    } else if (isAuthenticated() && TYPE === ICO_ACCEPT) {
      handleMakeAnOffer();
    } else {
      setState({ ...state, [anchor]: open });
    }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  };

  const onChangeState = () => {
    setState({ ...state, right: false });
  };
  const handleMakeOffer = async (e) => {
    setOfferPrice((v) => (e.target.validity.valid ? e.target.value : v));
  };

  const list = () => <LoginDrawers onChangeState={onChangeState} watch_id={watch_id} />;
  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className=' mr-0 submit_offer_div'>
            <div className='form-group make_end_offer'>
              <MyNumberInput
                // type='number'
                placeholder='Enter Amount'
                className='form-control'
                // value={offerPrice}
                onChange={(e) => handleMakeOffer(e)}
              />
              <div className='enter_amount'>
                <p>
                  ENTER AMOUNT <span className='dollar'>&#36;</span> <br />
                  <span className='usd_span'>usd</span>
                </p>
              </div>
            </div>
            <Button
              type='submit'
              disabled={offerPrice?.length === 0}
              onClick={toggleDrawer(anchor, true)}
              className='btn1 make_offer'
            >
              Submit offer
            </Button>
          </div>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};
