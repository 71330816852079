import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { icons } from '../../base/constants/ImageConstants';
import './BestDealSlider.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBestDeal } from '../../Redux/Actions';
import { GET_BEST_DEAL_URL } from '../../base/constants/apiEndPoints';
import { AddToFavourite } from '../../base/utils/utils';
import { useDeviceDetect } from '../../hooks';
export const BestDealSlider = () => {
  const navigate = useNavigate();
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          infinite: true,
        },
      },
    ],
  };
  const dispatch = useDispatch();
  const { isMobile } = useDeviceDetect();
  const bestDealData = useSelector((state) => state?.landingReducer?.bestDealData);
  const isRefresh = useSelector((state) => state.appReducer.refresh);
  const navigateRoutes = (route) => {
    navigate(route);
  };
  useEffect(() => {
    const getBestDealData = async () => {
      isMobile && (await dispatch(getBestDeal(GET_BEST_DEAL_URL)));
    };
    getBestDealData();
  }, [isRefresh]);
  return (
    <div className='slider-container just_droped_slider'>
      <Slider {...settings}>
        {bestDealData?.slice?.(0, 4)?.map((data) => (
          <div key={data?.id} className=''>
            <div
              className='image_div_drop'
              onClick={() => navigateRoutes(`/productview/watch/${data?.id}`)}
            >
              <img src={data?.main_image || icons.Drop1} className='drop_img' />
            </div>
            <a>
              <h5 onClick={() => navigateRoutes(`/productview/watch/${data?.id}`)}>
                {data?.brand} | {data?.model || '126610LV'}
              </h5>
              {/* <h6>Rolex Submariner Date</h6> */}
              {/* <p>{data?.model || '126610LV'}</p> */}
              <h3>
                {data?.state || 'Ca'} | {data?.city || 'Whittier'}
              </h3>
              <span>${Math.round(data?.ask_price)?.toLocaleString() || '22,133'}</span>
            </a>
            <img
              src={data?.is_saved ? icons.Filled_Bookmark : icons.Bookmark}
              className='bookmark_img'
              alt='Bookmark'
              onClick={() => AddToFavourite(data?.id, true, navigate)}
            />
            {/* <img src={icons.StarIcon2} className='star_icon' alt='' /> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};
