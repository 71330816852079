import * as TYPE from '../Types';

const initialState = {
  brandsData: [],
  error: '',
};

export const brandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.FETCH_BRANDS_SUCCESS:
      return {
        ...state,
        brandsData: action.payload,
      };
    case TYPE.FETCH_BRANDS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
