export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';

export const FETCH_INVENTORY_PRODUCTS_SUCCESS = 'FETCH_INVENTORY_PRODUCTS_SUCCESS';
export const FETCH_INVENTORY_PRODUCTS_ERROR = 'FETCH_INVENTORY_PRODUCTS_ERROR';

export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS';
export const FETCH_BRANDS_ERROR = 'FETCH_BRANDS_ERROR';

export const ADD_TO_FAVOURITE_SUCCESS = 'ADD_TO_FAVOURITE_SUCCESS';
export const ADD_TO_FAVOURITE_ERROR = 'ADD_TO_FAVOURITE_ERROR';

export const POST_FORM_DATA_SUCCESS = 'POST_FORM_DATA_SUCCESS';
export const POST_FORM_DATA_ERROR = 'POST_FORM_DATA_ERROR';

export const GET_GRAPH_SUCCESS = 'GET_GRAPH_SUCCESS';
export const GET_GRAPH_ERROR = 'GET_GRAPH_ERROR';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR';

export const SET_LOADING = 'SET_LOADING';

export const GET_USER_WATCHES = 'GET_USER_WATCHES';
export const GET_USER_WATCH_ERROR = 'GET_USER_WATCH_ERROR';

export const GET_BIDS_SUCCESS = 'GET_BIDS_SUCCESS';
export const GET_BIDS_ERROR = 'GET_BIDS_ERROR';

export const GET_FEATURED_SUCCESS = 'GET_FEATURED_SUCCESS';
export const GET_FEATURED_ERROR = 'GET_FEATURED_ERROR';

export const GET_JUST_DROPPED_SUCESS = 'GET_JUST_DROPPED_SUCESS';
export const GET_JUST_DROPPED__ERROR = 'GET_JUST_DROPPED__ERROR';

export const INVENTORY_FILTER_DATA_SUCCESS = 'INVENTORY_FILTER_DATA_SUCCESS';
export const INVENTORY_FILTER_DATA_ERROR = 'INVENTORY_FILTER_DATA_ERROR';

export const SET_BRAND_FILTERS = 'SET_BRAND_FILTERS';
export const SET_MODEL_FILTERS = 'SET_MODEL_FILTERS';
export const SET_CASE_SIZE_FILTERS = 'SET_CASE_SIZE_FILTERS';

export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR';

export const GET_FAVORITE_SUCCESS = 'GET_FAVORITE_SUCCESS';
export const GET_FAVORITE_ERROR = 'GET_FAVORITE_ERROR';

export const PRODUCT_INVENTORY_DETAIL_SUCCESS = 'PRODUCT_INVENTORY_DETAIL_SUCCESS';
export const PRODUCT_INVENTORY_DETAIL_ERROR = 'PRODUCT_INVENTORY_DETAIL_ERROR';

export const GET_WATCH_TYPE = 'GET_WATCH_TYPE';

export const GET_BEST_DEAL_SUCESS = 'GET_BEST_DEAL_SUCESS';
export const GET_BEST_DEAL_ERROR = 'GET_BEST_DEAL_ERROR';

export const REFRESH = 'REFRESH';

export const ADD_TO_SAVE_AFTER_LOGIN = 'ADD_TO_SAVE_AFTER_LOGIN';

export const RESET_STATE = 'RESET_STATE';

export const GET_LIKE_DATA_SUCCESS = 'GET_LIKE_DATA_SUCCESS';
export const GET_LIKE_DATA_ERROR = 'GET_LIKE_DATA_ERROR';

export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';

export const SET_APPRAISAL_DATA = 'SET_APPRAISAL_DATA';

export const HANDLE_SCROLL_DIALOG = 'HANDLE_SCROLL_DIALOG';

export const GET_STEPS_DETAIL_SUCCESS = 'GET_STEPS_DETAIL_SUCCESS';
export const GET_STEPS_DETAIL_ERROR = 'GET_STEPS_DETAIL_ERROR';

export const OPEN_DETAIL_MODAL = 'OPEN_DETAIL_MODAL';
export const CLOSE_DETAIL_MODAL = 'CLOSE_DETAIL_MODAL';

export const GET_WIN_RATE_SUCCESS = 'GET_WIN_RATE_SUCCESS';
export const GET_WIN_RATE_ERROR = 'GET_WIN_RATE_ERROR';

export const STORE_LOCATION = 'STORE_LOCATION';

export const VALIDATE_USER_EMAIL = 'VALIDATE_USER_EMAIL';
