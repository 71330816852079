import getAxios from '../../base/axos.config';
import * as TYPE from '../Types';
const api = getAxios();
export const fetchGraphData = (url) => async (dispatch) => {
  await api
    .get(url)
    .then(async (response) => {
      await dispatch({ type: TYPE.GET_GRAPH_SUCCESS, payload: response?.data?.data });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.GET_GRAPH_ERROR, payload: error });
    });
};

export const bidRevceivedData = (url) => async (dispatch) => {
  await api
    .get(url)
    .then(async (response) => {
      await dispatch({ type: TYPE.GET_BIDS_SUCCESS, payload: response?.data?.data });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.GET_BIDS_ERROR, payload: error });
    });
};
export const youMayAlsoLikeData = (url, params) => async (dispatch) => {
  await api
    .get(`${url}${params}/`)
    .then(async (response) => {
      await dispatch({ type: TYPE.GET_LIKE_DATA_SUCCESS, payload: response?.data?.results });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.GET_LIKE_DATA_ERROR, payload: error });
    });
};

export const getStepsDetail = (url, params) => async (dispatch) => {
  await api
    .get(`${url}${params}/`)
    .then(async (response) => {
      await dispatch({ type: TYPE.GET_STEPS_DETAIL_SUCCESS, payload: response?.data?.data });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.GET_STEPS_DETAIL_ERROR, payload: error });
    });
};

export const getWinRate = (url, params, payload) => async (dispatch) => {
  await api
    .post(`${url}${params}/`, payload)
    .then(async (response) => {
      await dispatch({ type: TYPE.GET_WIN_RATE_SUCCESS, payload: response?.data?.data });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.GET_WIN_RATE_ERROR, payload: error });
    });
};
