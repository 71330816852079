import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from 'react-bootstrap';
import { icons } from '../../base/constants/ImageConstants';
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '25px',
    color: '#111111',
    textTransform: 'capitalize',
  },
}));
export const Faqs = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className='faq_section mt-5 mt-md-5 pb-md-5 mb-md-5 mb-3'>
      <Container>
        <p className='find_answer'>Find your answer here</p>
        <h2 className='ask_question'>
          <span className='faq_text'> Frequently </span> Ask & Questions
        </h2>

        <div className='faq_img d-lg-none d-md-block d-block'>
          <img src={icons.Watch_faq} alt='Watch' />
        </div>

        <Row>
          <Col lg={6} md={12} xs={12}>
            <div className='accordion_faq'>
              <div className={classes.root}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary aria-controls='panel1bh-content' id='panel1bh-header'>
                    <Typography className={classes.heading}>
                      When will I need to service my Post timepiece?{' '}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      We designed the Post to be serviceable but not to require any servicing for a
                      long time. ETA movements are some of the finest in California and an ETA shock
                      proof quartz movement should run in perpetuity while there is battery power.{' '}
                      <br /> <br />
                      Weird stuff happens though, and if you feel the need to service your watch,
                      please message Post customer care through the Contact Us form.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary aria-controls='panel2bh-content' id='panel2bh-header'>
                    <Typography className={classes.heading}>
                      How waterproof is a Post watch?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and scrambled it to make a type
                      specimen book. It has survived not only five centuries, but also the leap into
                      electronic typesetting, remaining essentially unchanged. I
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary aria-controls='panel3bh-content' id='panel3bh-header'>
                    <Typography className={classes.heading}>
                      What is the best way to clean my watch + band?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and scrambled it to make a type
                      specimen book. It has survived not only five centuries, but also the leap into
                      electronic typesetting, remaining essentially unchanged. I
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                  <AccordionSummary aria-controls='panel4bh-content' id='panel4bh-header'>
                    <Typography className={classes.heading}>
                      How long does my battery last?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and scrambled it to make a type
                      specimen book. It has survived not only five centuries, but also the leap into
                      electronic typesetting, remaining essentially unchanged. I
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                  <AccordionSummary aria-controls='panel5bh-content' id='panel5bh-header'>
                    <Typography className={classes.heading}>
                      How long is my Post watch warranty?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and scrambled it to make a type
                      specimen book. It has survived not only five centuries, but also the leap into
                      electronic typesetting, remaining essentially unchanged. I
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <div className='d-md-none d-block'>
                  <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary aria-controls='panel6bh-content' id='panel6bh-header'>
                      <Typography className={classes.heading}>Are the materials safe?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry`s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining essentially unchanged. I
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary aria-controls='panel7bh-content' id='panel7bh-header'>
                      <Typography className={classes.heading}>
                        What is the Blok 33 made of?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry`s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining essentially unchanged. I
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          </Col>

          <Col md={6} className='d-lg-inline-block d-none'>
            <div className='faq_height_max'>
              <div className='accordion_faq mt-md-4'>
                <div className={classes.root}>
                  <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary aria-controls='panel6bh-content' id='panel6bh-header'>
                      <Typography className={classes.heading}>Are the materials safe?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry`s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining essentially unchanged. I
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary aria-controls='panel7bh-content' id='panel7bh-header'>
                      <Typography className={classes.heading}>
                        What is the Blok 33 made of?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry`s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining essentially unchanged. I
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className='faq_img'>
              <img src={icons.Watch_faq} alt='Watch' />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
