import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import './login.css';
import { CustomButton, TextInput } from '../../components';
import { useNavigate } from 'react-router-dom';
import * as PATH from '../../base/constants/RoutePath';
import { useDispatch, useSelector } from 'react-redux';
import { addToFavourite, getUserData, postFormData } from '../../Redux/Actions';
import {
  ADD_WATCH_TO_FAVOURITE,
  GET_USER_URL,
  LOGIN_URL,
  SEND_OTP_URL,
  VERIFY_OTP_URL,
} from '../../base/constants/apiEndPoints';
import { refresh_token, token, userId } from '../../base/constants/AppConstant';
import { responseHandler } from '../../base/utils/utils';
import { ADD_TO_SAVED, LOG_IN, VERIFICATION_CODE } from '../../base/constants/modalTitles';
import { Button } from '@mui/material';
import { icons } from '../../base/constants/ImageConstants';

export const Login = () => {
  const [showOTPField, setShowOTPField] = React.useState(false);
  const [isOtpValid, setIsOtpValid] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSaved, saveWatchData } = useSelector((state) => state?.inventoryReducer);
  const initialValues = {
    email: '',
    password: '',
    verifyCode: '',
  };
  const validationSchema = Yup.object({
    email: Yup.string().required('Please enter your Email').email('Please enter valid Email'),
    password: Yup.string()
      .required('Please enter your Cell Phone')
      .matches(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/, 'Cell Phone number is not valid'),
    verifyCode: Yup.string().required('Please enter your Verification code'),
  });
  const location = useSelector((state) => state.appReducer.location);
  const onSubmit = async (values, submitProps) => {
    const data = { email: values.email.toLowerCase(), password: values.password };
    const response = await dispatch(postFormData(LOGIN_URL, data));
    responseHandler(LOG_IN, response);
    if (response?.status === 200 || response?.status === 201) {
      dispatch(getUserData(GET_USER_URL));
      localStorage.setItem(token, response?.data?.data?.tokens);
      localStorage.setItem(refresh_token, response?.data?.data?.refresh_token);
      localStorage.setItem(userId, response?.data?.data?.id);
      if (location !== '') {
        navigate(-1);
      } else {
        navigate(PATH.MY_ACCOUNT);
      }

      if (isSaved) {
        const response = await dispatch(
          addToFavourite(ADD_WATCH_TO_FAVOURITE, {
            watches: saveWatchData.watches,
            user: localStorage.getItem(userId),
            is_favorite: saveWatchData.isFavourite,
          }),
        );
        responseHandler(ADD_TO_SAVED, response);
        submitProps.resetForm();
      }
    }
    submitProps.setSubmitting(false);
  };

  const sendOTP = async (phoneNumber) => {
    const data = {
      phone_number: `${'+1'}${phoneNumber}`,
    };
    const response = await dispatch(postFormData(SEND_OTP_URL, data));
    responseHandler(VERIFICATION_CODE, response);
    if (response?.status === 200) {
      setShowOTPField(true);
    } else if (response?.status === 400) {
      setShowOTPField(false);
    }
  };

  const verifyOTP = async (phoneNumber, OTP) => {
    const data = {
      phone_number: `${'+1'}${phoneNumber}`,
      code: OTP,
    };
    const response = await dispatch(postFormData(VERIFY_OTP_URL, data));
    if (response?.data?.status_code === 200) {
      setIsOtpValid(true);
    }
  };

  return (
    <div>
      <section className='login_banner'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-12'>
              <div className='login_bcintent'>
                <h1>Welcome to POST</h1>
                <p>Enter your details below to login</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='login_form'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-9 col-12  m-auto text-center'>
              <h2>Account Details</h2>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, errors, dirty, isValid }) => (
                  <Form className='formBody' autoComplete='off'>
                    <div className='form-group'>
                      <TextInput
                        label='Email address'
                        name='email'
                        value={values?.email?.trim()}
                        type='text'
                        placeholder='Enter email address'
                      />
                    </div>
                    <div className='form-group position-relative'>
                      <TextInput
                        label='Cell Phone'
                        name='password'
                        type='tel'
                        inputmode='numeric'
                        pattern='[0-9]*'
                        value={values?.password?.trim()}
                        placeholder='Enter Cell Phone Number'
                      />
                      <Button
                        className='send-OTP'
                        disabled={!values.password || errors.password}
                        onClick={() => sendOTP(values.password)}
                      >
                        {showOTPField ? 'RESEND-CODE' : 'SEND CODE'}
                      </Button>
                    </div>
                    {showOTPField && (
                      <div className='form-group position-relative'>
                        <TextInput
                          label='Verify Code'
                          name='verifyCode'
                          type='tel'
                          inputmode='numeric'
                          pattern='[0-9]*'
                          value={values?.verifyCode?.trim()}
                          placeholder='Enter Verification Code'
                        />
                        <Button
                          className={isOtpValid ? 'otp-verify-sucess' : 'send-OTP'}
                          disabled={!values.verifyCode || errors.verifyCode}
                          onClick={() => verifyOTP(values.password, values.verifyCode)}
                        >
                          {isOtpValid ? (
                            <img src={icons?.SuccessIcon} style={{ width: '30%' }} />
                          ) : (
                            'VERIFY CODE'
                          )}
                        </Button>
                      </div>
                    )}
                    <div className='form-group mt-2'>
                      {/* <div className='text-left d-block text-forgot'>
                      <Link to={PATH.FORGOT_PASSWORD}>Forgot Password?</Link>
                    </div> */}
                    </div>
                    {/* <button type='submit'>Login</button> */}
                    <CustomButton
                      type='submit'
                      title='Login'
                      disabled={!(dirty && isValid)}
                      className='login-btn'
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
