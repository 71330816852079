/* eslint-disable no-unused-vars */
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal, handleLoginModal, pageRefresh } from '../../Redux/Actions/AppAction';
import { icons } from '../../base/constants/ImageConstants';
import './Modal.css';
import { Button } from '@mui/material';
import { postFormData } from '../../Redux/Actions';
import { BUY_NOW_URL, MAKE_OFFER_URL } from '../../base/constants/apiEndPoints';
import { APPRAISAL, BUY_NOW, MAKE_AN_OFFER } from '../../base/constants/modalTitles';
import { responseHandler } from '../../base/utils/utils';
import { CLOSE_MODAL } from '../../Redux/Types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 430,
  height: 270,
  bgcolor: 'background.paper',
  border: '0px solid #fff',
  boxShadow: 24,
  borderRadius: '20px',
  p: 2,
  zIndex: '100000',
  outline: 'none',
};

export const CustomModal = () => {
  const props = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeModal());
  const handleMakeOffer = async () => {
    const response = await dispatch(postFormData(MAKE_OFFER_URL, props.data));
    responseHandler(MAKE_AN_OFFER, response);
    await dispatch(pageRefresh());
  };
  const handleBuyNow = async () => {
    const response = await dispatch(postFormData(BUY_NOW_URL, props.data));
    responseHandler(BUY_NOW, response);
    await dispatch(pageRefresh());
  };
  const handleLogin = () => {
    dispatch({ type: CLOSE_MODAL });
    dispatch(handleLoginModal(true));
  };
  return (
    <div>
      <Modal
        open={props?.showModal ? props?.showModal : false}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} className='modal_container'>
          {/* <div className='close_icon'>
              <img src={icons.closeIcon} onClick={handleClose} />
            </div> */}
          <div>
            <img
              src={props?.success ? icons?.successIcon : icons?.ErrorIcon}
              className='status_image'
              onClick={handleClose}
            />
          </div>
          <strong className='modal_heading'>{props?.title}</strong>
          <Typography id='modal-modal-description' sx={{ m: 2 }} className='modal_content'>
            {props?.message}
          </Typography>
          {props.showButtons && (
            <div className='modal_buttons'>
              <Button
                onClick={
                  props.action === MAKE_AN_OFFER
                    ? handleMakeOffer
                    : props.action === BUY_NOW
                    ? handleBuyNow
                    : props.title === APPRAISAL
                    ? handleLogin
                    : null
                }
              >
                Confirm
              </Button>
              <Button onClick={handleClose}> Cancel </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};
