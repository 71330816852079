/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LOGIN_PAGE, REGISTER_PAGE } from '../base/constants/RoutePath';
import { isAuthenticated } from '../base/utils/utils';

export const PublicRoute = ({ Component }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      (isAuthenticated() && pathname === LOGIN_PAGE) ||
      (isAuthenticated() && pathname === REGISTER_PAGE)
    ) {
      navigate('/');
    }
  }, []);

  return <Component />;
};
