import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { INVENTORY_LIST_URL } from '../../base/constants/apiEndPoints';
import { icons } from '../../base/constants/ImageConstants';
import * as Path from '../../base/constants/RoutePath';
import { AddToFavourite } from '../../base/utils/utils';
import { fetchJustDroppedata } from '../../Redux/Actions';
import { JustDropSlider } from '../MobileView';
import { ImageSkeleton } from '../Skeleton';
import './JustDropped.css';

export const JustDropped = () => {
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const navigateRoutes = (route) => {
    navigate(route);
  };
  const dispatch = useDispatch();
  const apiSort = {
    ordering: '',
    feed: '',
    brand: '',
  };
  const justDroppedData = useSelector((state) => state?.landingReducer?.justDroppedData?.results);
  const isRefresh = useSelector((state) => state?.appReducer?.refresh);
  useEffect(() => {
    dispatch(
      fetchJustDroppedata(INVENTORY_LIST_URL, {
        ...apiSort,
        ordering: 'newest',
        feed: 'just_dropped',
      }),
    );
  }, [isRefresh]);
  return (
    <Container className='just_droped'>
      <h2>Just dropped</h2>
      <p className='hub_heading'>
        Browse through pre-owned watches listed recently on POST and start offer!
      </p>
      <div className='row just_drop_mob_hide'>
        {justDroppedData && justDroppedData?.length > 0 ? (
          justDroppedData?.slice(0, 4)?.map((data) => (
            <div key={data?.id} className='col-lg-3 col-md-3 col-12'>
              {isLoading && <ImageSkeleton width={210} height={150} />}
              <div
                className={isLoading ? 'card_hidden' : 'card'}
                onClick={() => navigateRoutes(`/productview/watch/${data?.id}`)}
              >
                <div className='card_img'>
                  <img
                    onLoad={() => setLoading(false)}
                    src={data?.main_image || icons.Drop1}
                    className='watch_img'
                    alt='Watch'
                  />
                </div>
                <a href='#'>
                  <div className='card_body text-left'>
                    <h5>
                      {data?.brand || 'ROLEX'} | {data?.model || '126610LV'}
                    </h5>
                    {/* <h6>Rolex Submariner Date</h6> */}
                    {/* <p>{data?.model || '126610LV'}</p> */}
                    <h3>
                      {data?.state || 'Whittier'} | {data?.city || 'Ca'}{' '}
                    </h3>
                    <span>${Math.floor(data?.ask_price)?.toLocaleString('en-US') || '22,133'}</span>
                  </div>
                </a>
              </div>
              <img
                src={data?.is_saved ? icons.Filled_Bookmark : icons.Bookmark}
                className={isLoading ? 'card_hidden' : 'bookmark_img'}
                alt='Bookmark'
                onClick={() => AddToFavourite(data?.id, true, navigate)}
              />
              {/* <img
                src={data?.is_featured ? icons.StarIcon : icons.StarIcon2}
                className='star_icon'
                alt=''
              /> */}
            </div>
          ))
        ) : (
          <p className='no_justDropped'>No watches available.</p>
        )}
        <div className='col-lg-12 inventory_btn'>
          <button onClick={() => navigateRoutes(Path.INVENTORY_PAGE)}>See More</button>
        </div>
      </div>
      <div className='drop_slider'>
        <JustDropSlider />
        <div className='col-lg-12 inventory_btn'>
          <button onClick={() => navigateRoutes(Path.INVENTORY_PAGE)}>See More </button>
        </div>
      </div>
    </Container>
  );
};
