import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../base/constants/ImageConstants';
import * as PATH from '../../base/constants/RoutePath';
import { CropImage } from '../../components/CropImage/CropImage';
import { TextInput } from '../../components';
import './MyAccount.css';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordApi, openModal, pageRefresh, postFormData } from '../../Redux/Actions';
import {
  CHANGE_PASWORD_URL,
  CHECK_PHONE_NUMBER_URL,
  GET_USER_URL,
  SEND_OTP_URL,
  UPDATE_USER_URL,
  VERIFY_OTP_URL,
} from '../../base/constants/apiEndPoints';
import { Form, Formik } from 'formik';

import { changePasswordSchema, updateUserSchema } from '../../base/validations';
import { getUserData, updateUserData } from '../../Redux/Actions/UserAction';
import {
  CHANGE_PASSWORD,
  UPDATE_PROFILE,
  VERIFICATION_CODE,
} from '../../base/constants/modalTitles';
import { isAuthenticated, responseHandler } from '../../base/utils/utils';
import { Button } from '@mui/material';
export const MyAccount = () => {
  const [showOTPField, setShowOTPField] = React.useState(false);
  const [isOtpValid, setIsOtpValid] = React.useState(false);
  const userData = useSelector((state) => state?.userReducer?.userData);
  const forRefresh = useSelector((state) => state.appReducer.refresh);
  const [img, setImg] = React.useState(userData?.profile_pic || icons.verify_icon);
  const dispatch = useDispatch();
  React.useEffect(() => {
    isAuthenticated() && dispatch(getUserData(GET_USER_URL));
  }, [dispatch, img, forRefresh]);
  const initialValues = {
    first_name: userData?.first_name || '',
    last_name: userData?.last_name || '',
    phone_number: userData?.phone_number?.slice(2) || '',
    email: userData?.email || '',
  };
  const onSubmit = async (values, submitProps) => {
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      phone_number: `${'+1'}${values?.phone_number}`,
      email: values.email,
    };
    const response = await dispatch(updateUserData(UPDATE_USER_URL, data));
    responseHandler(UPDATE_PROFILE, response);
    dispatch(pageRefresh());
    submitProps.setSubmitting(false);
    submitProps.resetForm();
  };
  const handleChangePassword = async (values, submitProps) => {
    setShowOTPField(false);
    setIsOtpValid(false);
    const data = {
      old_password: userData?.phone_number?.slice(2),
      new_password: values.new_password,
      confirm_password: values.new_password,
    };
    const response = await dispatch(changePasswordApi(CHANGE_PASWORD_URL, data));
    responseHandler(CHANGE_PASSWORD, response);
    if (response?.data?.status_code === 200) {
      dispatch(pageRefresh());
    }
    submitProps.setSubmitting(false);
    submitProps.resetForm();
  };

  const sendOTP = async (phoneNumber) => {
    const data = {
      phone_number: `${'+1'}${phoneNumber}`,
    };
    const apiResponse = await dispatch(postFormData(CHECK_PHONE_NUMBER_URL, data));
    if (!apiResponse?.data?.is_user_exist) {
      const response = await dispatch(postFormData(SEND_OTP_URL, data));
      responseHandler(VERIFICATION_CODE, response);
      if (response?.status === 200) {
        setShowOTPField(true);
      } else if (response?.status === 400) {
        setShowOTPField(false);
      }
    } else {
      const modalResponse = {
        title: VERIFICATION_CODE,
        message: apiResponse?.data?.message,
        success: false,
      };
      dispatch(openModal(modalResponse));
    }
  };

  const verifyOTP = async (phoneNumber, OTP) => {
    const data = {
      phone_number: `${'+1'}${phoneNumber}`,
      code: OTP,
    };
    const response = await dispatch(postFormData(VERIFY_OTP_URL, data));
    if (response?.data?.status_code === 200) {
      setIsOtpValid(true);
    }
  };
  return (
    <div>
      <section className='sec_mywatch_area'>
        <div className='container-fluid pr-md-5'>
          <div className='row'>
            <div className='col-xl-2 col-lg-3 col-md-3 col-12 mobv'>
              <div className='sidebar'>
                <ul>
                  <li>
                    <Link to={PATH.MY_WATCH_PAGE}>
                      <span className='dark'>
                        <img className='img-fluid' src={icons.img_aprove} alt='reconditioning' />
                      </span>
                      <span className='light'>
                        <img className='img-fluid' src={icons.approvel_img} />
                      </span>{' '}
                      My Watches
                    </Link>
                  </li>
                  <li className='active'>
                    <Link to={PATH.MY_ACCOUNT}>
                      <span className='dark'>
                        <img className='img-fluid' src={icons.img_account} alt='reconditioning' />
                      </span>
                      <span className='light'>
                        <img className='img-fluid' src={icons.account_act} />
                      </span>{' '}
                      My Account
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-xl-10 col-lg-9 col-md-9 col-12 '>
              <div className='right_content'>
                <div className='myaccount_area'>
                  <div className='my_account_head'>
                    <h2>My Account </h2>
                    <p>View and edit your personal detail</p>
                  </div>
                  <div className='row'>
                    <div className='col-xl-8 col-md-12 col-12'>
                      <div className='personal_detail'>
                        <h3>Personal Details</h3>
                        <div className='img__box'>
                          <div className='img_inn'>
                            <CropImage setImg={(img) => setImg(img)} page='my-account' />
                            <img src={userData?.profile_pic || icons.profileIcon} alt='virify' />
                          </div>
                        </div>
                        <Formik
                          enableReinitialize={true}
                          initialValues={initialValues}
                          validationSchema={updateUserSchema}
                          onSubmit={onSubmit}
                        >
                          {() => (
                            <Form className='personal_detail_form' autoComplete='off'>
                              <div className='row'>
                                <div className='col-md-5'>
                                  <div className='form-group'>
                                    <TextInput
                                      label='FIRST NAME'
                                      name='first_name'
                                      type='text'
                                      className='form-control'
                                    />
                                  </div>
                                </div>
                                <div className='col-md-5'>
                                  <div className='form-group'>
                                    <TextInput
                                      label='LAST NAME'
                                      name='last_name'
                                      type='text'
                                      className='form-control'
                                    />
                                  </div>
                                </div>
                                <div className='col-md-5'>
                                  <div className='form-group'>
                                    <TextInput
                                      disabled
                                      label='PHONE NUMBER'
                                      name='phone_number'
                                      type='text'
                                      className='form-control'
                                    />
                                    <label className='verified-label'>
                                      Verified <img src={icons.verify_icon} alt='virify' />
                                    </label>
                                  </div>
                                  <button type='submit' className='my_accountsave'>
                                    Save
                                  </button>
                                </div>
                                <div className='col-md-5'>
                                  <div className='form-group position-relative'>
                                    <TextInput
                                      disabled
                                      label='EMAIL'
                                      name='email'
                                      type='text'
                                      className='form-control'
                                    />
                                    <div className='verify_icon'>
                                      <div className='form-group mt-md-4 mt-2 verifytext '></div>
                                      <img
                                        src={icons.verify_icon}
                                        alt='virify'
                                        className='ver_icon w-75'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                        <Formik
                          initialValues={{
                            new_password: '',
                            verify_OTP: '',
                          }}
                          validationSchema={changePasswordSchema}
                          onSubmit={handleChangePassword}
                        >
                          {({ values, errors, dirty, isValid }) => (
                            <Form className='change__pass' autoComplete='off'>
                              <h3>Update Contact Number</h3>
                              <div className='row'>
                                <div className='col-md-5 col-12'>
                                  <div className='form-group position-relative'>
                                    <TextInput
                                      label='New Contact Number'
                                      name='new_password'
                                      type='number'
                                      className='form-control'
                                      autoComplete='off'
                                    />
                                    <Button
                                      className='send_code'
                                      disabled={!values.new_password || errors.new_password}
                                      onClick={() => sendOTP(values.new_password)}
                                    >
                                      {showOTPField ? 'RESEND CODE' : 'SEND CODE'}
                                    </Button>
                                  </div>
                                  <button
                                    type='submit'
                                    disabled={!isOtpValid || !(dirty && isValid)}
                                    className='top_sp my_accountsave'
                                  >
                                    Save
                                  </button>
                                </div>
                                {showOTPField && (
                                  <div className='col-md-5 col-12'>
                                    <div className='form-group position-relative'>
                                      <TextInput
                                        label='VERIFY CODE'
                                        name='verify_OTP'
                                        type='tel'
                                        className='form-control'
                                      />
                                      <Button
                                        className={
                                          isOtpValid ? 'verify_code_success' : 'verify_code'
                                        }
                                        disabled={!values.verify_OTP || errors.verify_OTP}
                                        onClick={() =>
                                          verifyOTP(values.new_password, values.verify_OTP)
                                        }
                                      >
                                        {isOtpValid ? (
                                          <img src={icons?.SuccessIcon} style={{ width: '30%' }} />
                                        ) : (
                                          'VERIFY CODE'
                                        )}
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
