import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import './ScrollDialog.css';
export const ScrollDialog = () => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div className='scrol_dilog'>
      <Typography variant='contained' onClick={handleClickOpen('paper')}>
        What is this?
      </Typography>
      <Dialog
        PaperProps={{
          style: { borderRadius: 20 },
        }}
        open={open}
        className='dialog_box'
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <IconButton
          aria-label='close'
          onClick={handleClose}
          onTouchStart={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 999,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className='dailog_content' dividers={scroll === 'paper'}>
          <DialogContentText
            id='scroll-dialog-description'
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ fontFamily: 'Gotham regular', color: '#000' }}
          >
            <h2 style={{ textDecoration: 'underline', letterSpacing: '1px' }}>
              Post Market Value Info
            </h2>
            <p>{`The Post Market Value(PMV) is PostYourWatches's estimated retail price for a watch based on a detailed analysis of comparable current and previously sold watch as well as verified purchase offers. Our analysis takes into account specific details including dial variation,year, greater availability, demand, and condition. PostYourWatches's PMV should be used as a guide for making an educated offer decision. PostYourWatches strives to provide a fully transparent watch purchasing experience.`}</p>
            <br />
            <h2 style={{ textDecoration: 'underline', letterSpacing: '1px' }}>FAQ</h2>
            <div className='row question_row'>
              <div className='col-lg-3 col-4'>
                <p>Question :</p>
              </div>
              <div className='col-lg-9 col-8'>
                <p> My bid is lower than the highest bid,Can my offer still be accepted?</p>
              </div>

              <div className='col-lg-3 col-4'>
                <p>Answer :</p>
              </div>
              <div className='col-lg-9 col-8'>
                <p>{` Absolutely! The highest offer doesn't always get accepted for a variety of reasons. The generated win rate takes into account the potentially that this happens. Technically speaking,if every high offer was accepted 100% of the time, any offers lower than the high offer would have a win ratio of 0%.`}</p>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
