export const APPRAISAL = 'Appraisal';
export const LOG_IN = 'Log In';
export const VERIFICATION_CODE = 'Verification Code';
export const CONTACT_US = 'Contact Us';
export const FORGOT_PASSWORD = 'Forgot Password';
export const CHANGE_PASSWORD = 'Change Password';
export const UPDATE_PASSWORD = 'Update Password';
export const UPDATE_PROFILE = 'Update Profile';
export const ADD_TO_SAVED = 'Add To Saved';
export const UPDATE_PROFILE_PIC_TITLE = 'Update Profile';
export const BUY_NOW = 'Buy Now';
export const BUY_NOW_BEFORE_LOGIN = 'BUY_NOW_BEFORE_LOGIN';
export const MAKE_AN_OFFER = 'Make an Offer';
export const ZIP_CODE = 'Zip Code';
export const CONFIRM_USER_EXISTS = 'CONFIRM_USER_EXISTS';
