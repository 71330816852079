import React from 'react';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
);

export const Linechart = ({ graphData }) => {
  const getGradient = () => {
    var canvas = document.getElementById('canvas');
    var ctx = canvas.getContext('2d');
    var gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgba(55, 193, 188, 0.74)');
    gradient.addColorStop(1, 'rgba(210, 237, 255, 0)');
    return gradient;
  };
  // const handleLabel = (label) => {
  //   if (label < 9999) {
  //     return label;
  //   }

  //   if (label <= 1000000) {
  //     return Math.round(label / 1000) + 'k';
  //   }
  //   if (label < 10000000) {
  //     return (label / 1000000).toFixed(2) + 'M';
  //   }

  //   if (label < 1000000000) {
  //     return Math.round(label / 1000000) + 'M';
  //   }

  //   if (label < 1000000000000) {
  //     return Math.round(label / 1000000000) + 'B';
  //   }
  // };
  const getRandomColor = () => {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  var options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: false,
      },
    },
    scales: {
      y: {
        reverse: true,
        ticks: {
          callback: (value) => value + 'k',
          display: true,
        },
        position:'right',
        grid: { tickColor: 'white' },
      },
      x: {
        ticks: {
          maxRotation: 40,
          minRotation: 40,
        },
      },
    },
  };
  const labels = graphData?.data?.map((data, i) => [
    `Step ${i + 1}`,
    `$ ${Math.round(data?.price)}`,
  ]);
  const dummyData = [
    ['Step 1', `($10,000)`],
    ['Step 2', '($10,000)'],
    ['Step 3', '($10,000)'],
    ['Step 4', '($10,000)'],
    ['Step 5', '($10,000)'],
    ['Step 6', '($10,000)'],
    ['Step 7', '($10,000)'],
    ['Step 8', '($10,000)'],
    ['Step 9', '($10,000)'],
    ['Step 10', '($10,000)'],
  ];
  const yData = graphData?.data?.map((data) => data?.remaining_deduction_day);
  const data = {
    labels: graphData?.data?.length < 1 ? dummyData : labels,
    datasets: [
      {
        label: 'Views',
        data:
          graphData?.data?.length < 1
            ? [1000000, 600000, 480000, 200000, 100000, 500000, 400000, 300000, 200000, 100000]
            : yData,
        tension: 0.0,
        borderWidth: 2.5,
        backgroundColor: getGradient,
        pointBackgroundColor: getRandomColor,
        pointRadius: 5,
        fill: 'start',
        borderColor: 'rgba(55, 193, 188, 0.74)',
        // xAxisID: 'x1',
      },
    ],
  };
  const plugins = [
    {
      afterDraw: (chart) => {
        if (chart.tooltip?._active?.length) {
          let x = chart.tooltip._active[0].element.x;
          let yAxis = chart.scales.y;
          let ctx = chart.ctx;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, yAxis.top);
          ctx.lineTo(x, yAxis.bottom);
          ctx.lineWidth = 2;
          // ctx.strokeStyle = getRandomColor();
          ctx.setLineDash([6, 6]);
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];

  return (
    <div style={{ height: '250px' }}>
      <Line id='canvas' options={options} data={data} plugins={plugins} />
    </div>
  );
};
