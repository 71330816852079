import React from 'react';
import NumberFormat from 'react-number-format';

export function MyNumberInput(props) {
  const [state, setState] = React.useState({
    value: '',
  });
  return (
    <NumberFormat
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === '' || floatValue <= 1000000;
      }}
      placeholder='Number Format Input looses focus'
      isNumericString={true}
      thousandSeparator={true}
      value={state.value}
      onValueChange={(vals) => setState({ value: vals.formattedValue })}
      {...props}
    />
  );
}
