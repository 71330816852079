import * as TYPE from '../Types';

const initialState = {
  appraisalData: {},
};

export const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.SET_APPRAISAL_DATA:
      return {
        ...state,
        appraisalData: action.payload,
      };
    default:
      return state;
  }
};
