import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import './HomeBanner.css';
import { useNavigate } from 'react-router-dom';
import * as Path from '../../base/constants/RoutePath';
import { icons } from '../../base/constants/ImageConstants';
import { isAuthenticated } from '../../base/utils/utils';

export const HomeBanner = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (isAuthenticated()) {
      navigate(Path.APPRAISAL_PAGE);
    } else {
      navigate(Path.REGISTER_PAGE);
    }
  };
  return (
    <div className='banner_landing mb-md-0 mb-3'>
      {/* ------------------- Desktop View Start ------------------- */}
      <Container>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <div className='banner_form'>
              <img src={icons.BgWatch} alt='Watch' className='banner-watch d-md-block d-none' />
              <img src={icons.BgWatchMob} alt='Watch' className='banner-watch d-md-none d-block' />
              <h1 className='d-md-block d-none'>the best price for your watch </h1>
              <h1 className='d-md-none d-block'>
                the best price <span> for your watch</span>{' '}
              </h1>
              <div className=''>
                <button
                  type='submit'
                  className='button_form d-md-block d-none'
                  onClick={handleNavigate}
                >
                  <span className='d-md-inline-block d-none'>Get your </span> free appraisal
                </button>

                <button
                  type='submit'
                  className='button_form d-md-none d-inline-block'
                  onClick={handleNavigate}
                >
                  <span className='d-md-inline-block d-none'>Get your </span> Free appraisal
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* ------------------- Desktop View End ------------------- */}

      {/* ------------------- Mobile View Start -------------------*/}
      <div className='banner_form_mob d-md-none d-none '>
        <h1>
          the best price <br /> for your watch
        </h1>
        <p>it’s all free, no commitment</p>
        <span className='form_span_para'>get a free appraisal</span>
        <div className='form_tab form_bg mt-4'>
          <div className='form_data'>
            <button className='button_form' onClick={handleNavigate}>
              Get your free appraisal
            </button>
          </div>
        </div>
      </div>
      {/* ------------------- Mobile View End ------------------- */}
    </div>
  );
};
