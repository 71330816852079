import getAxios from '../../base/axos.config';
import * as TYPE from '../Types';
const api = getAxios();
export const getUserData = (url) => async (dispatch) => {
  return await api
    .get(url)
    .then(async (response) => {
      await dispatch({ type: TYPE.GET_USER_DETAILS_SUCCESS, payload: response?.data?.data });
      return response;
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.GET_USER_DETAILS_ERROR, payload: error });
    });
};

export const updateUserData = (url, data) => async () => {
  return await api
    .put(url, data)
    .then((response) => response)
    .catch((error) => error);
};

export const updateProfilePicAPI = async (url, data) => {
  return await api.post(url, data);
};
export const getUserWatch = (url) => async (dispatch) => {
  await api
    .get(url)
    .then(async (response) => {
      await dispatch({ type: TYPE.GET_USER_WATCHES, payload: response?.data?.data });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.GET_USER_WATCH_ERROR, payload: error });
    });
};
export const zipCodeVerify = (url, data) => async () => {
  return await api
    .post(url, data)
    .then((response) => response?.data)
    .catch((error) => error);
};
