import axios from 'axios';
import store from '../../Redux/Store';
import { ADD_WATCH_TO_FAVOURITE, REFRESH_TOKEN_URL } from '../constants/apiEndPoints';
import { refresh_token, token, userId } from '../constants/AppConstant';
import { openModal, pageRefresh } from '../../Redux/Actions/AppAction';
import { ADD_TO_SAVED } from '../constants/modalTitles';
import { ADD_TO_SAVE_AFTER_LOGIN } from '../../Redux/Types';
import { LOGIN_PAGE } from '../constants/RoutePath';

//Refresh Token

export const refreshToken = async () => {
  return await axios
    .post(REFRESH_TOKEN_URL, { refresh: localStorage.getItem(refresh_token) })
    .then((response) => response.data.access);
};

//Format Date

export const formatDate = (date) => {
  if (!date) return;
  let dateObj = new Date(date);
  // const dd = String(dateObj?.getDate())?.padStart(2, '0');
  const mm = dateObj?.toLocaleString('en-US', {
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
  // const yyyy = dateObj?.getFullYear();
  return (dateObj = `${mm} PT`);
};
//PDT FORMAT DATE
export const pdtDate = (date) => {
  if (!date) return;
  let dateObj = new Date(date);
  const pst = dateObj.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  });

  return pst;
};

//Response Handler

export const responseHandler = async (title, response) => {
  if (
    response?.data?.status_code === 200 ||
    response?.data?.status_code === 201 ||
    response?.status === 200
  ) {
    const filterResponse = {
      title,
      message: response?.data?.message,
      success: true,
    };
    await store.dispatch(openModal(filterResponse));
  }
  if (response?.data?.status_code === 400) {
    if (response?.data?.errors?.non_field_errors || response?.data?.errors?.phone_number) {
      if (response?.data?.errors?.non_field_errors) {
        const filterResponse = {
          title,
          message: response?.data?.errors?.non_field_errors[0] || 'Something went wrong',
          success: false,
        };
        store.dispatch(openModal(filterResponse));
      }
      if (response?.data?.errors?.phone_number) {
        const filterResponse = {
          title,
          message: response?.data?.errors?.phone_number[0] || 'Something went wrong',
          success: false,
        };
        store.dispatch(openModal(filterResponse));
      }
    } else {
      const filterResponse = {
        title,
        message: response?.data?.message || 'Something went wrong',
        success: false,
      };
      store.dispatch(openModal(filterResponse));
    }
  }
  if (response?.data?.status_code === 500) {
    const filterResponse = {
      title,
      message:
        response?.data?.errors?.non_field_errors[0] ||
        response?.data?.message ||
        'Something went wrong',
      success: false,
    };
    store.dispatch(openModal(filterResponse));
  }
};

export const isAuthenticated = () => {
  const authToken = window.localStorage.getItem(token);
  if (!authToken) {
    return false;
  }
  return true;
};

/******************  Add To Favorite *******************/

export const AddToFavourite = async (id, isFavourite, navigate) => {
  if (isAuthenticated()) {
    const response = await axios
      .post(
        ADD_WATCH_TO_FAVOURITE,
        {
          watches: id,
          user: localStorage.getItem(userId),
          is_favorite: isFavourite,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem(token)}` },
        },
      )
      .then((response) => response)
      .catch((error) => error);
    responseHandler(ADD_TO_SAVED, response);
    store.dispatch(pageRefresh());
  } else {
    navigate(LOGIN_PAGE);
    store.dispatch({
      type: ADD_TO_SAVE_AFTER_LOGIN,
      payload: { watches: id, is_favorite: isFavourite },
    });
  }
};

/*
    @desc - Check file width and height
    @return - boolean
  */
export async function checkFileWidthHeight(file, width = 600, height = 600) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        let isValid = true;
        if (image.width <= width || image.height <= height) {
          isValid = false;
        }
        resolve({ isValid, height: image.height, width: image.width });
      };
    };
    reader.onerror = reject;
  });
}

export const checkBidderName = (name) => {
  const nameArray = name.split(' ');
  if (
    name.toLowerCase().endsWith('null') &&
    name.slice(-4).toLowerCase() === 'null' &&
    nameArray.length > 1 &&
    nameArray[nameArray.length - 1].toLowerCase() === 'null'
  ) {
    // console.log(name.replace(/(null|NULL|Null)/g, '').trim());
    return name.replace(/(null|NULL|Null)/g, '').trim();
  }
  return name;
};
