import React, { useCallback, useState } from 'react';
import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
import { icons } from '../../base/constants/ImageConstants';
import CloseIcon from '@mui/icons-material/Close';
import { InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { appraisalSchema } from '../../base/validations';
import { DragDrop } from '../../components/DragDrop/DragDrop';
import { Link, useNavigate } from 'react-router-dom';
import { AutoComplete, CustomButton } from '../../components';
import { manuFacturerOptions } from '../../base/constants/manufactureList';
import { useDispatch } from 'react-redux';
import { APPRAISAL_URL } from '../../base/constants/apiEndPoints';
import { postFormData } from '../../Redux/Actions';
import * as PATH from '../../base/constants/RoutePath';
import { useEffect } from 'react';
import { responseHandler } from '../../base/utils/utils';
import { APPRAISAL } from '../../base/constants/modalTitles';
import './Appraisal.css';
import { CustomizedDialogs } from '../../components/CropImage/CustomizedDialogs';
import { resizeFile } from '../../components/CropImage/ImageResize';
import { MyNumberInput } from '../../components/MyNumberInput/MyNumberInput';
import { useDeviceDetect } from '../../hooks';
import { blobToFile } from '../../base/utils/stringUtils';
const Radio = ({ ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <input type='radio' {...field} {...props} />
    </>
  );
};
// eslint-disable-next-line no-unused-vars
const TextInput = ({ ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      {meta.touched && meta.error ? (
        <input
          className='form-control'
          style={{
            borderColor: 'red',
          }}
          {...field}
          {...props}
        />
      ) : (
        <input className='form-control' {...field} {...props} />
      )}
      {meta.touched && meta.error ? (
        <div
          className='error'
          style={{
            color: 'red',
            borderColor: 'red',
            fontSize: '11px',
            marginTop: '5px',
          }}
        >
          {meta.error}
        </div>
      ) : null}
    </>
  );
};
export const Appraisal = () => {
  const [files, setFiles] = useState([]);
  // const [showPreview, setShowPreview] = useState('');
  const [isFilesError, setIsFilesError] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imageName, setImageName] = React.useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    brand: '',
    model: '',
    year: '',
    serialNumber: '',
    papers: '',
    box: '',
    condition: '',
    askingPrice: '',
    images: [],
  };
  const { isMobile } = useDeviceDetect();
  const handlesubmit = async (values, submitProps) => {
    var formData = new FormData();
    formData.append('brand', values.brand);
    formData.append('model', values.model);
    formData.append('year', values.year);
    formData.append('serial', values.serialNumber);
    formData.append('papers', values.papers);
    formData.append('box', values.box);
    formData.append('condition', values.condition);
    formData.append('ask_price', values.askingPrice);
    for (let img of files) {
      if (isMobile) {
        let fImg = blobToFile(img, 'xyz.png');
        formData.append('images', fImg);
      } else {
        const image = await resizeFile(img);
        formData.append('images', image);
      }
    }
    const response = await dispatch(postFormData(APPRAISAL_URL, formData));
    responseHandler(APPRAISAL, response);
    if (response?.status === 200 || response?.status === 201) {
      navigate(PATH.INVENTORY_PAGE);
      submitProps.setSubmitting(false);
      submitProps.resetForm();
    }
  };
  // const onShowPreview = (event) => {
  //   setShowPreview(event.target.src);
  // };

  const onUploadFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const image = reader.result;
        setImageToCrop(image);
      });
      reader.readAsDataURL(file[0]);
    }
  };

  const setRemoveImage = () => {
    setImageToCrop(undefined);
  };

  const onselectCrop = (forwardImg) => {
    uploadedFiles.map((img, index) => {
      if (img?.path === forwardImg?.name) {
        uploadedFiles[index + 1]
          ? setImageName(uploadedFiles[index + 1]?.path)
          : uploadedFiles[index - 1]
          ? setImageName(uploadedFiles[index - 1]?.path)
          : setImageName(uploadedFiles[index]?.path);
        var reader = new window.FileReader();
        reader.readAsDataURL(
          uploadedFiles[index + 1]
            ? uploadedFiles[index + 1]
            : uploadedFiles[index - 1]
            ? uploadedFiles[index - 1]
            : uploadedFiles[index],
        );
        reader.onloadend = function () {
          var base64data = reader.result;
          setImageToCrop(base64data);
        };
        uploadedFiles?.splice(index, 1);
        setFiles((old) => [...old, forwardImg]);
      }
    });
  };

  const deleteImg = (index) => {
    const updatedArray = files.filter((x, i) => i !== index);
    setFiles(updatedArray);
  };
  const handleFileChange = async (acceptedFiles) => {
    // let blobImg = acceptedFiles.map((img) => img);
    // setFiles((oldArray) => [...oldArray, ...acceptedFiles]);
    setUploadedFiles(acceptedFiles);
    setImageName(acceptedFiles[0]?.path);
    onUploadFile(acceptedFiles);
  };

  const handleFormSubmit = () => {
    if (files.length < 3) {
      setIsFilesError(true);
      return;
    }
    if (files.length > 3 && files.length > 6) {
      setIsFilesError(true);
      return;
    }
    setIsFilesError(false);
  };

  useEffect(() => {
    if (files.length >= 3) {
      setIsFilesError(false);
      return;
    }
  }, [files]);

  useCallback(() => {
    handleFormSubmit();
  }, [files]);

  return (
    <div>
      <div className='appraisel'>
        <div className='container'>
          <div className='appraisel_top'>
            <img
              src={icons.LongArrow}
              alt=''
              style={{ width: '14px', height: '14px', cursor: 'pointer' }}
              onClick={() => navigate('/')}
            />
            <Link to='/'>Home</Link>
            <img src={icons.RightArrow} alt='' style={{ width: '6px', marginLeft: '10px' }} />
            <h6>Appraisal</h6>
          </div>
        </div>
      </div>
      <section className='appraisel_banner'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-12 '>
              <h1>Get your offer</h1>
              <p>
                Share a few details about your Watch <br /> and well give you a real offer right
                now.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='additional'>
        <Formik
          initialValues={initialValues}
          validationSchema={appraisalSchema}
          onSubmit={handlesubmit}
        >
          {({ errors, touched, dirty, isValid, setFieldValue, values }) => (
            <Form className='formBody' autoComplete='off'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='additinal_box'>
                      <h2>We need some additional info</h2>
                      <p>
                        To provide an accurate offer, please provide correct information of your
                        watch.
                      </p>
                      <Field
                        name='brand'
                        component={AutoComplete}
                        data={manuFacturerOptions}
                        label='Brand'
                        error={errors.brand && touched.brand}
                      />
                      <ErrorMessage name='brand'>
                        {(msg) => (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '11px',
                              marginTop: '5px',
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                      <InputLabel className='inputLabel' htmlFor='outlined-adornment-amount'>
                        Model
                      </InputLabel>
                      <Field name='model'>
                        {({ field }) => (
                          <OutlinedInput
                            startAdornment={<InputAdornment position='start'></InputAdornment>}
                            label='Model'
                            error={errors.model && touched.model}
                            {...field}
                          />
                        )}
                      </Field>
                      {errors.model && touched.model && (
                        <div
                          className='error'
                          style={{
                            color: 'red',
                            borderColor: 'red',
                            fontSize: '11px',
                          }}
                        >
                          {errors.model}
                        </div>
                      )}

                      <InputLabel className='inputLabel' htmlFor='outlined-adornment-amount'>
                        Year
                      </InputLabel>
                      <Field name='year'>
                        {({ field }) => (
                          <OutlinedInput
                            startAdornment={<InputAdornment position='start'></InputAdornment>}
                            label='Year'
                            error={errors.year && touched.year}
                            {...field}
                          />
                        )}
                      </Field>
                      <ErrorMessage name='year'>
                        {(msg) => (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '11px',
                              marginTop: '5px',
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                      <InputLabel className='inputLabel' htmlFor='outlined-adornment-amount'>
                        Reference
                      </InputLabel>
                      <Field name='serialNumber'>
                        {({ field }) => (
                          <OutlinedInput
                            startAdornment={<InputAdornment position='start'></InputAdornment>}
                            error={errors.serialNumber && touched.serialNumber}
                            label='Reference'
                            {...field}
                          />
                        )}
                      </Field>
                      <ErrorMessage name='serialNumber'>
                        {(msg) => (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '11px',
                              marginTop: '5px',
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className='appraisal_radio'>
                      <h5>Papers</h5>
                      <label>
                        <Radio name='papers' value='YES' id={0} />
                        <span className='right'>YES</span>
                      </label>
                      <label>
                        <Radio name='papers' value='NO' id={1} />
                        <span className='left'>NO</span>
                      </label>
                      {errors.papers && touched.papers && (
                        <div
                          className='error'
                          style={{
                            color: 'red',
                            borderColor: 'red',
                            fontSize: '11px',
                          }}
                        >
                          {errors.papers}
                        </div>
                      )}
                    </div>

                    <div className='appraisal_radio'>
                      <h5>Box</h5>
                      <label>
                        <Radio name='box' value='YES' />
                        <span className='right'>YES</span>
                      </label>
                      <label>
                        <Radio name='box' value='NO' />
                        <span className='left'>NO</span>
                      </label>
                      {errors.box && touched.box && (
                        <div
                          className='error'
                          style={{
                            color: 'red',
                            borderColor: 'red',
                            fontSize: '11px',
                          }}
                        >
                          {errors.box}
                        </div>
                      )}
                    </div>
                    <div className='appraisal_radio'>
                      <h5>Condition</h5>
                      <label>
                        <Radio name='condition' value='Poor' />
                        <span className='right'>Poor</span>
                      </label>
                      <label>
                        <Radio name='condition' value='Average' />
                        <span className='left_right'>Average</span>
                      </label>
                      <label>
                        <Radio name='condition' value='Good' />
                        <span className='right_left'>Good</span>
                      </label>
                      <label>
                        <Radio name='condition' value='Excellent' />
                        <span className='left'>Excellent</span>
                      </label>
                      {errors.condition && touched.condition && (
                        <div
                          className='error'
                          style={{
                            color: 'red',
                            borderColor: 'red',
                            fontSize: '11px',
                          }}
                        >
                          {errors.condition}
                        </div>
                      )}
                    </div>
                    <div className='asking'>
                      <div className='asking_price'>
                        <h4>What is your asking price ?</h4>
                        <h5>Currency</h5>
                      </div>
                      <div className='form-group'>
                        {/* <TextInput 
                          type='number' 
                          name='askingPrice'
                          className='form-control'
                          value={values.askingPrice}
                          onValueChange={val => setFieldValue("askingPrice", val.floatValue)}
                        /> */}
                        <MyNumberInput
                          placeholder=''
                          className='form-control'
                          value={values.askingPrice}
                          onValueChange={(val) => setFieldValue('askingPrice', val.floatValue)}
                        />
                        <span>Usd</span>
                      </div>
                    </div>
                    <div className='drag_drop'>
                      <h5>Add photos</h5>
                      <div className='drop_box'>
                        <div className='row'>
                          <div className='col-lg-4 col-sm-5 col-12 '>
                            <div className='choose_file'>
                              <p>
                                The more photos you include, the more <br /> accurate your valuation
                                will be.
                              </p>
                              <div className={files.length > 0 ? 'drag_zone' : ''}>
                                <div className='newDragDrop'>
                                  <Field
                                    type='file'
                                    component={DragDrop}
                                    name='images'
                                    fileCount={files}
                                    handleFileChange={handleFileChange}
                                  />
                                </div>
                                {imageToCrop && (
                                  <CustomizedDialogs
                                    imageToCrop={imageToCrop}
                                    setRemoveImage={setRemoveImage}
                                    page='appraisal'
                                    onBlob={onselectCrop}
                                    uploadedFiles={uploadedFiles}
                                    files={files}
                                    setFiles={setFiles}
                                    setImageToCrop={setImageToCrop}
                                    setUploadedFiles={setUploadedFiles}
                                    imageName={imageName}
                                    setImageName={setImageName}
                                  />
                                )}
                                {/* <img src={icons.Drag} /> */}
                              </div>
                            </div>
                            {files.length > 0 && (
                              <div className='preview_img'>
                                <h6>Preview</h6>
                                {files?.map((img, key) => {
                                  return (
                                    <div key={key} className='prewi_imge'>
                                      <img src={window.URL.createObjectURL(img)} alt='priviewImg' />
                                      <CloseIcon
                                        className='cross_img'
                                        onClick={() => deleteImg(key)}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            {isFilesError && (
                              <div
                                className='error'
                                style={{
                                  color: 'red',
                                  borderColor: 'red',
                                  fontSize: '11px',
                                }}
                              >
                                <span>*Required</span>
                              </div>
                            )}
                          </div>
                          <div className='col-lg-8 col-sm-7 col-12'>
                            <div className='example_photo'>
                              <h6>Example Photos</h6>
                              {/* <img src={showPreview === '' ? icons.Drop1 : showPreview} /> */}
                              <div className='row'>
                                <div className='col-md-4 col-sm-4 col-4'>
                                  <img src={icons.example_image_1} className='img-fluid' />
                                </div>
                                <div className='col-md-4 col-sm-4 col-4'>
                                  <img src={icons.example_image_2} className='img-fluid' />
                                </div>
                                <div className='col-md-4 col-sm-4 col-4'>
                                  <img src={icons.example_image_3} className='img-fluid' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-sm-5 col-12'>
                      {/* <button
                        className={
                          !(dirty && isValid) ? 'form_disable_button' : 'form_contact_button'
                        }
                        type='submit'
                        disabled={!(dirty && isValid)}
                        onClick={() => handleFormSubmit()}
                      >
                        Get My Quote
                      </button> */}
                      <CustomButton
                        type='submit'
                        disabled={!(dirty && isValid)}
                        className={
                          !(dirty && isValid) ? 'form_disable_button' : 'form_contact_button'
                        }
                        title='Get My Quote'
                        handleFormSubmit={handleFormSubmit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </div>
  );
};
