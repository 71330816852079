import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { getUserData, handleLoginModal, postFormData } from '../../../Redux/Actions';
import { icons } from '../../../base/constants/ImageConstants';
import '../Modal.css';
import { Form, Formik } from 'formik';
import { validationLoginSchema } from '../../../base/validations';
import { APPRAISAL_URL, GET_USER_URL, LOGIN_URL } from '../../../base/constants/apiEndPoints';
import { refresh_token, token, userId } from '../../../base/constants/AppConstant';
import { APPRAISAL, LOG_IN } from '../../../base/constants/modalTitles';
import { TextInput } from '../../Input';
import { responseHandler } from '../../../base/utils/utils';
import { useNavigate } from 'react-router-dom';
import { INVENTORY_PAGE } from '../../../base/constants/RoutePath';
import './LoginModal.css';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 430,
  height: 400,
  bgcolor: 'background.paper',
  border: '0px solid #fff',
  boxShadow: 24,
  borderRadius: '20px',
  p: 2,
  zIndex: '100000',
  outline: 'none',
};

export const LoginModal = () => {
  //   const navigate = useNavigate();
  const isOpen = useSelector((state) => state?.appReducer?.showLoginModal);
  const appraisalData = useSelector((state) => state?.formReducer?.appraisalData);
  const initialValues = {
    email: '',
    password: '',
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => dispatch(handleLoginModal(!isOpen));
  const onSubmit = async (values, submitProps) => {
    const response = await dispatch(postFormData(LOGIN_URL, values));
    await dispatch(handleLoginModal(!isOpen));
    responseHandler(LOG_IN, response);
    if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
      localStorage.setItem(token, response?.data?.data?.tokens);
      localStorage.setItem(refresh_token, response?.data?.data?.refresh_token);
      localStorage.setItem(userId, response?.data?.data?.id);
      dispatch(getUserData(GET_USER_URL));
      const apiResponse = await dispatch(postFormData(APPRAISAL_URL, appraisalData));
      navigate(INVENTORY_PAGE);
      responseHandler(APPRAISAL, apiResponse);
    }
    submitProps.setSubmitting(false);
    submitProps.resetForm();
  };
  return (
    <div>
      <Modal
        open={isOpen ? isOpen : false}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} className='modal_container'>
          <div className='close_icon'>
            <img src={icons.closeIcon} onClick={handleClose} />
            {/* <button onClick={handleClose}>x</button> */}
          </div>
          <div>
            <img src={icons?.Logo} className='status_image' />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationLoginSchema}
            onSubmit={onSubmit}
          >
            <Form className='formBody' autoComplete='off'>
              <TextInput placeholder='Email' name='email' type='email' />

              <TextInput placeholder='Password' name='password' type='password' />
              <button className='login_button' type='submit'>
                Login
              </button>
              {/* <div className='forgot_password'>
                <Link to={PATH.FORGOT_PASSWORD}>Forgot Password?</Link>
              </div> */}
            </Form>
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};
