import * as TYPE from '../Types';

const initialState = {
  graphData: [],
  bidsData: [],
  likeData: [],
  stepsData: [],
  error: '',
  winRate: '',
};

export const productViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.GET_GRAPH_SUCCESS:
      return {
        ...state,
        graphData: action.payload,
      };
    case TYPE.GET_GRAPH_ERROR:
      return {
        ...state,
        graphData: action.payload,
      };
    case TYPE.GET_BIDS_SUCCESS:
      return {
        ...state,
        bidsData: action.payload,
      };
    case TYPE.GET_BIDS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TYPE.GET_LIKE_DATA_SUCCESS:
      return {
        ...state,
        likeData: action.payload,
      };
    case TYPE.GET_LIKE_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TYPE.GET_STEPS_DETAIL_SUCCESS:
      return {
        ...state,
        stepsData: action.payload,
      };
    case TYPE.GET_STEPS_DETAIL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TYPE.GET_WIN_RATE_SUCCESS:
      return {
        ...state,
        winRate: action.payload,
      };
    case TYPE.RESET_STATE:
      return { ...state, bidsData: [], graphData: [] };
    default:
      return state;
  }
};
