import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';

export const CustomButton = (props) => {
  const { type, className, title, handleFormSubmit, disabled } = props;
  const isLoading = useSelector((state) => state.appReducer.isLoading);
  const handleButtonClick = () => {
    handleFormSubmit && handleFormSubmit();
  };
  return (
    <LoadingButton
      onClick={handleButtonClick}
      loading={isLoading}
      variant='outlined'
      loadingPosition='end'
      type={type}
      className={className}
      disabled={disabled}
    >
      {title}
    </LoadingButton>
  );
};
