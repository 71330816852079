import React from 'react';

import { icons } from '../../base//constants/ImageConstants';
import './Footer.css';
import { Link, useNavigate } from 'react-router-dom';

export const Footer = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };
  return (
    <div>
      <div className='footer'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3 col-12'>
              <div className='icons.Logo_img logo1-img1'>
                <img
                  style={{ cursor: 'pointer' }}
                  src={icons.Logo}
                  alt='icons.Logo'
                  onClick={handleClick}
                  className='footer_logo'
                />
              </div>
            </div>
            <div className='col-lg-2 col-md-3 col-6'>
              <h4>Overview</h4>

              <ul className='p-0'>
                <li>
                  <Link to='/about' className='foot_link' onClick={() => window.scrollY(0, 0)}>
                    About us
                  </Link>
                </li>

                <li className='foot_link'>
                  {/* <a href='#' className='foot_link'> */}
                  How it works
                  {/* </a> */}
                </li>

                <li>
                  <Link to='/contact' className='foot_link' onClick={() => window.scrollY(0, 0)}>
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
            <div className='col-lg-4 col-md-4 col-6'>
              <h4>Quick Links</h4>

              <ul className='p-0'>
                <li className='foot_link'>
                  {/* <a href='#' className='foot_link'> */}
                  Terms & Conditions
                  {/* </a> */}
                </li>

                <li className='foot_link'>
                  {/* <a href='#' > */}
                  FAQs
                  {/* </a> */}
                </li>

                <li className='foot_link'>
                  {/* <a href='#'> */}
                  Privacy Policy
                  {/* </a> */}
                </li>
              </ul>
            </div>
            <div className='col-lg-3 col-md-5 col-12 position-relative'>
              <div className='position_absolute'>
                <h4>Contact</h4>
                <ul className='p-0 contact_list'>
                  <li className='foot_link'>
                    <img src={icons.Email} className='mr-2' alt='icons.Email' />{' '}
                    contact@postyourwatches.com
                  </li>

                  <li className='foot_link'>
                    <img src={icons.Address} className='mr-2' alt='locatin' /> 4 Park Plaza Irvine
                    California.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='copyright'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-12 pt-2 text-md-left text-center'>
              <p>© Copyright Inpublic 2022</p>
            </div>
            <div className='col-lg-6 col-md-6 col-12 social_icon '>
              <ul className='p-0 mb-0'>
                <li>
                  <a href='#'>
                    <img src={icons.Facebook} alt='Facebbok' />
                  </a>
                </li>

                <li>
                  <a href='#'>
                    <img src={icons.Instagram} alt='icons.Instagram' />
                  </a>
                </li>

                <li>
                  <a href='#'>
                    <img src={icons.Youtube} alt='icons.Youtube' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
