import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { MY_WATCH_PAGE_URL, REPOST_WATCH_URL } from '../../base/constants/apiEndPoints';
import { CCC_ACCEPT, CCC_COMPLETE, ICO_ACCEPT } from '../../base/constants/AppConstant';
import { icons } from '../../base/constants/ImageConstants';
import { APPRAISAL } from '../../base/constants/modalTitles';
import * as PATH from '../../base/constants/RoutePath';
import { responseHandler } from '../../base/utils/utils';
import { ImageSkeleton } from '../../components/Skeleton';
import { pageRefresh, repostWatch } from '../../Redux/Actions';
import { getUserWatch } from '../../Redux/Actions/UserAction';
import './MyWatch.css';

export const MyWatch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = React.useState(true);
  const userWatch = useSelector((state) => state.userReducer.userWatches);
  const isRefresh = useSelector((state) => state.appReducer.refresh);
  React.useEffect(() => {
    const getMyWatch = async () => {
      await dispatch(getUserWatch(MY_WATCH_PAGE_URL));
    };
    getMyWatch();
  }, [dispatch, isRefresh]);
  const handleRepost = async (id) => {
    const response = await dispatch(repostWatch(REPOST_WATCH_URL, id));
    responseHandler(APPRAISAL, response);
    await dispatch(pageRefresh());
  };
  return (
    <div>
      <section className='sec_mywatch_area'>
        <div className='container-fluid pr-md-5'>
          <div className='row'>
            <div className='col-xl-2 col-lg-3 col-md-3 col-12 mobv'>
              <div className='sidebar'>
                <ul>
                  <li className='active'>
                    <Link to={PATH.MY_WATCH_PAGE}>
                      <span className='dark'>
                        <img className='img-fluid' src={icons.img_aprove} alt='reconditioning' />
                      </span>
                      <span className='light'>
                        <img className='img-fluid' src={icons.approvel_img} />
                      </span>{' '}
                      My Watches
                    </Link>
                  </li>

                  <li>
                    <Link to={PATH.MY_ACCOUNT}>
                      <span className='dark'>
                        <img className='img-fluid' src={icons.img_account} alt='reconditioning' />
                      </span>
                      <span className='light'>
                        <img className='img-fluid' src={icons.account_act} />
                      </span>{' '}
                      My Account
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className='col-xl-10 col-lg-9 col-md-9 col-12'>
              <div className='right_content'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='cell_btn'>
                      <Link to={PATH.APPRAISAL_PAGE}>Sell My Watch</Link>
                    </div>
                  </div>
                </div>

                <div className='row '>
                  {userWatch &&
                    userWatch?.map((watch, key) => {
                      // if (watch?.watch_apporved === true) {
                      return watch?.status_type === 'ccc_complete' ? (
                        <div key={key} className='col-md-3 sold_cart'>
                          {isLoading && <ImageSkeleton width={250} height={250}/>}
                          <div className={isLoading ? 'watch_box_none' : 'watch_box'}>
                            <div className='img_box'>
                              <img
                                onLoad={() => setLoading(false)}
                                src={watch?.main_image ? watch?.main_image : icons.img_watch}
                                alt='reconditioning'
                              />
                              {/* <div className='sold_div'>Sold</div> */}
                            </div>
                            <div className='watch_content'>
                              <h2>
                                <a href='#!'>
                                  {watch.brand} | {watch.model}
                                </a>
                              </h2>
                              {/* <ul>
                                <li>{watch.model}</li>
                              </ul> */}
                              <button className='my_accountsave my_watchsave'>Sold</button>
                            </div>
                          </div>
                          <div className={isLoading ? 'centered_none' : 'centered'}>SOLD</div>
                        </div>
                      ) : (
                        <div key={key} className='col-lg-3  col-sm-4 col-6'>
                          {isLoading && <ImageSkeleton width={250} height={250}/>}
                          <div className={isLoading ? 'watch_box_none' : 'watch_box'}>
                            <div className='img_box'>
                              <img
                                onLoad={() => setLoading(false)}
                                src={watch?.main_image ? watch?.main_image : icons.img_watch}
                                alt='reconditioning'
                              />
                              {/* <div className='sold_div'>Sold</div> */}
                            </div>
                            <div className='watch_content'>
                              <h2>
                        
                                  {watch.brand} | {watch.model}
                            
                              </h2>
                              {/* <ul>
                                <li>{watch.model}</li>
                              </ul> */}
                              <button
                                className='my_accountsave my_watchsave'
                                onClick={() => {
                                  watch?.status_type === 'expired' ||
                                  watch?.status_type === 'rejected'
                                    ? handleRepost(watch?.id)
                                    : watch?.status_type === ICO_ACCEPT ||
                                      watch?.status_type === CCC_ACCEPT
                                    ? navigate(`/productview/watch/${watch?.id}`)
                                    : null;
                                }}
                              >
                                {watch?.status_type === ICO_ACCEPT ||
                                watch?.status_type === CCC_ACCEPT
                                  ? 'View'
                                  : watch?.status_type === CCC_COMPLETE
                                  ? 'Sold'
                                  : watch?.status_type === 'expired' ||
                                    watch?.status_type === 'rejected'
                                  ? 'Repost'
                                  : watch?.status_type === 'new'
                                  ? 'Reviewing'
                                  : null}
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
