import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { icons } from '../../base/constants/ImageConstants';

export const BrandFocus = () => {
  const navigate = useNavigate();
  const getbranddetail = (brand) => {
    navigate(`/brands/${brand}`);
  };
  return (
    <div className='brand_section pt-2 mt-md-2'>
      <Container>
        <h2 className='text-left '>Brand focus</h2>
        <Row className='brand_div'>
          <Col md={2} xs={2} className='d-md-inline-block'>
            <div className='brand_img' onClick={() => getbranddetail('PatekPhilippe')}>
              <img src={icons.Brand1} alt='Brand ' className='breguest' />
            </div>
            <p>PATEK PHILIPPE</p>
          </Col>
          <Col md={2} xs={2} className='d-md-inline-block'>
            <div className='brand_img' onClick={() => getbranddetail('RichardMille')}>
              <img src={icons.Brand2} alt='Brand' style={{ maxWidth: '60%' }} />

              {/* <img src={icons.MobBrand2}
              className='d-md-none d-block'
               alt='Brand'
               style={{maxWidth : '86%'}}
               /> */}
            </div>
            <p>RICHARD MILLE</p>
          </Col>

          <Col md={2} xs={2} className='d-md-inline-block'>
            <div className='brand_img' onClick={() => getbranddetail('VacheronConstantin')}>
              <img src={icons.Brand3} alt='Brand' style={{ maxWidth: '65%' }} />
            </div>
            <p>VACHERON CONSTANTIN</p>
          </Col>

          <Col md={2} xs={2} className='d-md-inline-block'>
            <div className='brand_img ap_img' onClick={() => getbranddetail('AudemarsPiguet')}>
              <img src={icons.Brand4} alt='Brand' className='ap_icon' />
            </div>
            <p>Audemars Piguet</p>
          </Col>

          <Col md={2} xs={2} className='d-md-inline-block'>
            <div className='brand_img' onClick={() => getbranddetail('A.Lange&Söhne')}>
              <img src={icons.Brand5} alt='' />
            </div>
            <p>A. Lange & Söhne</p>
          </Col>
        </Row>
        <Row>
          <Col md={2} xs={2} className='d-md-inline-block'>
            <div className='brand_img' onClick={() => getbranddetail('Piaget')}>
              <img src={icons.Brand6} alt='Brand' className='rolex' />
            </div>
            <p>PIAGET</p>
          </Col>

          <Col md={2} xs={2} className='d-md-inline-block'>
            <div className='brand_img' onClick={() => getbranddetail('Jaeger-LeCoultre')}>
              <img src={icons.Brand7} alt='Brand' style={{ maxWidth: '65%' }} />
            </div>
            <p>Jaeger- LeCoultre</p>
          </Col>

          <Col md={2} xs={2} className='d-md-inline-block'>
            <div className='brand_img' onClick={() => getbranddetail('ParmigianiFleurier')}>
              <img
                src={icons.Brand8}
                alt='Brand'
                className='parmigiani'
                style={{ maxWidth: '55%' }}
              />
            </div>
            <p>Parmigiani Fleurier</p>
          </Col>

          <Col md={2} xs={2} className='d-md-inline-block'>
            <div className='brand_img' onClick={() => getbranddetail('Rolex')}>
              <img src={icons.Brand9} alt='Brand' className='rolex' />
            </div>
            <p>ROLEX</p>
          </Col>

          <Col md={2} xs={2} className='d-md-inline-block'>
            <div className='brand_img' onClick={() => getbranddetail('Breguet')}>
              <img src={icons.Brand10} alt='Brand' className='breguest' />
            </div>
            <p>Breguet</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
