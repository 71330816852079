/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
// const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
var phoneRegExp = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
// --------------- Appraisal Validation --------------- //
export const registerSchema = Yup.object({
  brand: Yup.string().required('*Required'),
  model: Yup.string().required('*Required'),
  country: Yup.string().required('*Required'),
  images: Yup.array(),
  year: Yup.string()
    .matches(/^[0-9]*$/, 'Must be a number')
    .typeError('Must be a number.')
    .matches(/^[0-9]{4}$/, 'Year should be in the format YYYY')
    .required('*Required'),
  askingPrice: Yup.string().required('*Required'),
  serialNumber: Yup.string().matches(/^[a-zA-Z0-9]*$/, 'Must be Alphanumerical'),
  papers: Yup.string().required('*Required'),
  box: Yup.string().required('*Required'),
  condition: Yup.string().required('*Required'),
  fname: Yup.string().required('Please enter your First Name'),
  lname: Yup.string().required('Please enter your Last Name'),
  email: Yup.string().required('Please enter your Email').email('Please enter valid Email'),
  phone: Yup.string()
    .required('Please enter Phone Number')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
  verify_OTP: Yup.string().required('Please enter Verification Code'),
  password: Yup.string(),
  confirmPassword: Yup.string(),
  zipCode: Yup.string()
    .matches(/^[0-9]*$/, 'Must be a number')
    .typeError('Must be a number.')
    .matches(/\b\d{5}\b/g, 'Zipcode should be of 5 digits')
    .required('*Required'),
});
export const appraisalSchema = Yup.object({
  brand: Yup.string().required('*Required'),
  model: Yup.string().required('*Required'),
  images: Yup.array(),
  year: Yup.string()
    .matches(/^[0-9]*$/, 'Must be a number')
    .typeError('Must be a number.')
    .matches(/^[0-9]{4}$/, 'Year should be in the format YYYY')
    .required('*Required'),
  askingPrice: Yup.string().required('*Required'),
  serialNumber: Yup.string().matches(/^[a-zA-Z0-9]*$/, 'Must be Alphanumerical'),
  papers: Yup.string().required('*Required'),
  box: Yup.string().required('*Required'),
  condition: Yup.string().required('*Required'),
});
// ------------- Buy Now Validation------------- //
export const emailExistBuyNow = Yup.object({
  email: Yup.string().required('Please enter your Email').email('Please enter valid Email'),
  password: Yup.string()
    .required('Please enter your Cell Phone number')
    .matches(phoneRegExp, 'Cell Phone is not valid'),
  verify_OTP: Yup.string().required('Please enter Verification Code'),
});
export const validationBuyNowSchema = Yup.object({
  name: Yup.string()
    .required('Please enter your Name')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  email: Yup.string().required('Please enter your Email').email('Please enter valid Email'),
  cell_phone: Yup.string()
    .required('Please enter your Cell Phone number')
    .matches(phoneRegExp, 'Cellphone number is not valid'),
  verify_OTP: Yup.string().required('Please enter Verification Code'),
});
// ------------- Make An Offer Validation------------- //
export const validationMakeAnOfferSchema = Yup.object({
  name: Yup.string().required('Please enter your Name'),
  email: Yup.string().required('Please enter your Email').email('Please enter valid Email'),
  cell_phone: Yup.string()
    .required('Please enter your Cellphone number')
    .matches(phoneRegExp, 'Cellphone number is not valid'),
});
export const validationMakeAnOfferCCCSchema = Yup.object({
  name: Yup.string().required('Please enter your Name'),
  email: Yup.string().required('Please enter your Email').email('Please enter valid Email'),
  cell_phone: Yup.string()
    .required('Please enter your Cellphone number')
    .matches(phoneRegExp, 'Cellphone number is not valid'),
  verify_OTP: Yup.string().required('Please enter Verification Code'),
});
// -------------- Login Validation ---------------- //
export const validationLoginSchema = Yup.object({
  email: Yup.string().required('Please enter your Email').email('Please enter valid Email'),
  password: Yup.string().min(6, 'Must be atleat 6 characters.').required('*Required'),
});
// ------------- contact us validation ------------- //
export const contactUsSchema = Yup.object({
  firstName: Yup.string().required('Please enter your First Name'),
  lastName: Yup.string().required('Please enter your Last Name'),
  email: Yup.string().required('Please enter your Email').email('Please enter valid Email'),
  subject: Yup.string().required('Please enter your Subject'),
  message: Yup.string().required('Please enter your message'),
});
// ------------- confirm password validation ---------------- //
export const confimPasswordSchema = Yup.object({
  newPassword: Yup.string()
    .min(6, 'Must be atleat 6 characters.')
    .required('New Password Is Required'),
  confirmPassword: Yup.string()
    .required('Confirm Password Is Required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

// ------------- Update Uer validation ---------------- //

export const updateUserSchema = Yup.object({
  first_name: Yup.string(),
  last_name: Yup.string(),
  email: Yup.string().email('Please enter valid Email'),
  phone_number: Yup.string().matches(phoneRegExp, 'Please enter valid phone number'),
});

// -------------Change Password validation ---------------- //

export const changePasswordSchema = Yup.object({
  new_password: Yup.string()
    .required('Please enter your Cell Phone number')
    .matches(phoneRegExp, 'Cellphone number is not valid'),
  verify_OTP: Yup.string().required('*Required'),
});
