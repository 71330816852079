import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { handleLoginModal, openDetailModal, pageRefresh } from '../../../Redux/Actions/AppAction';
import { icons } from '../../../base/constants/ImageConstants';
import './DeatilPageModal.css';
import { Button } from '@mui/material';
import { postFormData } from '../../../Redux/Actions';
import {
  BUY_NOW_URL,
  // GET_GRAPH_DATA_BEFORE_LOGIN,
  MAKE_OFFER_URL,
} from '../../../base/constants/apiEndPoints';
import {
  APPRAISAL,
  BUY_NOW,
  BUY_NOW_BEFORE_LOGIN,
  CONFIRM_USER_EXISTS,
  MAKE_AN_OFFER,
} from '../../../base/constants/modalTitles';
import { CLOSE_MODAL, VALIDATE_USER_EMAIL } from '../../../Redux/Types';
import { closeDeatilModal } from '../../../Redux/Actions/AppAction';
// import { fetchGraphData } from '../../../Redux/Actions/ProductViewActions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 430,
  height: 350,
  bgcolor: 'background.paper',
  border: '0px solid #fff',
  boxShadow: 24,
  borderRadius: '20px',
  // p: 2,
  zIndex: '100000',
  outline: 'none',
};

export const DeatilPageModal = () => {
  const props = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeDeatilModal());
    props?.action === CONFIRM_USER_EXISTS && props?.data();
  };
  const handleMakeOffer = async () => {
    const response = await dispatch(postFormData(MAKE_OFFER_URL, props.data));
    if (
      response?.data?.status_code === 200 ||
      response?.data?.status_code === 201 ||
      response?.status === 200
    ) {
      handleClose();
    }
    if (response?.data?.status_code === 400) {
      const filterResponse = {
        // title,
        message:
          response?.data?.errors?.non_field_errors[0] ||
          response?.data?.message ||
          'Something went wrong',
        success: false,
      };
      dispatch(openDetailModal(filterResponse));
    }
    // responseHandler('Offer Submitted!', response);
    await dispatch(pageRefresh());
  };
  const handleBuyNow = async () => {
    const response = await dispatch(postFormData(BUY_NOW_URL, props.data));
    if (
      response?.data?.status_code === 200 ||
      response?.data?.status_code === 201 ||
      response?.status === 200
    ) {
      handleClose();
    }
    if (response?.data?.status_code === 400) {
      const filterResponse = {
        // title,
        message:
          response?.data?.errors?.non_field_errors[0] ||
          response?.data?.message ||
          'Something went wrong',
        success: false,
      };
      dispatch(openDetailModal(filterResponse));
    }
    await dispatch(pageRefresh());
  };
  const handleLogin = () => {
    dispatch({ type: CLOSE_MODAL });
    dispatch(handleLoginModal(true));
  };
  const handleBuyNowBeforeLogin = () => {
    handleClose();
    props.data();
  };
  const handleVerifyEmail = () => {
    dispatch({ type: VALIDATE_USER_EMAIL, payload: true });
    handleClose();
  };
  return (
    <div>
      <Modal
        open={props?.showDetailModal ? props?.showDetailModal : false}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={style}
          className={props.success === 'pending' ? 'detail_modal_container' : 'modal_conatiner'}
        >
          {props.success === 'pending' ? null : (
            <div className={props.success ? 'status_success' : 'status_failure'}>
              <img
                className='popup-image'
                src={props.success ? icons?.SuccessIcon : icons?.Danger}
                onClick={handleClose}
              />
            </div>
          )}
          <div className={props?.success === 'pending' ? 'modal_sucess_pending' : null}>
            <strong
              className={props.action === CONFIRM_USER_EXISTS ? 'modal_use_details' : 'modal_title'}
            >
              {props?.title}
            </strong>
          </div>
          <Typography id='modal-modal-description' sx={{ m: 2 }} className='modal_message'>
            {props?.message}
          </Typography>
          <div
            className={
              props?.success === 'pending'
                ? 'deatil_modal_buttons'
                : props?.success
                ? 'deatil_modal_buttons_sucess'
                : 'deatil_modal_buttons_sucess'
            }
          >
            {props.showButtons && (
              <>
                <Button
                  className='confirm'
                  onClick={
                    props.action === MAKE_AN_OFFER
                      ? handleMakeOffer
                      : props.action === BUY_NOW
                      ? handleBuyNow
                      : props.title === APPRAISAL
                      ? handleLogin
                      : props.action === BUY_NOW_BEFORE_LOGIN
                      ? handleBuyNowBeforeLogin
                      : props.action === CONFIRM_USER_EXISTS
                      ? handleVerifyEmail
                      : null
                  }
                >
                  Confirm
                </Button>
                <Button className='cancel' onClick={handleClose}>
                  {' '}
                  Cancel{' '}
                </Button>
              </>
            )}
            {!props.showButtons && (
              <Button className='close' onClick={handleClose}>
                {props.action === 'OK' ? 'OK' : 'X Close'}
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};
