import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Popover,
  Skeleton,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  BuyNowDrawer,
  LikeSlider,
  BidTable,
  BarChart,
  ScrollDialog,
  MakeOfferDrawer,
} from '../../components';
import { useLocation, useParams } from 'react-router-dom';
// import { VerticalTopSlider } from '../../components/Slider/VerticalTopSlider';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  bidRevceivedData,
  fetchGraphData,
  getStepsDetail,
  youMayAlsoLikeData,
} from '../../Redux/Actions/ProductViewActions';
import {
  BID_RECEIVED_URL,
  // GET_BID_DATA_BEFORE_LOGIN,
  GET_GRAPH_DATA,
  // GET_GRAPH_DATA_BEFORE_LOGIN,
  GET_USER_URL,
  PRODUCT_INVENTORY_DETAIL_URL,
  STEPS_DETAIL_URL,
  YOU_MA_ALSO_LIKE_URL,
} from '../../base/constants/apiEndPoints';
import { getUserData, inventoryFilterDetail } from '../../Redux/Actions';
import { getWatchType } from '../../Redux/Actions';
import { RESET_STATE, STORE_LOCATION } from '../../Redux/Types';
import {
  CCC_ACCEPT,
  CONDITION_AVERAGE,
  CONDITION_EXCELLENT,
  CONDITION_GOOD,
  CONDITION_POOR,
  ICO_ACCEPT,
} from '../../base/constants/AppConstant';
import { icons } from '../../base/constants/ImageConstants';
import { formatDate, isAuthenticated } from '../../base/utils/utils';
import './ProductDetail.css';
import { SyncSlider } from '../../components/Slider/SyncSlider';

export const ProductDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const [isRefresh, setIsRefresh] = useState(false);
  const [stepValue, setStepValue] = useState();
  const [expanded, setExpanded] = React.useState('');
  const { stepsData, likeData, graphData } = useSelector((state) => state?.productViewReducer);
  const productData = useSelector((state) => state?.inventoryReducer?.productDetail?.data);
  const forRefresh = useSelector((state) => state.appReducer.refresh);
  const userData = useSelector((state) => state?.userReducer?.userData);
  const bidsData = useSelector((state) => state?.productViewReducer?.bidsData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  if (location.pathname) {
    dispatch({ type: STORE_LOCATION, payload: location.pathname });
  }
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  if (productData) {
    dispatch(getWatchType(productData?.status_type));
  }
  const getBidsData = () => {
    dispatch(bidRevceivedData(`${BID_RECEIVED_URL}${id}/`));
  };
  const getUserType = () => {
    dispatch(getUserData(GET_USER_URL));
  };
  const getLikeData = () => {
    dispatch(youMayAlsoLikeData(YOU_MA_ALSO_LIKE_URL, id));
  };
  const getStepsDetails = () => {
    dispatch(getStepsDetail(STEPS_DETAIL_URL, id));
  };
  const getGraphData = () => {
    dispatch(fetchGraphData(`${GET_GRAPH_DATA}${id}/`));
  };
  useEffect(() => {
    dispatch(inventoryFilterDetail(`${PRODUCT_INVENTORY_DETAIL_URL}${id}`));
    getBidsData();
    getGraphData();
    getLikeData();
    isAuthenticated() && getUserType();
    getStepsDetails();
    return () => {
      dispatch({ type: RESET_STATE });
      setStepValue();
    };
  }, [dispatch, isRefresh, forRefresh]);

  const handleRefresh = () => {
    setIsRefresh(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOfferChange = async (value) => {
    setStepValue(value);
    // if (isAuthenticated()) {
    //   await dispatch(
    //     getWinRate(GET_POPUP_WIN_RATE, id, {
    //       offer_price: value,
    //       email: userData?.email,
    //     }),
    //   );
    // }
    // if (!isAuthenticated()) {
    //   await dispatch(
    //     getWinRate(GET_POPUP_WIN_RATE, id, {
    //       offer_price: value,
    //     }),
    //   );
    // }
  };
  const open = Boolean(anchorEl);
  const hover = open ? 'simple-popover' : undefined;
  return (
    <div className='container mt-4'>
      <div className='row'>
        <div className='col-md-6 col-12 marg_top_20_show_mob '>
          {/* <VerticalTopSlider productData={productData} /> */}
          <SyncSlider productData={productData} />
        </div>
        <div className='col-md-6 moser_head'>
          {productData ? (
            <h3>
              {productData?.year} | {productData?.brand} | {productData?.model}
            </h3>
          ) : (
            <Skeleton width={350} height={50} />
          )}
          <div className='market_value'>
            <h5>POST MARKET VALUE</h5>
            <ScrollDialog />
            {/* <Typography aria-describedby={id} variant='contained'>
              What is this?
            </Typography> */}
          </div>
          <div
            className={
              bidsData?.extra?.is_increase || bidsData?.length === 0
                ? 'market_price'
                : 'market_price_drop'
            }
          >
            <h3>
              ${0 || Math.round(bidsData?.extra?.post_market_value || 0)?.toLocaleString('en-US')}{' '}
              <span> {bidsData?.extra?.index_perc || 0} % </span>{' '}
            </h3>
            <p>
              {new Date().toLocaleString('en-US', {
                timeZone: 'America/Los_Angeles',
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
              })}{' '}
              <p className='circle'></p>
            </p>
          </div>
          <div className='market_ofer'>
            <p>{bidsData?.data?.length || bidsData?.extra?.number_of_offer} Offers</p>
            {bidsData?.extra?.last_datetime && (
              <p>LAST BID ON {formatDate(bidsData?.extra?.last_datetime)}</p>
            )}
          </div>
          {(bidsData?.data?.length === 0 ||
            (!isAuthenticated() && productData?.status_type === ICO_ACCEPT)) && (
            <Box className='login_box'>
              <div className='login_container'>
                <Typography className='modal_text'>
                  Please make an offer to see all other bids.
                </Typography>
                {/* <button className='login_btn' onClick={() => navigate(PATH.LOGIN_PAGE)}>
                  Login
                </button> */}
              </div>
            </Box>
          )}
          <BidTable bidsData={bidsData} watchType={productData?.status_type} />
          <div className='data_real'>DATA IS DISPLAYED IN REAL TIME</div>
          {graphData?.data &&
            (Object.entries(graphData?.data)?.length === 0 ||
              (!isAuthenticated() && productData?.status_type === ICO_ACCEPT)) && (
              <Box className='graph_box'>
                <div className='graph_container'>
                  <Typography className='graph_modal_text'>
                    Please make an offer to see all other bids.
                  </Typography>
                </div>
              </Box>
            )}
          <BarChart
            bidsData={bidsData}
            graphData={graphData}
            watchType={productData?.status_type}
          />
          <div className='my_bid_lower'>
            <Typography aria-describedby={id} variant='contained' onClick={handleClick}>
              My bid is lower than the highest bid, how can i still win?
            </Typography>
          </div>

          <Popover
            id={hover}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
          </Popover>
          {productData?.status_type === CCC_ACCEPT && (
            <div className='row radio_button'>
              {stepsData?.map((stepData, index) => (
                <div key={index} className='col-md-4 col-sm-4 col-4 text-center'>
                  <label>
                    <input
                      type='radio'
                      name='months'
                      id={index}
                      ue
                      value={stepData}
                      onClick={(e) => handleOfferChange(e.target.value)}
                    />
                    <span>${Math.round(stepData).toLocaleString('en-US')}</span>
                  </label>
                </div>
              ))}
            </div>
          )}
          {productData?.status_type === ICO_ACCEPT && (
            <MakeOfferDrawer
              watch_id={productData?.id}
              watch_type={productData?.status_type}
              onRefresh={handleRefresh}
              userData={userData}
              stepValue={stepValue}
            />
          )}
          {productData?.status_type === CCC_ACCEPT && (
            <BuyNowDrawer
              ask_price={productData?.ask_price}
              watch_id={productData?.id}
              watch_type={productData?.status_type}
              onRefresh={handleRefresh}
              userData={userData}
              stepValue={stepValue}
            />
          )}
          <h3 id='about_watch'>ABOUT THIS WATCH</h3>

          <div className='detal_accordion'>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>CONDITION</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {productData?.condition === 'Excellent' ? (
                    CONDITION_EXCELLENT
                  ) : productData?.condition === 'Good' ? (
                    <>
                      <p>{CONDITION_GOOD.p1}</p>
                      <br />
                      <p>{CONDITION_GOOD.p2}</p>
                    </>
                  ) : productData?.condition === 'Average' ? (
                    <>
                      <p>{CONDITION_AVERAGE.p1}</p>
                      <br />
                      <p>{CONDITION_AVERAGE.p2}</p>
                    </>
                  ) : productData?.condition === 'Poor' ? (
                    CONDITION_POOR
                  ) : (
                    'N/A'
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>THE DETAILS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className='row the_detail'>
                  <div className='col-lg-3 col-sm-6 col-6'>
                    <Typography>Model </Typography>
                    <p>{productData?.model || 'N/A'}</p>
                  </div>
                  <div className='col-lg-2 col-sm-6 col-6'>
                    <Typography>Year </Typography>
                    <p>{productData?.year || 'N/A'}</p>
                  </div>
                  <div className='col-lg-3 col-sm-6 col-6'>
                    <Typography>Case Size </Typography>
                    <p>{productData?.case_size || 'N/A'}</p>
                  </div>
                  <div className='col-lg-4 col-sm-6 col-6'>
                    <Typography>Case Material </Typography>
                    <p>{productData?.case_material || 'N/A'}</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className='payment_icons'>
            <img src={icons.Pyament1} alt='' />
            <div>
              <p className='text1'>Authenticity Guarantee</p>
              <p className='text2'>Trusted check out with mult-stage encryption</p>
            </div>
          </div>
          <div className='payment_icons'>
            <img src={icons.Pyament2} alt='' />
            <div>
              <p className='text1'>Insured shipping</p>
              <p className='text2'>Trusted check out with mult-stage encryption</p>
            </div>
          </div>
          <div className='payment_icons'>
            <img src={icons.Pyament3} alt='' />
            <div>
              <p className='text1'>Money Back Guarantee</p>
              <p className='text2'>Trusted check out with mult-stage encryption</p>
            </div>
          </div>
        </div>
      </div>
      <div className='border-bottom-div1'></div>
      <section>
        <div className='container p-0 mob_pad_none '>
          <LikeSlider data={likeData} />
        </div>
      </section>
    </div>
  );
};
