import React from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TopSlider.css';
import { icons } from '../../base/constants/ImageConstants';

export const TopSlider = () => {
  let navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          className: 'center',
          centerMode: true,
          centerPadding: '30px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.22,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          className: 'center',
          centerMode: true,
          centerPadding: '40px',
        },
      },
    ],
  };
  return (
    <div className='slider-container'>
      <Slider {...settings}>
        <div className='card' onClick={() => navigate('/productview')}>
          <div className='card_img'>
            <img src={icons.Watch1} className='watch_img' alt='Watch' />
            <img src={icons.Bookmark} className='bookmark_img' alt='icons.Bookmark' />
          </div>
          <a href='#'>
            <div className='card_body text-left'>
              <h5>H. Moser & Cie.</h5>

              <h6>Pioneer Tourbillon</h6>

              <p>Model No.3804-1205</p>

              <span>$64,133</span>
            </div>
          </a>
        </div>

        <div className='card' onClick={() => navigate('/productview')}>
          <div className='card_img'>
            <img src={icons.Watch2} className='watch_img' alt='Watch' />
            <img src={icons.Bookmark} className='bookmark_img' alt='icons.Bookmark' />
          </div>
          <a href='#'>
            <div className='card_body text-left'>
              <h5>H. Moser & Cie.</h5>

              <h6>Pioneer Tourbillon</h6>

              <p>Model No.3804-1205</p>

              <span>$64,133</span>
            </div>
          </a>
        </div>

        <div className='card' onClick={() => navigate('/productview')}>
          <div className='card_img'>
            <img src={icons.Watch3} className='watch_img' alt='Watch' />
            <img src={icons.Bookmark} className='bookmark_img' alt='icons.Bookmark' />
          </div>
          <a href='#'>
            <div className='card_body text-left'>
              <h5>H. Moser & Cie.</h5>

              <h6>Pioneer Tourbillon</h6>

              <p>Model No.3804-1205</p>

              <span>$64,133</span>
            </div>
          </a>
        </div>

        <div className='card' onClick={() => navigate('/productview')}>
          <div className='card_img'>
            <img src={icons.Watch4} className='watch_img' alt='Watch' />
            <img src={icons.Bookmark} className='bookmark_img' alt='icons.Bookmark' />
          </div>
          <a href='#'>
            <div className='card_body text-left'>
              <h5>H. Moser & Cie.</h5>

              <h6>Pioneer Tourbillon</h6>

              <p>Model No.3804-1205</p>

              <span>$64,133</span>
            </div>
          </a>
        </div>

        <div className='card' onClick={() => navigate('/productview')}>
          <div className='card_img'>
            <img src={icons.Watch5} className='watch_img' alt='Watch' />
            <img src={icons.Bookmark} className='bookmark_img' alt='icons.Bookmark' />
          </div>
          <a href='#'>
            <div className='card_body text-left'>
              <h5>H. Moser & Cie.</h5>

              <h6>Pioneer Tourbillon</h6>

              <p>Model No.3804-1205</p>

              <span>$64,133</span>
            </div>
          </a>
        </div>

        <div className='card' onClick={() => navigate('/productview')}>
          <div className='card_img'>
            <img src={icons.Watch6} className='watch_img' alt='Watch' />
            <img src={icons.Bookmark} className='bookmark_img' alt='icons.Bookmark' />
          </div>
          <a href='#'>
            <div className='card_body text-left'>
              <h5>H. Moser & Cie.</h5>

              <h6>Pioneer Tourbillon</h6>

              <p>Model No.3804-1205</p>

              <span>$64,133</span>
            </div>
          </a>
        </div>
      </Slider>
    </div>
  );
};
