import React, { forwardRef } from 'react';
import PlyrJS from 'plyr';
import { useAptor } from './useAptor';
import './plyr.css';

const instantiate = (_, params) => {
  const plyr = new PlyrJS('.plyr-react', params?.options || {});
  if (params?.source) plyr.source = params?.source;
  return plyr;
};

const destroy = (plyr) => {
  if (plyr) plyr.destroy();
};

const noop = () => {};

const getAPI = (plyr) => {
  if (!plyr) {
    return () =>
      new Proxy(
        { plyr: { source: null } },
        {
          get: (target, prop) => {
            if (prop === 'plyr') {
              return target[prop];
            }
            return noop;
          },
        },
      );
  }

  return () => ({
    /**
     * Plyr instance with all of its functionality
     */
    plyr,
  });
};

export function usePlyr(ref, params, deps = null) {
  return useAptor(
    ref,
    {
      instantiate,
      getAPI,
      destroy,
      params,
    },
    deps || [params.options, params.source],
  );
}

const Plyr = forwardRef((props, ref) => {
  const { source, options, className, ...rest } = props;
  const raptorRef = usePlyr(ref, {
    source,
    options,
  });

  return (
    <video
      preload='none'
      ref={raptorRef}
      className={`plyr-react plyr ${className || ''}`}
      {...rest}
    />
  );
});

Plyr.displayName = 'Plyr';

export default Plyr;
