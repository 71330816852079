import React from 'react';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  BarElement,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { isAuthenticated } from '../../base/utils/utils';
import { CCC_ACCEPT } from '../../base/constants/AppConstant';

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Filler,
);
export const BarChart = ({ bidsData, graphData, watchType }) => {
  const getRandomColor = () => {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  var count = graphData && graphData?.extra?.map((i) => i.count);
  var options = {
    responsive: true,
    maintainAspectRatio: false,
    maxBarThickness: 28,
    plugins: {
      tooltip: {
        bodyFont: { family: 'Gotham regular', size: 13, weight: 500 },
        displayColors: false,
        callbacks: {
          mode: 'label',
          title: () => null,
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += `${context?.parsed?.y}%`;
            }
            return label;
          },
          afterLabel: (context) => {
            return `${count[context.dataIndex] > 1 ? 'Offers' : 'Offer'}: ${
              count && count[context.dataIndex]
            }`;
          },
        },
      },
      legend: {
        position: false,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: (value) => value + '%',
          display: true,
          stepSize: 20,
          crossAlign: 'far',
        },
        position: 'right',
        grid: { tickColor: 'white' },
        beginAtZero: true,
        max: 100,
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };
  const dummyData = [85000, 87000, 88000, 89000, 90000, 91000, 92000, 93000, 94000];
  const data = {
    labels: graphData
      ? graphData?.extra &&
        // eslint-disable-next-line no-unused-vars
        Object.entries(graphData?.extra)?.map(([key, value]) => [
          `$${value.x?.toLocaleString('en-US')}`,
        ])
      : dummyData,
    datasets: [
      {
        label: 'Win Rate',
        data:
          graphData?.extra &&
          // eslint-disable-next-line no-unused-vars
          Object.entries(graphData?.extra)?.map(([key, value]) => value.y),
        tension: 0.0,
        borderWidth: 2.5,
        borderRadius: 5,
        backgroundColor: 'rgba(55, 193, 188, 0.74)',
        pointBackgroundColor: getRandomColor,
        pointRadius: 5,
        fill: 'start',
        borderColor: 'rgba(55, 193, 188, 0.74)',
      },
    ],
  };
  return (
    <div
      style={{ height: '200px' }}
      className={
        graphData?.data && watchType === CCC_ACCEPT && Object.entries(graphData?.data)?.length > 0
          ? null
          : !isAuthenticated() || bidsData?.data?.length === 0
          ? 'graph_blur'
          : null
      }
    >
      <Bar id='canvas' options={options} data={data} />
    </div>
  );
};
