import {
  InventoryDetail,
  Landing,
  About,
  Contact,
  Login,
  Register,
  Favorites,
  // Forgot_Password,
  ConfirmPassword,
  MyAccount,
  MyWatch,
  Appraisal,
  InventoryBrand,
  ProductDetail,
  PageNotFound,
} from '../pages';
import * as Path from '../base/constants/RoutePath';
export const publicRoutes = [
  { path: Path.DASHBOARD_PAGE, Component: Landing },
  { path: Path.PRODUCTVIEW_PAGE, Component: ProductDetail },
  { path: Path.INVENTORY_PAGE, Component: InventoryDetail },
  { path: Path.INVENTORY_BRAND, Component: InventoryBrand },
  { path: Path.ABOUT_PAGE, Component: About },
  { path: Path.CONTACT_PAGE, Component: Contact },
  { path: Path.LOGIN_PAGE, Component: Login },
  { path: Path.REGISTER_PAGE, Component: Register },
  // { path: Path.FORGOT_PASSWORD, Component: Forgot_Password },
  { path: Path.CONFIRM_PASSWORD, Component: ConfirmPassword },
  { path: Path.PageNotFound, Component: PageNotFound },
];
export const privateRoutes = [
  { path: Path.MY_ACCOUNT, Component: MyAccount },
  { path: Path.MY_WATCH_PAGE, Component: MyWatch },
  { path: Path.APPRAISAL_PAGE, Component: Appraisal },
  { path: Path.FAVORITE, Component: Favorites },
];
