import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { useState } from 'react';
import { icons } from '../../base/constants/ImageConstants';
import { isNumber } from '../../base/utils/stringUtils';
import './Calculator.css';

export const Calculator = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [userValues, setUserValues] = useState({
    amount: '',
    downPayment: '',
    tradeInValue: '',
    interest: '',
    months: 12,
    salesTex: '',
    APR: '',
  });

  const handleInputChange = (event) => {
    if (isNumber(event.target.value)) {
      setUserValues({ ...userValues, [event.target.name]: event.target.value });
    } else {
      setUserValues({ ...userValues, [event.target.name]: '' });
    }
  };

  const salesTex = (userValues.amount * userValues.salesTex) / 100;
  const totalLoanAmount =
    userValues.amount - userValues.downPayment - userValues.tradeInValue + salesTex || 0;
  const months = parseInt(userValues.months);
  const annInterest = parseFloat(userValues.APR);
  const monInt = annInterest / 1200;
  let calculatedEMI = (
    (monInt + monInt / (Math.pow(1 + monInt, months) - 1)) *
    totalLoanAmount
  ).toFixed(2);
  const InterestAmount = Math.round(calculatedEMI * months - totalLoanAmount) || 0;
  calculatedEMI =
    Math.round(
      ((monInt + monInt / (Math.pow(1 + monInt, months) - 1)) * totalLoanAmount).toFixed(2),
    ) || Math.round(totalLoanAmount / months);
  const totalLoanAndInterestPaid = totalLoanAmount + InterestAmount || 0;

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className='finance_calculator'>
      <h2>Finance Calculator</h2>
      <h3>${calculatedEMI}/mo.</h3>
      <p className='estimate'>
        Estimated payment for {userValues.months} months at {userValues.APR || 0}% APR*
      </p>
      <Accordion expanded={expanded} onChange={handleChange(!expanded)}>
        {!expanded ? (
          <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
            <Typography>
              <span className='Show_breakdown' style={{ color: '#f29342' }}>
                Show breakdown
                <img src={icons.DownArrow} alt='' />
              </span>
            </Typography>
          </AccordionSummary>
        ) : null}
        <AccordionDetails>
          <div className='show_breakdown'>
            <div className='breakdown_content'>
              <div className='break_first_content'>
                <div className='row'>
                  <div className='col-md-7 col-7'>
                    <p> Watch price </p>
                  </div>
                  <div className='col-md-5 col-5'>
                    <span> ${userValues.amount} </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-7 col-7'>
                    <p> Down payment </p>
                  </div>
                  <div className='col-md-5 col-5'>
                    <span> -${userValues.downPayment} </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-7 col-7'>
                    <p> Trade-in value </p>
                  </div>
                  <div className='col-md-5 col-5'>
                    <span> -${userValues.tradeInValue} </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-7 col-7'>
                    <p> Estimated sales tax (0.00%) </p>
                  </div>

                  <div className='col-md-5 col-5'>
                    <span> +${salesTex} </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-7 col-7'>
                    <p> Other fees* </p>
                  </div>
                  <div className='col-md-5 col-5'>
                    <span> not included </span>
                  </div>
                </div>
              </div>
              <div className='border-bottom-div1 pt-0 mb-3'></div>
              <div className='break_other_content'>
                <div className='row'>
                  <div className='col-md-7 col-7'>
                    <p> Total loan amount </p>
                  </div>
                  <div className='col-md-5 col-5 text-right'>
                    <span> ${totalLoanAmount} </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-7 col-7'>
                    <p>
                      {' '}
                      Total interest paid <span> (over life of loan) </span>
                    </p>
                  </div>
                  <div className='col-md-5 col-5 text-right'>
                    <span> +${InterestAmount} </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-7 col-7'>
                    <p> Total loan &amp; interest paid </p>
                  </div>
                  <div className='col-md-5 col-5 text-right'>
                    <span> ${totalLoanAndInterestPaid} </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-7 col-7'>
                    <p> Monthly payment </p>
                  </div>
                  <div className='col-md-5 col-5 text-right'>
                    <span> ${calculatedEMI} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {expanded ? (
        <Accordion expanded={expanded} onChange={handleChange(!expanded)}>
          <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
            <Typography>
              <span className='Show_breakdown hide_breakdown' style={{ color: '#f29342' }}>
                Hide breakdown
                <img src={icons.DownArrow} alt='' />
              </span>
            </Typography>
          </AccordionSummary>
        </Accordion>
      ) : null}
      <form>
        <div className='price_vicle2'>
          <div className='price_box_view'>
            <h1>Watch price</h1>
            <input
              type='text'
              name='amount'
              maxLength='6'
              placeholder='$2,000'
              value={userValues.amount}
              onChange={handleInputChange}
            />
          </div>
          <div className='price_box_view mr-0'>
            <h1>Down payment (Optional)</h1>
            <input
              type='text'
              placeholder='$2,000'
              maxLength='6'
              name='downPayment'
              value={userValues.downPayment}
              onChange={handleInputChange}
            />
          </div>
          <div className='price_box_view'>
            <h1> Trade-in value (optional) </h1>
            <input
              type='text'
              placeholder='$2,000'
              maxLength='6'
              name='tradeInValue'
              value={userValues.tradeInValue}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <h4>Length of loan (in months)</h4>
        <div className='radio_buttons'>
          <label>
            <input
              type='radio'
              name='months'
              id='number1'
              value='12'
              defaultChecked={true}
              onChange={handleInputChange}
            />
            <span>12</span>
          </label>
          <label>
            <input
              type='radio'
              id='number2'
              name='months'
              value='24'
              onChange={handleInputChange}
            />
            <span>24</span>
          </label>
          <label>
            <input
              type='radio'
              id='number3'
              name='months'
              value='36'
              onChange={handleInputChange}
            />
            <span>36</span>
          </label>
          <label>
            <input
              type='radio'
              id='number4'
              name='months'
              value='48'
              onChange={handleInputChange}
            />
            <span>48</span>
          </label>
          <label>
            <input
              type='radio'
              id='number5'
              name='months'
              value='60'
              onChange={handleInputChange}
            />
            <span>60</span>
          </label>
          <label>
            <input
              type='radio'
              id='number6'
              name='months'
              value='72'
              onChange={handleInputChange}
            />
            <span>72</span>
          </label>
          <label>
            <input
              type='radio'
              id='number7'
              name='months'
              value='84'
              onChange={handleInputChange}
            />
            <span>84</span>
          </label>
        </div>
        <div className='price_vicle2'>
          <div className='price_box_view'>
            <h1>Annual percentage rate (APR)</h1>
            <input
              type='text'
              name='APR'
              value={userValues.APR}
              placeholder='0.00 %'
              onChange={handleInputChange}
            />
          </div>
          <div className='price_box_view mr-0'>
            <h1>Sales tax</h1>
            <input
              type='text'
              name='salesTex'
              value={userValues.salesTex}
              placeholder='0.00 %'
              onChange={handleInputChange}
            />
          </div>
          <p>
            *Title and other fees and incentives are not included in this calculation, which is an
            estimate only. Monthly payment estimates are for informational purpose and do not
            represent a financing offer from the seller of this watch. Other taxes may apply.
          </p>
        </div>
      </form>
    </div>
  );
};
