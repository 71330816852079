import getAxios from '../../base/axos.config';
import * as TYPE from '../Types';
const api = getAxios();
export const getBrandData = (url) => async (dispatch) => {
  await api
    .get(url)
    .then(async (response) => {
      await dispatch({ type: TYPE.FETCH_BRANDS_SUCCESS, payload: response?.data?.results });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.FETCH_BRANDS_ERROR, payload: error?.statusText });
    });
};
