import * as TYPE from '../Types';

const initialState = {
  featuredData: [],
  justDroppedData: [],
  transactionData: [],
  bestDealData: [],
  error: '',
};

export const landingReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.GET_FEATURED_SUCCESS:
      return {
        ...state,
        featuredData: action.payload,
      };
    case TYPE.GET_FEATURED_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TYPE.GET_JUST_DROPPED_SUCESS:
      return {
        ...state,
        justDroppedData: action.payload,
      };
    case TYPE.GET_JUST_DROPPED__ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TYPE.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionData: action.payload,
      };
    case TYPE.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TYPE.GET_BEST_DEAL_SUCESS:
      return {
        ...state,
        bestDealData: action.payload,
      };
    case TYPE.GET_BEST_DEAL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
