import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDeviceDetect } from '../../hooks';
export const ImageCropper = (props) => {
  const { imageToCrop, onImageCropped, onCropBlob, page, imageName } = props;
  const { isMobile } = useDeviceDetect();
  const [cropConfig, setCropConfig] = useState(
    // default crop config
    page !== 'my-account'
      ? {
          unit: isMobile ? '%' : 'px',
          width: isMobile ? 90 : 500,
          height: isMobile ? 90 : 500,
          x: isMobile ? 10 : 50,
          y: isMobile ? 10 : 50,
          aspect: 1,
        }
      : {
          unit: '%',
          width: 30,
          // x: 0,
          // y: 0,
          aspect: 16 / 9,
        },
  );

  const [imageRef, setImageRef] = useState();

  async function cropImage(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(
        imageRef,
        crop,
        imageName, // destination filename
      );

      // calling the props function to expose
      // croppedImage to the parent component
      onImageCropped(croppedImage.croppedImageUrl);
      onCropBlob(croppedImage.blob);
    }
  }

  function getCroppedImage(sourceImage, cropConfig, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement('canvas');
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height,
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        blob.name = fileName;
        // creating a Object URL representing the Blob object given
        const croppedImageUrl = window.URL.createObjectURL(blob);

        resolve({ croppedImageUrl, blob });
      }, 'image/png');
    });
  }

  return (
    <ReactCrop
      src={imageToCrop}
      crop={cropConfig}
      keepSelection
      ruleOfThirds
      minHeight={page !== 'my-account' ? (isMobile ? 300 : 500) : ''}
      minWidth={page !== 'my-account' ? (isMobile ? 300 : 500) : ''}
      onImageLoaded={(imageRef) => setImageRef(imageRef)}
      onComplete={(cropConfig) => cropImage(cropConfig)}
      onChange={(cropConfig) => setCropConfig(cropConfig)}
      crossorigin='anonymous' // to avoid CORS-related problems
    />
  );
};

ImageCropper.defaultProps = {
  onImageCropped: () => {},
};
