import Popularity1 from '../../assets/images/popularity/1.svg';
import Popularity2 from '../../assets/images/popularity/2.svg';
import Popularity3 from '../../assets/images/popularity/3.svg';
import Popularity4 from '../../assets/images/popularity/4.svg';
import Brand1 from '../../assets/images/brands/1.png';
import Brand2 from '../../assets/images/brands/2.png';
import MobBrand2 from '../../assets/images/brands/mob2.png';
import Brand3 from '../../assets/images/brands/3.png';
import Brand4 from '../../assets/images/brands/4.svg';
import Brand5 from '../../assets/images/brands/5.svg';
import Brand6 from '../../assets/images/brands/6.png';
import Brand7 from '../../assets/images/brands/7.png';
import Brand8 from '../../assets/images/brands/8.png';
import Brand9 from '../../assets/images/brands/9.png';
import Brand10 from '../../assets/images/brands/10.svg';
import ShareIcon from '../../assets/images/Share.svg';
import ChatIcon from '../../assets/images/Chat.svg';
import QuoteIcon from '../../assets/images/Quote.svg';
import ArrowIcon from '../../assets/images/Arrow.svg';
import SliderIcon from '../../assets/images/slider.svg';
import Drop1 from '../../assets/images/drop/1.png';
import Drop2 from '../../assets/images/drop/2.png';
import Drop3 from '../../assets/images/drop/3.png';
import Drop4 from '../../assets/images/drop/4.png';
import Drop5 from '../../assets/images/drop/5.png';
import Drop6 from '../../assets/images/drop/6.png';
import Drop7 from '../../assets/images/drop/7.png';
import Drop8 from '../../assets/images/drop/8.png';
import Drop9 from '../../assets/images/drop/9.png';
import Drop10 from '../../assets/images/drop/10.png';
import Drop11 from '../../assets/images/drop/11.png';
import Drop12 from '../../assets/images/drop/12.png';
import Drop13 from '../../assets/images/drop/13.png';
import Drop14 from '../../assets/images/drop/14.png';
import Drop15 from '../../assets/images/drop/15.png';
import Drop16 from '../../assets/images/drop/16.png';
import Drop17 from '../../assets/images/drop/17.png';
import Drop18 from '../../assets/images/drop/18.png';
import Bookmark from '../../assets/images/icons/bookmark.png';
import Filled_Bookmark from '../../assets/images/icons/filled_bookmark.png';
import Video_img from '../../assets/images/banner/video.png';
import Mob_video_img from '../../assets/images/mob_video.png';
import Logo_mark from '../../assets/images/logo_mark.png';
import Watch_faq from '../../assets/images/watch.png';
import RightArrow from '../../assets/images/icons/rightArrow.svg';
import PriceIcon from '../../assets/images/icons/price.svg';
import ViewIcon from '../../assets/images/icons/view.png';
import PreIcon from '../../assets/images/icons/pre.png';
import MonyIcon1 from '../../assets/images/icons/1.svg';
import MonyIcon2 from '../../assets/images/icons/2.svg';
import MonyIcon3 from '../../assets/images/icons/3.svg';
import MonyIcon4 from '../../assets/images/icons/4.svg';
import DownArrow from '../../assets/images/icons/downArrow.png';
import Dangor from '../../assets/images/icons/danger.png';
import Dangor1 from '../../assets/images/icons/danger1.png';
import closeIcon from '../../assets/images/icons/close.png';
import Close_btn from '../../assets/images/icons/close_btn.png';
import profileIcon from '../../assets/images/profile.png';
import Watch1 from '../../assets/images/slider/1.png';
import Watch2 from '../../assets/images/slider/2.png';
import Watch3 from '../../assets/images/slider/3.png';
import Watch4 from '../../assets/images/slider/4.png';
import Watch5 from '../../assets/images/slider/5.png';
import Watch6 from '../../assets/images/slider/6.png';
import Logo from '../../assets/images/logo.svg';
import Email from '../../assets/images/icons/email.svg';
import Address from '../../assets/images/icons/address.svg';
import Facebook from '../../assets/images/icons/facebook.png';
import Instagram from '../../assets/images/icons/instagram.png';
import Youtube from '../../assets/images/icons/youtube.png';
import Mob_logo from '../../assets/images/logofoot.png';
import White_facebook from '../../assets/images/icons/white_facebook.png';
import White_instagram from '../../assets/images/icons/white_instagram.png';
import User from '../../assets/images/user.png';
import User1 from '../../assets/images/user_1.png';
import Toggle from '../../assets/images/icons/menu.png';
import Toggle1 from '../../assets/images/icons/menu1.svg';
import Search_img from '../../assets/images/icons/search.png';
import FashionImage1 from '../../assets/images/fashion/1.svg';
import FashionImage2 from '../../assets/images/fashion/2.svg';
import FashionImage3 from '../../assets/images/fashion/3.svg';
import FashionImage4 from '../../assets/images/fashion/4.svg';
import FashionImage5 from '../../assets/images/fashion/5.svg';
import FashionImage6 from '../../assets/images/fashion/6.svg';
import ProductWatch from '../../assets/images/slider/product.png';
import ProductWatch2 from '../../assets/images/slider/product2.png';
import ProductWatch3 from '../../assets/images/slider/product3.png';
import ProductWatch4 from '../../assets/images/slider/product4.png';
import ProductWatch5 from '../../assets/images/slider/product5.png';
import ProductWatch6 from '../../assets/images/slider/product6.png';
import FilterIcon from '../../assets/images/icons/filter.png';
import SearchIcon from '../../assets/images/icons/SearchIcon.png';
import New from '../../assets/images/speciality/1.png';
import Media from '../../assets/images/speciality/2.png';
import Finance from '../../assets/images/speciality/3.png';
import Reconditioning from '../../assets/images/speciality/4.png';
import Customization from '../../assets/images/speciality/5.png';
import MailIcon from '../../assets/images/icons/mail_icon.svg';
import LongArrow from '../../assets/images/icons/longArrow.png';
import Drag from '../../assets/images/icons/drag.png';
import Secure from '../../assets/images/icons/secure.svg';
import Usa from '../../assets/images/icons/usa.svg';
import Pass from '../../assets/images/icons/pass.svg';
import Conpass from '../../assets/images/icons/conpass.svg';
import Pencil from '../../assets/images/icons/pencil.png';
import Checkinput from '../../assets/images/icons/checkinput.svg';
import img_watch from '../../assets/images/img_watch.png';
import img_aprove from '../../assets/images/approval.png';
import img_account from '../../assets/images/account.png';
import approvel_img from '../../assets/images/approvalactive.png';
import approvel_image from '../../assets/images/approval-image.png';
import account_act from '../../assets/images/accountactive.png';
import SuccessIcon from '../../assets/images/icons/SuccessIcon.svg';
import successIcon from '../../assets/images/icons/success.png';
import StarIcon from '../../assets/images/icons/star.png';
import StarIcon2 from '../../assets/images/icons/star2.png';
import WrightArrow from '../../assets/images/icons/wrightArrow.svg';
import verify_icon from '../../assets/images/verify.png';
import Curser from '../../assets/images/cursor.svg';
import Transaction from '../../assets/images/transaction.svg';
import Card from '../../assets/images/card.svg';
import Pie from '../../assets/images/pie-chart.svg';
import Msg from '../../assets/images/msg.svg';
import Un from '../../assets/images/un.svg';
import Addgrid from '../../assets/images/add-grid 1.svg';
import Percent from '../../assets/images/percent.svg';
import About1 from '../../assets/images/about/about1.png';
import About2 from '../../assets/images/about/about2.png';
import MobAbout1 from '../../assets/images/about/mobabout1.png';
import example_image_1 from '../../assets/images/example_Image_1.jpeg';
import example_image_2 from '../../assets/images/example_Image_2.jpeg';
import example_image_3 from '../../assets/images/example_Image_3.jpeg';
import Purpose from '../../assets/images/banner/mob_purpose.png';
import Mobile from '../../assets/images/banner/mob1.svg';
import New1 from '../../assets/images/speciality/mob1.png';
import Media_mob from '../../assets/images/speciality/mob2.png';
import Affirm from '../../assets/images/speciality/mob3.png';
import Recondition_mob from '../../assets/images/speciality/mob4.png';
import Custom_mob from '../../assets/images/speciality/mob5.png';
import Mobile2 from '../../assets/images/banner/mob2.png';
import Aboutmob1 from '../../assets/images/about/mob1.png';
import Aboutmob2 from '../../assets/images/about/mob2.png';
import Arrow from '../../assets/images/banner/chevron-right.png';
import Watch from '../../assets/images/banner/watch.png';
import Arrowright from '../../assets/images/banner/arrow-right.png';
import BgWatch from '../../assets/images/banner/watch.png';
import BgWatchMob from '../../assets/images/banner/mobwatch.png';
import Mobile3 from '../../assets/images/banner/mobbg.png';
import VideoImg from '../../assets/images/banner/videoimg.png';
import aboutBanner from '../../assets/images/banner/aboutBanner.jpg';
import MobVideoImg from '../../assets/images/banner/videoimgmob.png';
import ErrorIcon from '../../assets/images/icons/danger121.png';
import Pyament1 from '../../assets/images/icons/11.svg';
import Pyament2 from '../../assets/images/icons/22.svg';
import Pyament3 from '../../assets/images/icons/33.svg';
import Danger from '../../assets/images/icons/danger.svg';
import slideArrow from '../../assets/images/icons/slideArrow.svg';
import slideArrow2 from '../../assets/images/icons/slideArrow2.svg';
import plusIcon from '../../assets/images/icons/plusIcon.png';

export const icons = {
  Danger,
  plusIcon,
  SuccessIcon,
  Drop18,
  slideArrow,
  slideArrow2,
  aboutBanner,
  Pyament1,
  Pyament2,
  Pyament3,
  Pencil,
  Mobile2,
  MobVideoImg,
  Mobile3,
  VideoImg,
  ErrorIcon,
  BgWatchMob,
  Arrow,
  BgWatch,
  About1,
  Dangor1,
  StarIcon2,
  StarIcon,
  About2,
  MobAbout1,
  profileIcon,
  WrightArrow,
  successIcon,
  New,
  Watch,
  Arrowright,
  Checkinput,
  Close_btn,
  Conpass,
  Pass,
  Usa,
  Secure,
  Drag,
  Filled_Bookmark,
  LongArrow,
  Finance,
  Media,
  Reconditioning,
  Customization,
  SearchIcon,
  FilterIcon,
  Popularity1,
  ProductWatch,
  ProductWatch2,
  ProductWatch3,
  ProductWatch4,
  ProductWatch5,
  ProductWatch6,
  FashionImage1,
  FashionImage2,
  FashionImage3,
  FashionImage4,
  FashionImage5,
  FashionImage6,
  User,
  User1,
  Toggle,
  Toggle1,
  Search_img,
  Logo,
  Email,
  Address,
  Facebook,
  Instagram,
  Dangor,
  Youtube,
  Mob_logo,
  White_facebook,
  White_instagram,
  Watch1,
  Watch2,
  Watch3,
  Watch4,
  Watch5,
  Watch6,
  closeIcon,
  RightArrow,
  PriceIcon,
  ViewIcon,
  PreIcon,
  MonyIcon1,
  MonyIcon2,
  MonyIcon3,
  MonyIcon4,
  DownArrow,
  Video_img,
  Mob_video_img,
  Logo_mark,
  Watch_faq,
  Brand1,
  Brand2,
  Brand3,
  Brand4,
  Brand5,
  Brand6,
  Brand7,
  Brand8,
  Brand9,
  Brand10,
  Popularity2,
  Popularity3,
  Popularity4,
  ShareIcon,
  ChatIcon,
  QuoteIcon,
  ArrowIcon,
  SliderIcon,
  Drop1,
  Drop2,
  Drop3,
  Drop4,
  Drop5,
  Drop6,
  Drop7,
  Drop8,
  Drop9,
  Drop10,
  Drop11,
  Drop12,
  Drop13,
  Bookmark,
  Drop14,
  Drop15,
  Drop16,
  Drop17,
  MailIcon,
  img_watch,
  img_aprove,
  img_account,
  approvel_img,
  account_act,
  verify_icon,
  approvel_image,
  Curser,
  Transaction,
  Card,
  Pie,
  Msg,
  Un,
  Addgrid,
  Percent,
  example_image_1,
  example_image_2,
  example_image_3,
  Purpose,
  Mobile,
  Affirm,
  Media_mob,
  New1,
  Recondition_mob,
  Custom_mob,
  MobBrand2,
  Aboutmob1,
  Aboutmob2,
};
