export const BUY_NOW_URL = '/api/v1/product/buyitnow/';
export const MAKE_OFFER_URL = 'api/v1/product/makeanoffer/';
export const LOGIN_URL = '/api/v1/auth/login/';
export const APPRAISAL_URL = '/api/v1/product/user/watch/';
export const REGISTER_URL = '/api/v1/product/watch/';
export const FORGOT_PASSWORD_URL = '/api/v1/auth/request-reset-email/';
export const CONTACT_US_URL = '/api/v1/auth/contactus/';
export const CONFIRM_PASSWORD_URL = '/api/v1/auth/password-reset-complete/';
export const SEND_OTP_URL = '/api/v1/auth/send-otp/';
export const VERIFY_OTP_URL = '/api/v1/auth/verify-otp/';
export const GET_USER_URL = '/api/v1/auth/userdetails/9/';
export const UPDATE_USER_URL = '/api/v1/auth/userdetails/27/';
export const UPDATE_PROFILE_PIC_URL = '/api/v1/auth/userdetails/9/';
export const CHANGE_PASWORD_URL = '/api/v1/auth/change/password/';
export const INVENTORY_LIST_URL = '/api/v1/product/inventory/?';
export const SEND_OTP_Make_AN_OFFER_URL = '/api/v1/product/send-otp/makeanoffer/';
export const VERIFY_OTP_MAKE_AN_OFFER_URL = '/api/v1/product/verify-otp/makeanoffer/';
export const GET_BRANDS_URL = '/api/v1/product/brand/focus/';
export const ADD_WATCH_TO_FAVOURITE = '/api/v1/product/userfavourite/';
export const GET_SAVED_WATCHES = '/api/v1/product/userfavourite/';
export const MY_WATCH_PAGE_URL = '/api/v1/product/mywatch/';
export const BID_RECEIVED_URL = '/api/v1/product/win-rate/';
export const GET_BID_DATA_BEFORE_LOGIN = '/api/v1/product/win-rate/';
export const SEND_OTP_BUY_NOW_URL = '/api/v1/product/send-otp/buyitnow/';
export const VERIFY_OTP_BUY_NOW_URL = '/api/v1/product/verify-otp/buyitnow/';
export const REFRESH_TOKEN_URL = '/api/v1/auth/token/refresh/';
export const INVENTORY_FILTER_DATA_URL = '/api/v1/product/inventory/filter/data/';
export const VALIDATE_BIDDER_URL = '/api/v1/product/offer/validation/';
export const GET_TRANSACTIONS_URL = '/api/v1/product/transaction/image/';
export const PRODUCT_INVENTORY_DETAIL_URL = '/api/v1/product/inventory/detail/';
export const ZIPCODE_VERIFY_URL = 'api/v1/auth/zipcode/validate/';
export const GET_GRAPH_DATA = '/api/v1/product/graph/list/';
export const GET_GRAPH_DATA_BEFORE_LOGIN = '/api/v1/product/graph/list/';
export const GET_BEST_DEAL_URL = '/api/v1/product/best/watch/';
export const REPOST_WATCH_URL = '/api/v1/product/repost/';
export const YOU_MA_ALSO_LIKE_URL = '/api/v1/product/you/may/like/watch/list/';
export const STEPS_DETAIL_URL = '/api/v1/product/ccc/offer/steps/detail/';
export const GET_POPUP_WIN_RATE = '/api/v1/product/popup/win/rate/';
export const VALIDATE_USER_EMAIL_URL = '/api/v1/product/check/user/email/?email=';
export const CHECK_PHONE_NUMBER_URL = '/api/v1/auth/phone/number/check/';
