import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './BidTable.css';
import { checkBidderName, formatDate, isAuthenticated } from '../../base/utils/utils';
import { CCC_ACCEPT } from '../../base/constants/AppConstant';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    fontFamily: 'Gotham Bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Gotham Bold',
  },
}));

// eslint-disable-next-line no-unused-vars
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:first-of-type tr': {
    background: '#4165b6',
    marginTop: '5px',
  },
}));

function createData(name, price, winRate, date) {
  return { name, price, winRate, date };
}

const rows = [
  createData('ALBERT T.', '$89,000', '83%', 'JULY 25, 9:23 AM PDT'),
  createData('KEVIN K.', '$91,000', '45%', 'JULY 16, 1:23 AM PDT'),
  createData('JOSHUA H.', '$87,000', '29%', 'JULY 15, 6:23 AM PDT'),
  createData('ROY L.', '$88,000', '19%', 'JULY 16, 9:23 AM PDT'),
  createData('JOHN U.', '$93,000', '98%', 'JULY 14, 9:23 AM PDT'),
  createData('HUBERT T.', '$86,000', '8%', 'JULY 13, 4:23 AM PDT'),
  createData('MATT R.', '$84,000', '5%', 'JULY 13, 9:23 AM PDT'),
  createData('RYAN H.', '$82,000', '3%', 'JULY 11, 9:23 AM PDT'),
  createData('BRIYAN J.', '$84,000', '5%', 'JULY 11, 9:23 AM PDT'),
  createData('JAMES T.', '$81,000', '2%', 'JULY 10, 9:23 AM PDT'),
];

export const BidTable = ({ bidsData, watchType }) => {
  let bidRows = [];

  const isSelf = bidsData?.data?.find((b) => b.is_self === true) || null;
  const otherData = bidsData?.data?.filter((b) => b.is_self === false) || [];
  if (isSelf) {
    bidRows.push(isSelf);
    bidRows = [...bidRows, ...otherData];
  } else {
    bidRows = [...otherData];
  }
  const result =
    bidRows.length > 1 &&
    bidRows
      // ?.slice(1, bidRows?.length)
      ?.reduce((max, curren) =>
        parseInt(max.offer_price) > parseInt(curren.offer_price) ? max : curren,
      );
  const filterData = bidRows.filter(
    (i) => parseInt(i.offer_price) === parseInt(result.offer_price),
  );
  bidRows &&
    filterData &&
    filterData.forEach((i) => {
      bidRows?.forEach((obj) => {
        if (parseInt(obj.offer_price) === parseInt(i.offer_price)) {
          obj.secondMaxPrice = true;
        }
      });
    });

  const isSelfBid = (rows = []) => {
    if (!rows || rows.length === 0) {
      return null;
    }
    if (rows?.is_self && rows?.secondMaxPrice) {
      return { background: '#3BBB7C', color: '#fff', whiteSpace: 'nowrap' };
    }
    if (rows?.is_self && !rows?.secondMaxPrice) {
      return { background: '#4165b6', color: '#fff', whiteSpace: 'nowrap' };
    }
    if (!rows?.is_self && rows?.secondMaxPrice) {
      return { background: '#3BBB7C', color: '#fff', whiteSpace: 'nowrap' };
    }
  };

  return (
    <>
      {bidRows?.length === 0 && (
        <TableContainer
          component={Paper}
          className={
            watchType === CCC_ACCEPT && bidRows?.length > 0
              ? null
              : !isAuthenticated() || bidRows?.length === 0
              ? 'table_blur'
              : null
          }
        >
          <Table sx={{ minWidth: 300, borderRadius: 0 }} aria-label='customized table'>
            <TableHead sx={{ height: 2 }}>
              <TableRow>
                <StyledTableCell>NAME</StyledTableCell>
                <StyledTableCell align='left'>PRICE</StyledTableCell>
                <StyledTableCell align='left'>WIN RATE</StyledTableCell>
                <StyledTableCell align='right'>DATE</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow className='table_blur' key={row.name}>
                  <StyledTableCell component='th' scope='row' sx={{ color: '#fff' }}>
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: '#fff' }} align='left'>
                    {row.price}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: '#fff' }} align='left'>
                    {row.winRate}
                  </StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap', color: '#fff' }} align='right'>
                    {row.date}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TableContainer
        component={Paper}
        className={
          watchType === CCC_ACCEPT && bidRows?.length > 0
            ? null
            : !isAuthenticated() || bidRows?.length === 0
            ? 'table_blur'
            : null
        }
      >
        {bidRows?.length > 0 && (
          <Table sx={{ minWidth: 300, borderRadius: 0 }} aria-label='customized table'>
            <TableHead sx={{ height: 2 }}>
              <TableRow>
                <StyledTableCell>NAME</StyledTableCell>
                <StyledTableCell align='left'>PRICE</StyledTableCell>
                <StyledTableCell align='left'>WIN RATE</StyledTableCell>
                <StyledTableCell align='right'>DATE</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bidRows?.map((row) => (
                <StyledTableRow key={row?.created_at}>
                  <StyledTableCell component='th' scope='row' sx={isSelfBid(row)}>
                    {checkBidderName(row?.name)}
                  </StyledTableCell>
                  <StyledTableCell align='left' sx={isSelfBid(row)}>
                    ${Math.round(row?.offer_price)?.toLocaleString('en-US')}
                  </StyledTableCell>
                  <StyledTableCell sx={isSelfBid(row)} align='left'>
                    {Math.round(row?.win_rate)}%
                  </StyledTableCell>
                  <StyledTableCell sx={isSelfBid(row)} align='right'>
                    {formatDate(row?.created_at)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
};
