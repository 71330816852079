import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { icons } from '../../base//constants/ImageConstants';
import './Header.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as PATH from '../../base/constants/RoutePath';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../Redux/Actions/UserAction';
import { GET_USER_URL } from '../../base/constants/apiEndPoints';
import { isAuthenticated } from '../../base/utils/utils';
import { STORE_LOCATION } from '../../Redux/Types';

export const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [menu, setMenu] = React.useState();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  React.useEffect(() => {
    isAuthenticated() && dispatch(getUserData(GET_USER_URL));
  }, []);
  const userData = useSelector((state) => state?.userReducer?.userData);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setMenu();
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    handleMenuClose();
    localStorage.clear();
    dispatch({ type: STORE_LOCATION, payload: '' });
    navigate(PATH.DASHBOARD_PAGE);
  };
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        className={menu === 'Profile' ? 'menu_selected' : null}
        onClick={() => {
          navigate(PATH.MY_ACCOUNT), handleMenuClose();
          setMenu('Profile');
        }}
      >
        Profile
      </MenuItem>
      <MenuItem
        className={menu === 'Favorites' ? 'menu_selected' : null}
        onClick={() => {
          navigate(PATH.FAVORITE), handleMenuClose();
          setMenu('Favorites');
        }}
      >
        Favorites
      </MenuItem>
      <MenuItem
        className={menu === 'MyWatch' ? 'menu_selected' : null}
        onClick={() => {
          navigate(PATH.MY_WATCH_PAGE), handleMenuClose();
          setMenu('MyWatch');
        }}
      >
        My Watches
      </MenuItem>
      <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className='mob_menu'
    >
      <MenuItem>
        <Link to='/inventory' className='links' onClick={handleMobileMenuClose}>
          Browse
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to='about' className='links' onClick={handleMobileMenuClose}>
          about us
        </Link>
      </MenuItem>

      <MenuItem>
        <Link to='/contact' className='links' onClick={handleMobileMenuClose}>
          contact us
        </Link>
      </MenuItem>

      {isAuthenticated() ? (
        <MenuItem onClick={() => handleProfileMenuOpen()}>
          <button
            className='user_dropdown'
            aria-label='account of current user'
            aria-controls='primary-search-account-menu'
            aria-haspopup='true'
            color='inherit'
          >
            <span>{userData?.first_name}</span>
            <img
              src={isAuthenticated() ? userData?.profile_pic || icons.User : icons.User}
              className='d-md-inline-block d-none'
            />

            <img
              src={isAuthenticated() ? userData?.profile_pic || icons.User1 : icons.User1}
              className='d-md-none d-inline-block'
            />
          </button>
        </MenuItem>
      ) : (
        <MenuItem>
          <Link to='/login' className='links' onClick={handleMobileMenuClose}>
            Login
          </Link>
          <img src={icons.User} alt='icons.User' className='profile_icon' />
        </MenuItem>
      )}
    </Menu>
  );

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 0);
    });
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static' className={scroll ? 'header header_scroll' : 'header'}>
        {/* <div className='container-fluid padding_70 container_head'> */}
        <div className='container-fluid'>
          <Toolbar className='p-0'>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='show more'
                aria-controls={mobileMenuId}
                aria-haspopup='true'
                onClick={handleMobileMenuOpen}
                className='p-0'
              >
                <img src={icons.Toggle1} alt='icons.Toggle' />
              </IconButton>
            </Box>

            <Box sx={{ flexGrow: { xs: '1', sm: '1', md: '0' } }} />

            <img
              src={icons.Logo}
              className='logo_head'
              alt='icons.Logo'
              onClick={() => navigate('/')}
            />

            <Box sx={{ flexGrow: { xs: '1', sm: '1', md: '1' } }} />

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <ul className='p-0 mb-0'>
                <li>
                  <Link
                    to='/inventory'
                    className={location.pathname === '/inventory' ? 'selected_link' : 'links'}
                  >
                    Browse
                  </Link>
                </li>
                <li>
                  <Link
                    to='/about'
                    className={location.pathname === '/about' ? 'selected_link' : 'links'}
                  >
                    about us
                  </Link>
                </li>
                <li>
                  <Link
                    to='contact'
                    className={location.pathname === '/contact' ? 'selected_link' : 'links'}
                  >
                    contact us
                  </Link>
                </li>
                {isAuthenticated() ? (
                  <li>
                    <Link
                      to={PATH.MY_ACCOUNT}
                      style={{ marginRight: '0px' }}
                      className={location.pathname === PATH.MY_ACCOUNT ? 'selected_link' : 'links'}
                    >
                      {userData?.first_name}
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link
                      to='/login'
                      style={{ marginRight: '0px' }}
                      className={location.pathname === '/login' ? 'selected_link' : 'links'}
                    >
                      Log in
                    </Link>
                  </li>
                )}{' '}
              </ul>
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <button
                className='user_dropdown'
                aria-label='account of current user'
                aria-controls={menuId}
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
              >
                <img
                  src={isAuthenticated() ? userData?.profile_pic || icons.User : icons.User}
                  className={isAuthenticated() ? 'user_profile' : null}
                  alt='user'
                />
              </button>
            </Box>
          </Toolbar>
        </div>
      </AppBar>
      {renderMobileMenu}
      {isAuthenticated() && renderMenu}
    </Box>
  );
};
