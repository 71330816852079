import * as React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { Button, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { icons } from '../../base/constants/ImageConstants';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { emailExistBuyNow } from '../../base/validations';
import { useDispatch } from 'react-redux';
import { getUserData, postFormData } from '../../Redux/Actions';
import {
  GET_USER_URL,
  LOGIN_URL,
  SEND_OTP_URL,
  VERIFY_OTP_BUY_NOW_URL,
} from '../../base/constants/apiEndPoints';
import { openModal } from '../../Redux/Actions/AppAction';
import { refresh_token, token, userId } from '../../base/constants/AppConstant';
import './LoginDrawers.css';
import { LOG_IN, VERIFICATION_CODE } from '../../base/constants/modalTitles';

export const LoginDrawers = ({ onChangeState, watch_id, email, isEmailExist, handleCCCBuyNow }) => {
  const [showOTPField, setShowOTPField] = React.useState(false);
  const [isOtpValid, setIsOtpValid] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    email: email || '',
    password: '',
    verify_OTP: '',
  };
  const onSubmit = async (values, submitProps) => {
    const data = { email: values.email.toLowerCase(), password: values.password };
    const response = await dispatch(postFormData(LOGIN_URL, data));
    if (response?.status === 200 || response?.status === 201) {
      localStorage.setItem(token, response?.data?.data?.tokens);
      localStorage.setItem(refresh_token, response?.data?.data?.refresh_token);
      localStorage.setItem(userId, response?.data?.data?.id);
      const result = await dispatch(getUserData(GET_USER_URL));
      isEmailExist
        ? handleCCCBuyNow(result?.data?.data)
        : navigate(`/productview/watch/${watch_id}`);
    } else {
      const filterResponse = {
        title: LOG_IN,
        message: response?.data?.errors?.non_field_errors[0] || 'Something went wrong',
        success: false,
      };
      dispatch(openModal(filterResponse));
    }
    onChangeState();
    submitProps.setSubmitting(false);
    submitProps.resetForm();
  };
  const sendOTP = async (phoneNumber, error) => {
    if (!phoneNumber || error) {
      const filterResponse = {
        title: VERIFICATION_CODE,
        message: 'Please enter valid phone number',
        success: false,
      };
      await dispatch(openModal(filterResponse));
    } else {
      const data = {
        phone_number: `${'+1'}${phoneNumber}`,
      };
      const response = await dispatch(postFormData(SEND_OTP_URL, data));
      // responseHandler(VERIFICATION_CODE, response);
      if (response?.status === 200) {
        setShowOTPField(true);
      }
    }
  };
  const verifyOTP = async (phoneNumber, OTP) => {
    const data = {
      phone_number: `${'+1'}${phoneNumber}`,
      code: OTP,
    };
    const response = await dispatch(postFormData(VERIFY_OTP_BUY_NOW_URL, data));
    if (response?.data?.status_code === 200) {
      setIsOtpValid(true);
    }
  };
  return (
    <div>
      <Box role='presentation' className='drawer_box'>
        <img onClick={() => onChangeState()} src={icons.closeIcon} alt='' className='close_btn' />
        <h2>{isEmailExist ? 'OFFERS' : 'LOGIN'} </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={emailExistBuyNow}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, dirty, isValid, setFieldValue }) => (
            <Form className='formBody' autoComplete='off'>
              <InputLabel htmlFor='outlined-adornment-amount' error={errors.email && touched.email}>
                Email
              </InputLabel>
              <Field name='email'>
                {({ field }) => (
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    startAdornment={<InputAdornment position='start' />}
                    value={values?.email?.toLowerCase()}
                    error={errors.email && touched.email}
                    label='email'
                    {...field}
                    onChange={(e) => setFieldValue('email', e.target.value?.trim())}
                  />
                )}
              </Field>
              <ErrorMessage name='email'>
                {(msg) => (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '11px',
                      marginTop: '5px',
                    }}
                  >
                    {msg}
                  </div>
                )}
              </ErrorMessage>
              <div className='position-relative cell_phone'>
                <InputLabel
                  htmlFor='outlined-adornment-amount'
                  error={errors.password && touched.password}
                >
                  Cell Phone
                </InputLabel>
                <Field name='password'>
                  {({ field }) => (
                    <OutlinedInput
                      type='tel'
                      id='outlined-adornment-amount'
                      startAdornment={<InputAdornment position='start'></InputAdornment>}
                      error={errors.password && touched.password}
                      label='Cell Phone'
                      {...field}
                      onChange={(e) => setFieldValue('password', e.target.value?.trim())}
                    />
                  )}
                </Field>
                <ErrorMessage name='password'>
                  {(msg) => (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '11px',
                        marginTop: '5px',
                      }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
                <div>
                  <Button
                    disabled={!values.password || errors.password}
                    className='sendOTP'
                    onClick={() => sendOTP(values.password)}
                  >
                    {showOTPField ? 'RE-SEND' : 'SEND CODE'}
                  </Button>
                  {showOTPField && (
                    <div className='position-relative'>
                      <>
                        <InputLabel
                          htmlFor='outlined-adornment-amount'
                          error={errors.verify_OTP && touched.verify_OTP}
                        >
                          Verify Code
                        </InputLabel>
                        <Field name='verify_OTP'>
                          {({ field }) => (
                            <OutlinedInput
                              type='tel'
                              id='outlined-adornment-amount'
                              startAdornment={<InputAdornment position='start'></InputAdornment>}
                              error={errors.verify_OTP && touched.verify_OTP}
                              label='Verify Code'
                              {...field}
                              onChange={(e) => setFieldValue('verify_OTP', e.target.value?.trim())}
                            />
                          )}
                        </Field>
                        <ErrorMessage name='verify_OTP'>
                          {(msg) => (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '11px',
                                marginTop: '5px',
                              }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                        <Button
                          className={isOtpValid ? 'verify-sucess' : 'verifyOTP'}
                          disabled={!values.verify_OTP || errors.verify_OTP}
                          onClick={() => verifyOTP(values.password, values.verify_OTP)}
                        >
                          {isOtpValid ? (
                            <img src={icons?.SuccessIcon} style={{ width: '30%' }} />
                          ) : (
                            'VERIFY CODE'
                          )}
                        </Button>
                      </>
                    </div>
                  )}
                </div>
                <button
                  disabled={!isOtpValid || !(dirty && isValid)}
                  type='submit'
                  className={!isOtpValid || !(dirty && isValid) ? 'disabled-btn' : 'mt-4'}
                >
                  {isEmailExist ? `SUBMIT OFFER` : `LOGIN`}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {/* <div className='mistake_box'>
        <img src={icons.Dangor} alt='' />
        <p>Do not make mistake bid, POST monitors abuse bidders</p>
      </div> */}
      </Box>
    </div>
  );
};
