import * as TYPE from '../Types';

const initialState = {
  productData: [],
  filtersData: [],
  selectedFilters: {
    brand: '',
    model: '',
    case_size: '',
  },
  productDetail: [],
  watchType: '',
  error: '',
  isSaved: false,
  saveWatchData: {},
  favoriteData: [],
};

export const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.FETCH_INVENTORY_PRODUCTS_SUCCESS:
      return {
        ...state,
        productData: action.payload,
      };
    case TYPE.FETCH_INVENTORY_PRODUCTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TYPE.INVENTORY_FILTER_DATA_SUCCESS:
      return {
        ...state,
        filtersData: action.payload,
      };
    case TYPE.SET_BRAND_FILTERS:
      return {
        ...state,
        selectedFilters: { ...state.selectedFilters, brand: action.payload },
      };
    case TYPE.SET_MODEL_FILTERS:
      return {
        ...state,
        selectedFilters: { ...state.selectedFilters, model: action.payload },
      };
    case TYPE.SET_CASE_SIZE_FILTERS:
      return {
        ...state,
        selectedFilters: { ...state.selectedFilters, case_size: action.payload },
      };
    case TYPE.INVENTORY_FILTER_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TYPE.PRODUCT_INVENTORY_DETAIL_SUCCESS:
      return {
        ...state,
        productDetail: action.payload,
      };
    case TYPE.PRODUCT_INVENTORY_DETAIL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TYPE.GET_FAVORITE_SUCCESS:
      return {
        ...state,
        favoriteData: action.payload,
      };
    case TYPE.GET_FAVORITE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TYPE.GET_WATCH_TYPE:
      return {
        ...state,
        watchType: action.payload,
      };
    case TYPE.ADD_TO_SAVE_AFTER_LOGIN:
      return {
        ...state,
        isSaved: true,
        saveWatchData: action.payload,
      };
    case TYPE.RESET_STATE:
      return { ...state, productDetail: [], productData: [] };
    default:
      return state;
  }
};
