import {
  CLOSE_DETAIL_MODAL,
  CLOSE_MODAL,
  OPEN_DETAIL_MODAL,
  OPEN_LOGIN_MODAL,
  OPEN_MODAL,
  REFRESH,
  SET_LOADING,
} from '../Types';

export const openModal = ({ title, message, success, showButtons, data, action }) => {
  return {
    type: OPEN_MODAL,
    title,
    message,
    success,
    showButtons,
    data,
    action,
  };
};
export const closeModal = () => {
  return {
    type: CLOSE_MODAL,
  };
};

export const pageRefresh = () => {
  return {
    type: REFRESH,
  };
};

export const loader = (data) => {
  return {
    type: SET_LOADING,
    payload: data,
  };
};

export const handleLoginModal = (state) => {
  return {
    type: OPEN_LOGIN_MODAL,
    payload: state,
  };
};

export const openDetailModal = ({ title, message, success, showButtons, data, action }) => {
  return {
    type: OPEN_DETAIL_MODAL,
    title,
    message,
    success,
    showButtons,
    data,
    action,
  };
};
export const closeDeatilModal = () => {
  return {
    type: CLOSE_DETAIL_MODAL,
  };
};
