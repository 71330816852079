import { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

/**
 * react aptor(api-connector) a hook which connect api to react itself
 * @param ref - react forwarded ref
 * @param {Object} configuration - configuration object for setup
 * @param {Array} [deps=[]] - react dependencies array
 * @return domRef - can be bound to dom element
 */
export function useAptor(ref, configuration, deps = []) {
  const [instance, setInstance] = useState(null);
  const domRef = useRef(null);
  const { instantiate, destroy, getAPI, params } = configuration;
  useEffect(() => {
    const instanceReference = instantiate(domRef.current, params);
    setInstance(instanceReference);
    return () => {
      if (destroy) destroy(instanceReference, params);
    };
  }, deps);

  const api = useMemo(() => getAPI(instance, params), [instance]);

  useImperativeHandle(ref, api, [api]);

  return domRef;
}
