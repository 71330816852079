import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { MenuItem, Pagination, Select, Stack } from '@mui/material';
import { icons } from '../../base/constants/ImageConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomSearch, ImageSkeleton, MenuItems, TabPanel } from '../../components';
import { useDeviceDetect } from '../../hooks';
import './InventoryDetail.css';
import { fetchInventoryData, inventoryFilters } from '../../Redux/Actions/InventoryAction';
import { INVENTORY_FILTER_DATA_URL, INVENTORY_LIST_URL } from '../../base/constants/apiEndPoints';
import { useDispatch, useSelector } from 'react-redux';
import {
  SET_BRAND_FILTERS,
  SET_CASE_SIZE_FILTERS,
  SET_MODEL_FILTERS,
  STORE_LOCATION,
} from '../../Redux/Types';
import { AddToFavourite, isAuthenticated } from '../../base/utils/utils';
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const InventoryDetail = () => {
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [sortByValue, setSortByValue] = React.useState('newest');
  const [tabValue, setTabValue] = React.useState('all');
  const [isLoading, setLoading] = React.useState(true);
  const { isMobile } = useDeviceDetect();
  const [selectValue, setSelectValue] = useState('all');
  const dispatch = useDispatch();
  const location = useLocation();
  if (location.pathname) {
    dispatch({ type: STORE_LOCATION, payload: location.pathname });
  }
  const { productData, filtersData, selectedFilters } = useSelector(
    (state) => state?.inventoryReducer,
  );
  const isRefresh = useSelector((state) => state?.appReducer?.refresh);
  const apiSort = {
    ordering: '',
    feed: '',
    brand: '',
    page: page,
  };
  const getInventoryData = () => {
    dispatch(
      fetchInventoryData(INVENTORY_LIST_URL, {
        ...apiSort,
        ordering: sortByValue ? sortByValue : 'newest',
        feed: 'all',
      }),
    );
  };

  const getInventoryFilters = () => {
    dispatch(inventoryFilters(INVENTORY_FILTER_DATA_URL));
  };
  useEffect(() => {
    getInventoryData();
    getInventoryFilters();
  }, [isRefresh]);
  useEffect(() => {}, [isMobile, tabValue, sortByValue, dispatch, page]);
  const navigate = useNavigate();
  const handleChange = (e) => {
    setSelectValue(e.target.value?.trim());
    dispatch(
      fetchInventoryData(INVENTORY_LIST_URL, {
        ...apiSort,
        ordering: 'newest',
        feed: e.target.value?.trim(),
        brand: selectedFilters.brand === '' ? '' : selectedFilters.brand,
      }),
    );
  };
  const container = React.useRef(null);
  const navigateRoutes = (route) => {
    navigate(route);
  };
  const handleSortBy = async (event) => {
    setSortByValue(event.target.value);
  };
  const handleTabChange = (event, newValue) => {
    setPage(1);
    dispatch(
      fetchInventoryData(INVENTORY_LIST_URL, {
        ...apiSort,
        ordering: sortByValue,
        feed: event.target.innerText.toLowerCase()?.trim(),
        page: 1,
        brand: selectedFilters.brand,
        model: selectedFilters.model,
        case_size: selectedFilters.case_size,
      }),
    );
    setValue(newValue);
    setTabValue(event.target.innerText.toLowerCase());
  };
  const handlePageChange = (event, pageValue) => {
    window.scrollTo(0, 0);
    setPage(pageValue);
    dispatch(
      fetchInventoryData(INVENTORY_LIST_URL, {
        ...apiSort,
        ordering: sortByValue,
        feed: tabValue,
        page: pageValue,
      }),
    );
  };

  const handleOrderingAPI = async (event) => {
    dispatch(
      fetchInventoryData(INVENTORY_LIST_URL, {
        ...apiSort,
        ordering: event.target.dataset.value,
        feed: tabValue,
        page: 1,
        brand: selectedFilters.brand,
        model: selectedFilters.model,
        case_size: selectedFilters.case_size,
      }),
    );
  };
  useEffect(() => {
    dispatch(
      fetchInventoryData(INVENTORY_LIST_URL, {
        ...apiSort,
        ordering: sortByValue,
        feed: tabValue,
        page,
        brand: selectedFilters.brand,
        model: selectedFilters.model,
        case_size: selectedFilters.case_size,
      }),
    );
  }, [selectedFilters.brand, selectedFilters.model, selectedFilters.case_size]);
  const onSelectChange = async (val) => {
    if (val.name === 'BRAND') {
      dispatch({ type: SET_BRAND_FILTERS, payload: val.value });
    }
    if (val.name === 'Model') {
      dispatch({ type: SET_MODEL_FILTERS, payload: val.value });
    }
    if (val.name === 'CASE-SIZE') {
      dispatch({ type: SET_CASE_SIZE_FILTERS, payload: val.value });
    }
  };
  return (
    <section className='inventory_Detail'>
      <div className='container'>
        <div className='row inventory_share'>
          <div className='col-lg-6 col-md-6 col-12'>
            <div className='heading_box'>
              <h6>how it works?</h6>
              <h3>simple and fast</h3>
            </div>
          </div>
        </div>
        {isMobile && (
          <div className='all_filter_mob_show'>
            <div className='row mb-3'>
              <div className='col-lg-6 col-6 padding_right'>
                <div className='customize_your_search'>
                  <h3>Customize your search</h3>
                </div>
              </div>
              <div className='col-lg-6 col-6 padding_left'>
                <div className='customize_your_search'>
                  <CustomSearch sortByValue={sortByValue} tabValue={tabValue} value={value} />
                </div>
              </div>
            </div>
          </div>
        )}
        <Box sx={{ width: '100%' }}>
          <div className='row'>
            <div className='col-lg-6 col-md-5 inventory_tab inventory_mob_hide'>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleTabChange} aria-label='basic tabs example'>
                  <Tab label='All' {...a11yProps(0)} />
                  <Tab label='Live' {...a11yProps(1)} />
                  <Tab label='Sold' {...a11yProps(2)} />
                </Tabs>
              </Box>
            </div>

            <div className='col-lg-6 col-sm-5 col-4 all_filter_mob_show padding_right'>
              <div className='Popularity'>
                <div className='filter custom_select sel_all_mob'>
                  <img src={icons.FilterIcon} alt='' />
                  <Select
                    value={selectValue}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value={'all'}>All </MenuItem>
                    <MenuItem value={'live'}>Live</MenuItem>
                    <MenuItem value={'sold'}>Sold</MenuItem>
                  </Select>
                </div>
                <div className='filter inventory_mob_hide'>
                  <img src={icons.Popularity2} alt='' className='mr-3' />
                  <img src={icons.Popularity4} alt='' />
                </div>
                <div className='filter inventory_mob_hide'>
                  <img src={icons.Popularity1} alt='' />
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-7 col-sm-7 col-8 padding_left'>
              <div className='Popularity'>
                <div className='filter custom_select custom_select_sort '>
                  <img src={icons.Popularity3} alt='' /> <span> Sort by:</span>
                  <Select
                    value={sortByValue}
                    onChange={handleSortBy}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value='newest' onClick={handleOrderingAPI}>
                      Newest
                    </MenuItem>
                    <MenuItem value={'featured'} onClick={handleOrderingAPI}>
                      Featured
                    </MenuItem>
                    {isAuthenticated() && (
                      <MenuItem value={'saved'} onClick={handleOrderingAPI}>
                        Saved
                      </MenuItem>
                    )}
                    <MenuItem value={'price_high_to_low'} onClick={handleOrderingAPI}>
                      Price High to Low
                    </MenuItem>
                    <MenuItem value={'price_low_to_high'} onClick={handleOrderingAPI}>
                      Price Low to high
                    </MenuItem>
                  </Select>
                </div>
                {/* <div className='filter inventory_mob_hide'>
                  <img src={icons.Popularity2} alt='' className='mr-3' />
                  <img src={icons.Popularity4} alt='' />
                </div> */}
                {/* <div className='filter inventory_mob_hide'>
                  <img src={icons.Popularity1} alt='' />
                </div> */}
              </div>
            </div>
          </div>
          <div className='row luxury_watches'>
            <div className='col-lg-6  col-sm-6 col-12 pl-0'>
              <div className='lax_watch'>
                <h2>{productData?.count} Luxury Watches</h2>
              </div>
            </div>
            <div className='col-lg-6  col-sm-6  inventory_mob_hide'>
              <div className='fil_brand ul_fixed'>
                <MenuItems
                  width='120'
                  name='BRAND'
                  subName={filtersData?.brand || ['a', 'b', 'c']}
                  onSelectChange={onSelectChange}
                  className='brand_drop'
                />
                <MenuItems
                  width='120'
                  name='Model'
                  subName={filtersData?.model || ['a', 'b', 'c']}
                  onSelectChange={onSelectChange}
                />
                <MenuItems
                  width='120'
                  name='CASE-SIZE'
                  subName={filtersData?.case_size || ['a', 'b', 'c']}
                  onSelectChange={onSelectChange}
                />
                {/* <img src={icons.SliderIcon} alt='' /> */}
              </div>
            </div>
          </div>
          <TabPanel value={value} index={0}>
            <div className='row luxury_card'>
              <>
                {productData?.results &&
                  productData?.results?.map((data) =>
                    data?.status_type === 'ccc_complete' ? (
                      <div key={data?.id} className='col-lg-3 col-6 sold_cart'>
                        {isLoading && <ImageSkeleton width={210} height={180} />}
                        <div
                          className={isLoading ? 'card_hidden' : 'card'}
                          onClick={() => navigateRoutes(`/productview/watch/${data?.id}`)}
                        >
                          <div className='card_img'>
                            <img
                              onLoad={() => setLoading(false)}
                              src={data?.main_image || icons.Drop1}
                              className='watch_img'
                              alt='Watch'
                            />
                          </div>
                          <a href='#'>
                            <div className='card_body text-left'>
                              <h5>
                                {data?.brand || 'H. Moser & Cie.'} | {data?.model || '3804-1205'}
                              </h5>
                              {/* <h6>Pioneer Tourbillon</h6> */}
                              {/* <p>{data?.model || '3804-1205'}</p> */}
                              <h3>
                                {data?.state || 'Whittier'} | {data?.city || 'Ca'}{' '}
                              </h3>
                              <span>${Math.round(data?.ask_price)?.toLocaleString() || 0}</span>
                            </div>
                          </a>
                        </div>
                        <img
                          src={data?.is_saved ? icons.Filled_Bookmark : icons.Bookmark}
                          className={isLoading ? 'card_hidden' : 'bookmark_img'}
                          alt='icons.Bookmark'
                          onClick={() => AddToFavourite(data?.id, true, navigate)}
                        />
                        <img
                          src={data?.is_featured ? icons.StarIcon : null}
                          className={isLoading ? 'card_hidden' : 'star_icon'}
                          alt=''
                        />
                        <div className={isLoading ? 'card_hidden' : 'centered'}>SOLD</div>
                      </div>
                    ) : (
                      <div key={data?.id} className='col-lg-3 col-6'>
                        {isLoading && <ImageSkeleton width={210} height={180} />}
                        <div
                          className={isLoading ? 'card_hidden' : 'card'}
                          onClick={() => navigateRoutes(`/productview/watch/${data?.id}`)}
                        >
                          <div className='card_img'>
                            <img
                              onLoad={() => setLoading(false)}
                              src={data?.main_image || icons.Drop1}
                              className='watch_img'
                              alt='Watch'
                            />
                          </div>
                          <a href='#'>
                            <div className='card_body text-left'>
                              <h5>
                                {data?.brand || 'H. Moser & Cie.'} | {data?.model || '3804-1205'}
                              </h5>
                              {/* <h6>Pioneer Tourbillon</h6> */}
                              {/* <p>{data?.model || '3804-1205'}</p> */}
                              <h3>
                                {data?.state || 'Whittier'} | {data?.city || 'Ca'}{' '}
                              </h3>
                              <span>${Math.round(data?.ask_price)?.toLocaleString() || 0}</span>
                            </div>
                          </a>
                        </div>
                        <img
                          src={data?.is_saved ? icons.Filled_Bookmark : icons.Bookmark}
                          className={isLoading ? 'card_hidden' : 'bookmark_img'}
                          alt='icons.Bookmark'
                          onClick={() => AddToFavourite(data?.id, true, navigate)}
                        />
                        <img
                          src={data?.is_featured ? icons.StarIcon : null}
                          className={isLoading ? 'card_hidden' : 'star_icon'}
                          alt=''
                        />
                      </div>
                    ),
                  )}
              </>
              <Box sx={{ p: 1, my: 1, border: '1px solid' }} ref={container} />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className='row luxury_card'>
              <>
                {productData?.results &&
                  productData?.results?.map((data) =>
                    data?.status_type === 'ccc_complete' ? (
                      <div key={data?.id} className='col-lg-3 col-6 sold_cart'>
                        {isLoading && <ImageSkeleton width={210} height={180} />}
                        <div
                          className={isLoading ? 'card_hidden' : 'card'}
                          onClick={() => navigateRoutes(`/productview/watch/${data?.id}`)}
                        >
                          <div className='card_img'>
                            <img
                              onLoad={() => setLoading(false)}
                              src={data?.main_image || icons.Drop1}
                              className='watch_img'
                              alt='Watch'
                            />
                          </div>
                          <a href='#'>
                            <div className='card_body text-left'>
                              <h5>
                                {data?.brand || 'H. Moser & Cie.'} | {data?.model || '3804-1205'}
                              </h5>

                              {/* <h6>Pioneer Tourbillon</h6> */}

                              {/* <p>{data?.model || '3804-1205'}</p> */}
                              <h3>
                                {data?.state || 'Whittier'} | {data?.city || 'Ca'}{' '}
                              </h3>
                              <span>${Math.round(data?.ask_price)?.toLocaleString() || 0}</span>
                            </div>
                          </a>
                        </div>
                        <img
                          src={data?.is_saved ? icons.Filled_Bookmark : icons.Bookmark}
                          className='bookmark_img'
                          alt='icons.Bookmark'
                          onClick={() => AddToFavourite(data?.id, true, navigate)}
                        />
                        <img
                          src={data?.is_featured ? icons.StarIcon : null}
                          className={isLoading ? 'card_hidden' : 'star_icon'}
                          alt=''
                        />
                        <div className={isLoading ? 'card_hidden' : 'centered'}>SOLD</div>
                      </div>
                    ) : (
                      <div key={data?.id} className='col-lg-3 col-6'>
                        <div
                          className={isLoading ? 'card_hidden' : 'card'}
                          onClick={() => navigateRoutes(`/productview/watch/${data?.id}`)}
                        >
                          <div className='card_img'>
                            <img
                              onLoad={() => setLoading(false)}
                              src={data?.main_image || icons.Drop1}
                              className='watch_img'
                              alt='Watch'
                            />
                          </div>
                          <a href='#'>
                            <div className='card_body text-left'>
                              <h5>
                                {data?.brand || 'H. Moser & Cie.'} | {data?.model || '3804-1205'}
                              </h5>

                              {/* <h6>Pioneer Tourbillon</h6> */}

                              {/* <p>{data?.model || '3804-1205'}</p> */}
                              <h3>
                                {data?.state || 'Whittier'} | {data?.city || 'Ca'}{' '}
                              </h3>
                              <span>${Math.round(data?.ask_price)?.toLocaleString() || 0}</span>
                            </div>
                          </a>
                        </div>
                        <img
                          src={data?.is_saved ? icons.Filled_Bookmark : icons.Bookmark}
                          className='bookmark_img'
                          alt='icons.Bookmark'
                          onClick={() => AddToFavourite(data?.id, true, navigate)}
                        />
                        <img
                          src={data?.is_featured ? icons.StarIcon : null}
                          className={isLoading ? 'card_hidden' : 'star_icon'}
                          alt=''
                        />
                      </div>
                    ),
                  )}
              </>
              <Box sx={{ p: 1, my: 1, border: '1px solid' }} ref={container} />
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className='row luxury_card'>
              <>
                {productData?.results &&
                  productData?.results?.map((data) =>
                    data?.status_type === 'ccc_complete' ? (
                      <div key={data?.id} className='col-lg-3 col-6 sold_cart'>
                        <div
                          className={isLoading ? 'card_hidden' : 'card'}
                          onClick={() => navigateRoutes(`/productview/watch/${data?.id}`)}
                        >
                          <div className='card_img'>
                            <img
                              onLoad={() => setLoading(false)}
                              src={data?.main_image || icons.Drop1}
                              className='watch_img'
                              alt='Watch'
                            />
                          </div>
                          <a href='#'>
                            <div className='card_body text-left'>
                              <h5>
                                {data?.brand || 'H. Moser & Cie.'} | {data?.model || '3804-1205'}
                              </h5>

                              {/* <h6>Pioneer Tourbillon</h6> */}

                              {/* <p>{data?.model || '3804-1205'}</p> */}
                              <h3>
                                {data?.state || 'Whittier'} | {data?.city || 'Ca'}{' '}
                              </h3>
                              <span>${Math.round(data?.ask_price)?.toLocaleString() || 0}</span>
                            </div>
                          </a>
                        </div>
                        <img
                          src={data?.is_saved ? icons.Filled_Bookmark : icons.Bookmark}
                          className='bookmark_img'
                          alt='icons.Bookmark'
                          onClick={() => AddToFavourite(data?.id, true, navigate)}
                        />
                        <img
                          src={data?.is_featured ? icons.StarIcon : null}
                          className={isLoading ? 'card_hidden' : 'star_icon'}
                          alt=''
                        />
                        <div className={isLoading ? 'card_hidden' : 'centered'}>SOLD</div>
                      </div>
                    ) : (
                      <div key={data?.id} className='col-lg-3 col-6'>
                        <div
                          className={isLoading ? 'card_hidden' : 'card'}
                          onClick={() => navigateRoutes(`/productview/watch/${data?.id}`)}
                        >
                          <div className='card_img'>
                            <img
                              onLoad={() => setLoading(false)}
                              src={data?.main_image || icons.Drop1}
                              className='watch_img'
                              alt='Watch'
                            />
                          </div>
                          <a href='#'>
                            <div className='card_body text-left'>
                              <h5>
                                {data?.brand || 'H. Moser & Cie.'} | {data?.model || '3804-1205'}
                              </h5>

                              {/* <h6>Pioneer Tourbillon</h6> */}

                              {/* <p>{data?.model || '3804-1205'}</p> */}
                              <h3>
                                {data?.state || 'Whittier'} | {data?.city || 'Ca'}{' '}
                              </h3>
                              <span>${Math.round(data?.ask_price)?.toLocaleString() || 0}</span>
                            </div>
                          </a>
                        </div>
                        <img
                          src={data?.is_saved ? icons.Filled_Bookmark : icons.Bookmark}
                          className='bookmark_img'
                          alt='icons.Bookmark'
                          onClick={() => AddToFavourite(data?.id, true, navigate)}
                        />
                        <img
                          src={data?.is_featured ? icons.StarIcon : null}
                          className={isLoading ? 'card_hidden' : 'star_icon'}
                          alt=''
                        />
                      </div>
                    ),
                  )}
              </>
              <Box sx={{ p: 1, my: 1, border: '1px solid' }} ref={container} />
            </div>
          </TabPanel>
        </Box>
        <div className='pagination_div'>
          <Stack spacing={2}>
            <Pagination
              page={page}
              count={Math.ceil(productData?.count / 16)}
              variant='outlined'
              shape='rounded'
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
    </section>
  );
};
