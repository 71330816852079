import React, { Suspense } from 'react';
// import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ScrollToTop, CustomModal, Loader } from '../components';
import { DeatilPageModal } from '../components/Modal/DeatilPageModal/DeatilPageModal';
import { LoginModal } from '../components/Modal/LoginModal/LoginModal';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoute } from './PublicRoute';
import { privateRoutes, publicRoutes } from './RouteTypes';

const Routing = () => {
  // const isLoading = useSelector((state) => state?.appReducer?.isLoading);
  const Header = React.lazy(() =>
    import('../components').then((module) => ({
      default: module.Header,
    })),
  );
  const Footer = React.lazy(() =>
    import('../components').then((module) => ({
      default: module.Footer,
    })),
  );
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <ScrollToTop>
          <Header />
          <CustomModal />
          <DeatilPageModal />
          <LoginModal />
          {/* {isLoading ? <Loader /> : null} */}
          <Routes>
            {publicRoutes?.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  key={index}
                  element={<PublicRoute Component={route.Component} />}
                />
              );
            })}
            {privateRoutes?.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  key={index}
                  element={<PrivateRoutes Component={route.Component} />}
                />
              );
            })}
          </Routes>
          <Footer />
        </ScrollToTop>
      </Suspense>
    </Router>
  );
};

export default Routing;
