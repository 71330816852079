import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const MenuItems = (props) => {
  let { width, name, subName, onSelectChange } = props;
  subName = subName?.map((item) =>
    item && typeof item === 'string' ? item.replace('_', ' ') : item,
  );
  const [userValue, setUserValue] = React.useState([]);

  const handleChange = (event) => {
    setUserValue(event.target.value);
    onSelectChange(event.target);
  };

  return (
    <div>
      <FormControl
        sx={{
          m: 1,
          width: width,
          mt: 3,
        }}
      >
        <Select
          disableUnderline={true}
          displayEmpty
          name={name}
          value={userValue}
          onChange={handleChange}
          variant='standard'
          renderValue={(selected) => {
            if (selected.length === 0) {
              return name;
            }

            return selected;
          }}
        >
          <MenuItem disabled value=''>
            {name}
          </MenuItem>
          <MenuItem value='' style={{color: 'red', fontStyle: 'italic'}}>
              Reset
          </MenuItem>
          {subName?.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
