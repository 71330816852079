export const DASHBOARD_PAGE = '/';
export const PRODUCTVIEW_PAGE = '/productview/watch/:id';
export const INVENTORY_PAGE = '/inventory';
export const INVENTORY_BRAND = '/brands/:brand';
export const PROFILE_PAGE = '/profile';
export const ABOUT_PAGE = '/about';
export const CONTACT_PAGE = '/contact';
export const LOGIN_PAGE = '/login';
export const APPRAISAL_PAGE = '/appraisal';
export const REGISTER_PAGE = '/register';
export const FORGOT_PASSWORD = '/forgot-password';
export const CONFIRM_PASSWORD = '/confirm-password/:uidb64/:token';
export const MY_ACCOUNT = '/my-account';
export const MY_WATCH_PAGE = '/my-watch';
export const FAVORITE = '/favorite';
export const PageNotFound = '*';
