/* eslint-disable no-debugger */
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { icons } from '../../base/constants/ImageConstants';
import { checkFileWidthHeight } from '../../base/utils/utils';
import { openModal } from '../../Redux/Actions';
import './DragDrop.css';
export const DragDrop = (props) => {
  const { fileCount, handleFileChange, field, modalOpen } = props;
  const [acceptedCount, SetAcceptedCount] = React.useState('');
  const dispatch = useDispatch();

  const handleValidateFiles = async (files) => {
    const filesStatus = [];
    for (const file of files) {
      const { isValid, height, width } = await checkFileWidthHeight(file);
      filesStatus.push(isValid);
      if (height <= 600 || width <= 600) {
        const modalResponse = {
          title: 'Image Select',
          success: false,
          message: `${file?.path} is less than 600 x 600 px.`,
        };
        dispatch(openModal(modalResponse));
      }
    }
    const isValid = filesStatus.every((f) => f === true);
    return { isValid };
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles?.length === 0) {
        const modalResponse = {
          title: 'Image Select',
          success: false,
          message: `Maximum 6 files can be uploaded`,
        };
        dispatch(openModal(modalResponse));
      }
      if (Array.isArray(acceptedFiles) && acceptedFiles?.length > 0 && acceptedFiles?.length <= 6) {
        const { isValid } = await handleValidateFiles(acceptedFiles);
        if (isValid) {
          handleFileChange(acceptedFiles.slice(0, 6 - parseInt(fileCount?.length)));
          SetAcceptedCount(acceptedFiles?.length);
        }
      }
    },
    [fileCount],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 6,
    autoQueue: false,
    multiple: true,
    accept: {
      'image/*': [
        '.png',
        '.gif',
        '.jpeg',
        '.jpg',
        '.apng',
        '.avif',
        '.jfif',
        '.pjpeg',
        '.pjp',
        '.svg',
        '.webp',
        '.HEIC',
        '.HEVC',
        '.HEIF',
      ],
    },
    noClick: fileCount?.length > 5 ? true : false,
    noKeyboard: fileCount?.length > 5 ? true : false,
    noDrag: fileCount?.length > 5 ? true : false,
  });
  return (
    <>
      <section className='container' style={{ padding: 0 }}>
        <div {...getRootProps({ className: 'dropzone' })}>
          {!modalOpen && <img src={icons.Drag} />}
          <input {...field} {...getInputProps()} />
          {modalOpen ? (
            <div className='add-image'>
              <img src={icons.plusIcon} />
            </div>
          ) : isDragActive ? (
            <p>Drop the files here ...</p>
          ) : fileCount?.length > 5 || acceptedCount === 0 ? (
            <p>Maximum 6 files will be upload</p>
          ) : fileCount?.length === 1 || fileCount?.length === 2 ? (
            <p>Minimum 3 files will be upload</p>
          ) : (
            <p>Drag and drop a file here or click</p>
          )}
        </div>
      </section>
    </>
  );
};
