export const isNumber = (value) => {
  let result = false;
  if (parseFloat(value) === parseFloat(value)) {
    result = true;
  }
  return result;
};
export function isNullValue(value) {
  return (
    value === null ||
    value === undefined ||
    (typeof value === 'string' ? value.trim() : value) === ''
  );
}
/** create formData */
export const createFormData = (item) => {
  let formData = new FormData();
  for (const key in item) {
    formData.append(key, item[key]);
  }
  return formData;
};
/** base64 to image file */
export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
/** blob to image file */
export const blobToFile = (blob, filename) => {
  const file = new File([blob], filename,{type:"image/png", lastModified:new Date().getTime()});
  return file;
}
