import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export const ImageSkeleton = ({width, height}) => {
  return (
    <Grid container wrap='nowrap'>
      <Box sx={{ width: 200, marginRight: 0.5, my: 5 }}>
        <Skeleton variant='rectangular' width={width} height={height} />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width='60%' />
        </Box>
      </Box>
    </Grid>
  );
};
