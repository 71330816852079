import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProductViewSlider.css';
import { icons } from '../../base/constants/ImageConstants';

export const ProductViewSlider = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className='slider-container product_view'>
      <Slider {...settings}>
        <div className='card'>
          <div className='card_img'>
            <img src={icons.ProductWatch} className='watch_img' alt='Watch' />
          </div>
        </div>
        <div className='card'>
          <div className='card_img'>
            <img src={icons.ProductWatch2} className='watch_img' alt='Watch' />
          </div>
        </div>
        <div className='card'>
          <div className='card_img'>
            <img src={icons.ProductWatch3} className='watch_img' alt='Watch' />
          </div>
        </div>
        <div className='card'>
          <div className='card_img'>
            <img src={icons.ProductWatch4} className='watch_img' alt='Watch' />
          </div>
        </div>
        <div className='card'>
          <div className='card_img'>
            <img src={icons.ProductWatch5} className='watch_img' alt='Watch' />
          </div>
        </div>
        <div className='card'>
          <div className='card_img'>
            <img src={icons.ProductWatch6} className='watch_img' alt='Watch' />
          </div>
        </div>
        <div className='card'>
          <div className='card_img'>
            <img src={icons.ProductWatch} className='watch_img' alt='Watch' />
          </div>
        </div>
        <div className='card'>
          <div className='card_img'>
            <img src={icons.ProductWatch} className='watch_img' alt='Watch' />
          </div>
        </div>
        <div className='card'>
          <div className='card_img'>
            <img src={icons.ProductWatch} className='watch_img' alt='Watch' />
          </div>
        </div>
      </Slider>
    </div>
  );
};
