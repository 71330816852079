import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../base/constants/ImageConstants';
import * as PATH from '../../base/constants/RoutePath';
import './About.css';
import { isAuthenticated } from '../../base/utils/utils';
export const About = () => {
  return (
    <div>
      <section className='about_top'>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='banner_content'>
                <h3>The Leading Data-Driven</h3>
                <h2>Luxuries Trading Platform</h2>
                <p>
                  We operate a data-driven online luxuries marketplace. Our platform aims to disrupt
                  the world of online luxuries sales, and bring clarity to the free market
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='sec_post'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='post_head'>
                <h3>POST your watches</h3>
                <p>
                  In its true essence, POST is an online auctioning platform where watch owners list
                  their watch for dealers to bid on them. Consequently, the highest bidder gets the
                  opportunity to purchase the used-watch directly from the sellers! Thus, POST helps
                  facilitate both sale and purchase decisions for watch that otherwise would have
                  not been found in the local market easily.
                </p>
              </div>
            </div>
          </div>

          <div className='mob_about mt-5 d-md-none d-block'>
            <div className='row'>
              <div className='col-md-6 col-6'>
                <div className='text-center about_content'>
                  <p>
                    <span className='about_icon'>
                      <img src={icons.Curser} alt='img' className='img-responsive' />
                    </span>
                  </p>
                  <h4>Get your watch seen</h4>
                  <p>Get more views on your POST listing than anywhere else on the internet</p>
                </div>
              </div>

              <div className='col-md-6 col-6'>
                <div className='text-center about_content'>
                  <p>
                    <span className='about_icon'>
                      <img src={icons.Card} alt='img' className='img-responsive' />
                    </span>
                  </p>
                  <h4>Get paid Securely</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus. </p>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className='watch_about_img'>
                  <img src={icons.Aboutmob1} alt='img' className='img-responsive' />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 col-6'>
                <div className='text-center about_content'>
                  <p>
                    <span className='about_icon'>
                      <img src={icons.Transaction} alt='img' className='img-responsive' />
                    </span>
                  </p>
                  <h4>Trade In</h4>
                  <p>
                    Post allows user to trade-in watches for one in our inventory. giving buyers
                    even more reasons to shop with us.
                  </p>
                </div>
              </div>

              <div className='col-md-6 col-6'>
                <div className='text-center about_content'>
                  <p>
                    <span className='about_icon'>
                      <img src={icons.Pie} alt='img' className='img-responsive' />
                    </span>
                  </p>
                  <h4>Sell Your Watch fast</h4>
                  <p>Get offer instantly from one of our POST specialist</p>
                </div>
              </div>
            </div>
          </div>

          <div className='row align-items-center botmm_spce d-md-flex d-none'>
            <div className='col-lg-6 col-md-6 mob_block'>
              <div className='about_center_img'>
                <img src={icons.MobAbout1} alt='img' className='img-responsive' />
              </div>
            </div>
            <div className='col-lg-3 col-md-4'>
              <div className='right_about'>
                <p>
                  <span className='about_icon'>
                    <img src={icons.Curser} alt='img' className='img-responsive' />
                  </span>
                </p>
                <h4>Get your watch seen</h4>
                <p>Get more views on your POST listing than anywhere else on the internet</p>
              </div>
              <div className='right_about'>
                <p>
                  <span className='about_icon'>
                    <img src={icons.Transaction} alt='img' className='img-responsive' />
                  </span>
                </p>
                <h4>Trade In</h4>
                <p>
                  Post allows user to trade-in watches for one in our inventory. giving buyers even
                  more reasons to shop with us.
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-md-4 mob_none'>
              <div className='about_center_img'>
                <img src={icons.About1} alt='img' className='img-responsive' />
              </div>
            </div>
            <div className='col-lg-3 col-md-4 left_text_about'>
              <div className='left_about'>
                <p>
                  <span className='about_icon'>
                    <img src={icons.Card} alt='img' className='img-responsive' />
                  </span>
                </p>
                <h4>Get paid Securely</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus. </p>
              </div>
              <div className='left_about'>
                <p>
                  <span className='about_icon'>
                    <img src={icons.Pie} alt='img' className='img-responsive' />
                  </span>
                </p>
                <h4>Sell Your Watch fast</h4>
                <p>Get offer instantly from one of our POST specialist</p>
              </div>
            </div>
          </div>
          <div className='row align-items-center mt-md-0 mt-5'>
            <div className='col-lg-12 col-md-12'>
              <div className='post_head'>
                <h3>We believe in mutual success</h3>
                <p>
                  Our platform mimics the logic of a free economic system but with the help of our
                  data analytics to ensure you price your watch competitivley and sell it quickly
                  despite ever-changing market conditions
                </p>
              </div>
            </div>

            <div className='mob_about mt-5 d-md-none d-block'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <div className='text-center about_content'>
                    <p>
                      <span className='about_icon'>
                        <img src={icons.Msg} alt='img' className='img-responsive' />
                      </span>
                    </p>
                    <h4>E-mail Marketing</h4>
                    <p>
                      Post conducts strategic e-mail marketing campaigns to directly target people
                      interesting in your watch
                    </p>
                  </div>
                </div>

                <div className='col-md-6 col-6'>
                  <div className='text-center about_content'>
                    <p>
                      <span className='about_icon'>
                        <img src={icons.Addgrid} alt='img' className='img-responsive' />
                      </span>
                    </p>
                    <h4>Strength in numbers</h4>
                    <p>
                      Our Platform attracts more potential buyers with our vast digital inventor
                      bolstering your chances of a sale.
                    </p>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6 col-12'>
                  <div className='watch_about_img'>
                    <img src={icons.Aboutmob2} alt='img' className='img-responsive' />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6 col-6'>
                  <div className='text-center about_content'>
                    <p>
                      <span className='about_icon'>
                        <img src={icons.Un} alt='img' className='img-responsive' />
                      </span>
                    </p>
                    <h4>Customer Retention</h4>
                    <p>We offer pricing data for purchasers to make buying decisions on the spot</p>
                  </div>
                </div>

                <div className='col-md-6 col-6'>
                  <div className='text-center about_content'>
                    <p style={{ paddingTop: '18px' }}>
                      <span className='about_icon'>
                        <img src={icons.Percent} alt='img' className='img-responsive' />
                      </span>
                    </p>
                    <h4>Marginal Fees</h4>
                    <p>
                      When you watch sells pay as little as 4%. This fee esures that we can continue
                      providing the best service
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-4 d-md-inline-block d-none pr-0'>
              <div className='right_about'>
                <p>
                  <span className='about_icon'>
                    <img src={icons.Msg} alt='img' className='img-responsive' />
                  </span>
                </p>
                <h4>E-mail Marketing</h4>
                <p>
                  Post conducts strategic e-mail marketing campaigns to directly target people
                  interesting in your watch
                </p>
              </div>
              <div className='right_about'>
                <p>
                  <span className='about_icon'>
                    <img src={icons.Un} alt='img' className='img-responsive' />
                  </span>
                </p>
                <h4>Customer Retention</h4>
                <p>We offer pricing data for purchasers to make buying decisions on the spot</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-4 d-md-inline-block d-none'>
              <div className='about_center_img about2_img'>
                <img src={icons.About2} alt='img' className='img-responsive' />
              </div>
            </div>

            <div className='col-lg-3 col-md-4 d-md-inline-block d-none pl-0'>
              <div className='left_about right_success'>
                <p>
                  <span className='about_icon'>
                    <img src={icons.Addgrid} alt='img' className='img-responsive' />
                  </span>
                </p>
                <h4>Strength in numbers</h4>
                <p>
                  Our Platform attracts more potential buyers with our vast digital inventor
                  bolstering your chances of a sale.
                </p>
              </div>
              <div className='left_about'>
                <p>
                  <span className='about_icon'>
                    <img src={icons.Percent} alt='img' className='img-responsive' />
                  </span>
                </p>
                <h4>Marginal Fees</h4>
                <p>
                  When you watch sells pay as little as 4%. This fee esures that we can continue
                  providing the best service
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='yourself_area'>
        <div className='container text-center'>
          <div className='row justify-content-center'>
            <div className='col-lg-12 '>
              <div className='about_bottom'>
                <h6>Find a new home for your watch</h6>
                <h3>Try it for yourself</h3>
                <p>
                  You are only a few steps away from getting real offers for your watch. <br /> Try
                  it free.
                </p>
                <div className='sell_btn'>
                  <Link to={isAuthenticated() ? PATH.APPRAISAL_PAGE : PATH.REGISTER_PAGE}>
                    Sell My Watch
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
