import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { INVENTORY_LIST_URL } from '../../base/constants/apiEndPoints';
import { icons } from '../../base/constants/ImageConstants';
import * as Path from '../../base/constants/RoutePath';
import { AddToFavourite } from '../../base/utils/utils';
import { fetchFeaturedData } from '../../Redux/Actions/LandingActions';
import { MostPopularSlider } from '../MobileView';
import './Featured.css';

export const Inventory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiSort = {
    ordering: '',
    feed: '',
    brand: '',
  };
  const featuredData = useSelector((state) => state?.landingReducer?.featuredData);
  const isRefresh = useSelector((state) => state.appReducer.refresh);
  useEffect(() => {
    dispatch(
      fetchFeaturedData(INVENTORY_LIST_URL, {
        ...apiSort,
        ordering: 'featured',
        feed: 'live',
      }),
    );
  }, [isRefresh]);
  const navigateRoutes = (route) => {
    navigate(route);
  };
  return (
    <div className='pt-md-0 mt-md-0'>
      <Container className='just_droped '>
        <h2>Featured </h2>
        <p className='hub_heading'>
          Browse through pre-owned watches listed recently on POST and start offer!
        </p>
        <div className='row just_drop_mob_hide'>
          {featuredData?.results && featuredData?.results?.length > 0 ? (
            featuredData?.results?.slice(0, 4)?.map((featured) => (
              <div key={featured?.id} className='col-lg-3 col-md-3 col-6'>
                <div
                  className='card'
                  onClick={() => navigateRoutes(`/productview/watch/${featured?.id}`)}
                >
                  <div className='card_img'>
                    <img
                      src={featured?.main_image || icons.Drop1}
                      className='watch_img'
                      alt='Watch'
                    />
                  </div>
                  <a>
                    <div className='card_body text-left'>
                      <h5 style={{ cursor: 'pointer' }}>
                        {featured?.brand || 'ROLEX'} | {featured?.model || '126610LV '}
                      </h5>

                      {/* <h6>Rolex Submariner Date</h6> */}

                      {/* <p>{featured?.model || '126610LV '}</p> */}
                      <h3>
                        {featured?.state || 'Whittier'} | {featured?.city || 'Ca'}{' '}
                      </h3>
                      <span>${Math.floor(featured?.ask_price)?.toLocaleString() || '22,133'}</span>
                    </div>
                  </a>
                </div>
                <img
                  src={featured?.is_saved ? icons.Filled_Bookmark : icons.Bookmark}
                  className='bookmark_img'
                  alt='Bookmark'
                  onClick={() => AddToFavourite(featured?.id, true, navigate)}
                />
                <img src={icons.StarIcon} className='star_icon' alt='' />
              </div>
            ))
          ) : (
            <p className='no_featured'>No Featured watches are available.</p>
          )}
          <div className='col-lg-12 inventory_btn'>
            <button onClick={() => navigateRoutes(Path.INVENTORY_PAGE)}>See More</button>
          </div>
        </div>
        <div className='drop_slider'>
          <MostPopularSlider />
          <div className='col-lg-12 inventory_btn btn_mob_feature'>
            <button onClick={() => navigateRoutes(Path.INVENTORY_PAGE)}>See More</button>
          </div>
        </div>
      </Container>
    </div>
  );
};
