import { useField } from 'formik';
import React from 'react';

export const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      {meta.touched && meta.error ? (
        <textarea
          style={{
            borderColor: 'red',
          }}
          className='form-control'
          {...field}
          {...props}
        />
      ) : (
        <input className='form-control' {...field} {...props} />
      )}
      {meta.touched && meta.error ? (
        <div
          className='error'
          style={{
            color: 'red',
            borderColor: 'red',
            fontSize: '11px',
            marginTop: '5px',
          }}
        >
          {meta.error}
        </div>
      ) : null}
    </>
  );
};
