import React from 'react';
import { icons } from '../../base/constants/ImageConstants';
import { Linechart } from '..';
import '../../pages/ProductView/Productview.css';
import { useNavigate } from 'react-router-dom';
import './PriceHistory.css';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { fetchGraphData } from '../../Redux/Actions/ProductViewActions';
import { GET_GRAPH_DATA } from '../../base/constants/apiEndPoints';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../base/utils/utils';

export const PriceHistory = ({ watch_id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getGraphData = () => {
    dispatch(fetchGraphData(`${GET_GRAPH_DATA}${watch_id}/`));
  };
  const graphData = useSelector((state) => state?.productViewReducer?.graphData);
  const watch_type = useSelector((state) => state?.inventoryReducer?.watchType);
  let TYPE = watch_type;
  useEffect(() => {
    getGraphData();
  }, []);
  return (
    <section className='price_history'>
      <h3>listing history</h3>
      <div className='row_price'>
        <div className='price_box'>
          <img src={icons.PriceIcon} alt='' />
          <div className='price'>
            <h6>No of days listed </h6>
            <p>{graphData?.graph_data?.no_of_days || 0} days on POST</p>
          </div>
        </div>
        <div className='price_box'>
          <img src={icons.PriceIcon} alt='' />
          <div className='price'>
            <h6>Price history</h6>
            <p>Price dropped by ${graphData?.graph_data?.dropped_price || 0}</p>
          </div>
        </div>
        <div className='need_reach '>
          <p>
             <button onClick={() => navigate('/contact')}> Contact Us</button>{' '}
          </p>
        </div>
      </div>
      <div className='need_reach need_reach_show'>
        <p>
           <button onClick={() => navigate('/contact')}> Contact us</button>{' '}
        </p>
      </div>

      <div className='position-relative'>
        <div className={TYPE === 'ico_accept' ? 'line_chart_blur' : 'line_chart'}>
          <ul>
            <li>
              ORIGINAL: <span>${graphData?.graph_data?.original || 0}</span>{' '}
            </li>
            <li>
              CURRENT: <span>${graphData?.graph_data?.current_price || 0}</span>
            </li>
          </ul>
          <Linechart graphData={graphData} />
          <p className='chart_date mt-3'>
            Date : {formatDate(graphData?.graph_data?.start_date)} -{' '}
            {formatDate(graphData?.graph_data?.end_date)}
          </p>
          <p className='chart_date price'>Views</p>
        </div>
        {/* {TYPE === 'ICO' && <p className='bg-text'>Graph data will be available after 48 hrs.</p>} */}
        {TYPE === 'ico_accept' && (
          <Box className='graph_box'>
            <div className='graph_container'>
              <Typography className='graph_modal_text'>
                Graph data will be available after 48 hrs.
              </Typography>
            </div>
          </Box>
        )}
      </div>
    </section>
  );
};
