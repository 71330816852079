import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { icons } from '../../base/constants/ImageConstants';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { validationBuyNowSchema } from '../../base/validations';
import { useDispatch, useSelector } from 'react-redux';
import { handleBidderValidation, postFormData, validateEmail } from '../../Redux/Actions';
import {
  BUY_NOW_URL,
  SEND_OTP_BUY_NOW_URL,
  VALIDATE_BIDDER_URL,
  VALIDATE_USER_EMAIL_URL,
  VERIFY_OTP_BUY_NOW_URL,
} from '../../base/constants/apiEndPoints';
import { openDetailModal, pageRefresh } from '../../Redux/Actions/AppAction';
import { LoginDrawers } from './LoginDrawers';
import {
  CCC_ACCEPT,
  CCC_COMPLETE,
  ICO_ACCEPT,
  refresh_token,
  Regular,
  token,
} from '../../base/constants/AppConstant';
import {
  BUY_NOW,
  BUY_NOW_BEFORE_LOGIN,
  CONFIRM_USER_EXISTS,
} from '../../base/constants/modalTitles';
import { isAuthenticated } from '../../base/utils/utils';
import { VALIDATE_USER_EMAIL } from '../../Redux/Types';

export const BuyNowDrawer = ({ watch_id, onRefresh, userData, stepValue }) => {
  const watch_type = useSelector((state) => state?.inventoryReducer?.watchType);
  const isEmailExist = useSelector((state) => state?.userReducer?.isEmailExist);

  const [state, setState] = React.useState({
    right: false,
  });
  const [otpverify, setOtpverify] = React.useState(false);
  const [isOtpValid, setIsOtpValid] = React.useState(false);
  const [email, setEmail] = React.useState();

  const TYPE = watch_type;
  const dispatch = useDispatch();

  const initialValues = {
    name: '',
    email: '',
    cell_phone: '',
    verify_OTP: '',
    offer_price: '',
  };

  const handleDrawer = () => {
    setState({ right: true });
  };

  const handleClick = () => {
    const filterResponse = {
      title: `$${Math.round(stepValue)?.toLocaleString('en-US')}`,
      // message: `Your current win rate is ${winRate}%`,
      action: BUY_NOW_BEFORE_LOGIN,
      showButtons: true,
      success: 'pending',
      data: handleDrawer,
    };
    dispatch(openDetailModal(filterResponse));
  };

  const handleKeyPress = (e) => {
    if (!isOtpValid) {
      e.preventDefault();
    }
  };

  const handleCCCBuyNow = async (userDetails) => {
    const data = {
      name: `${userData?.first_name} ${userData?.last_name}`,
      offer_price: stepValue,
      email: userData?.email,
      cell_phone: userData?.phone_number,
      watches: watch_id,
    };
    const response = await dispatch(
      handleBidderValidation(VALIDATE_BIDDER_URL, watch_id, {
        email: userData?.email,
        phone_number: userData?.phone_number,
      }),
    );
    if (response?.data?.status_code === 400) {
      const filterResponse = {
        title: `$${Math.round(stepValue)?.toLocaleString('en-US')}`,
        action: BUY_NOW,
        message: response?.data?.errors?.non_field_errors[0],
        showButtons: true,
        success: 'pending',
        data,
      };
      dispatch(openDetailModal(filterResponse));
    } else if (response?.data?.status_code === 200) {
      if (isEmailExist) {
        const postData = {
          name: `${userDetails?.first_name} ${userDetails?.last_name}`,
          offer_price: stepValue,
          email: userDetails?.email,
          cell_phone: userDetails?.phone_number,
          watches: watch_id,
        };
        const response = await dispatch(postFormData(BUY_NOW_URL, postData));
        if (response?.data?.status_code === 400) {
          const filterResponse = {
            // title,
            message: 'Something went wrong',
            success: false,
          };
          dispatch(openDetailModal(filterResponse));
        }
        await dispatch(pageRefresh());
      } else {
        const filterResponse = {
          title: `$${Math.round(stepValue)?.toLocaleString('en-US')}`,
          action: BUY_NOW,
          // message: `Your current win rate is ${winRate}%`,
          showButtons: true,
          success: 'pending',
          data,
        };
        dispatch(openDetailModal(filterResponse));
      }
    }

    onRefresh();
  };

  // onSubmit

  const onSubmit = async (values, submitProps) => {
    setOtpverify(false);
    setIsOtpValid(false);
    var data = {
      offer_price: stepValue,
      name: values.name,
      email: values.email.toLowerCase(),
      cell_phone: `${'+1'}${values.cell_phone}`,
      watches: watch_id,
    };
    if (TYPE === CCC_ACCEPT || TYPE === ICO_ACCEPT) {
      const response = await dispatch(
        handleBidderValidation(VALIDATE_BIDDER_URL, watch_id, {
          email: values.email.toLowerCase(),
          phone_number: values.cell_phone,
        }),
      );
      if (response?.data?.status_code === 400) {
        const filterResponse = {
          title: `$${Math.round(stepValue)?.toLocaleString('en-US')}`,
          action: BUY_NOW,
          message: response?.data?.errors?.non_field_errors[0],
          showButtons: userData?.user_type === null ? false : true,
          success: userData?.user_type === null ? false : 'pending',
          data: data,
        };
        dispatch(openDetailModal(filterResponse));
        await dispatch(pageRefresh());
        setState({ right: false });
        submitProps.setSubmitting(false);
        submitProps.resetForm();
      } else {
        const response = await dispatch(postFormData(BUY_NOW_URL, data));
        if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
          const filterResponse = {
            title: 'Your offer is successfully submitted',
            action: 'OK',
            message: (
              <>
                <p>Your login information will be :- </p>
                <p>Email: {values.email}</p>
                <p>Password: Phone number ({values.cell_phone})</p>
              </>
            ),
            success: true,
          };
          await dispatch(openDetailModal(filterResponse));
          localStorage.setItem(token, response?.data?.data?.user_token?.access);
          localStorage.setItem(refresh_token, response?.data?.data?.user_token?.refresh);
          setState({ right: false });
          dispatch(pageRefresh());
          onRefresh();
          submitProps.setSubmitting(false);
          submitProps.resetForm();
        } else {
          const filterResponse = {
            // title: 'Place Offer!',
            message: response?.data?.errors?.non_field_errors[0],
            success: false,
          };
          await dispatch(openDetailModal(filterResponse));
          setState({ right: false });
          submitProps.setSubmitting(false);
          submitProps.resetForm();
        }
      }
    }
  };

  const toggleDrawer = () => (event) => {
    setIsOtpValid(false);
    if (userData?.user_type === Regular && TYPE === ICO_ACCEPT) {
      const filterResponse = {
        message: 'You are not assigned dealer, probably auction will be available after 48 hrs',
        success: false,
      };
      dispatch(openDetailModal(filterResponse));
    } else if (TYPE === CCC_COMPLETE) {
      const filterResponse = {
        message: 'Watch is sold',
        success: false,
      };
      dispatch(openDetailModal(filterResponse));
    } else if (isAuthenticated() && TYPE === CCC_ACCEPT) {
      handleCCCBuyNow();
    } else {
      handleClick();
      setOtpverify(false);
    }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  };

  const onChangeState = () => {
    setState({ ...state, right: false });
    dispatch({ type: VALIDATE_USER_EMAIL, payload: false });
  };

  const sendOTP = async (phoneNumber) => {
    const data = {
      phone_number: `${'+1'}${phoneNumber}`,
    };
    const response = await dispatch(postFormData(SEND_OTP_BUY_NOW_URL, data));
    if (response?.status === 200) {
      setOtpverify(true);
    } else if (response?.status === 400) {
      setOtpverify(false);
    }
  };

  const handleFieldClear = (setFieldValue) => {
    setFieldValue('email', '');
  };

  const handleVerifyEmail = async (value, setFieldValue) => {
    await setFieldValue('email', value?.trim());
    const emailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (emailRegex.test(value?.toLowerCase())) {
      setEmail(value);
      const response = await dispatch(
        validateEmail(`${VALIDATE_USER_EMAIL_URL}${value?.toLowerCase()}`),
      );
      if (response?.status_code === 400) {
        const filterResponse = {
          title: (
            <div className='col-md-12 mt-3 mb-3'>
              <h3 style={{ fontSize: 23, color: '#fff', fontWeight: 400 }}>
                You are existing user
              </h3>
              <p
                style={{
                  display: 'block',
                  textAlign: 'center',
                  width: '100%',
                  fontSize: 16,
                  color: '#fff',
                  padding: '10px 0px',
                  fontWeight: 600,
                }}
              >
                Email: {value}
              </p>{' '}
              <p
                style={{
                  display: 'block',
                  textAlign: 'center',
                  width: '100%',
                  fontSize: 16,
                  color: '#fff',
                  fontWeight: 600,
                }}
              >
                Password: XXXXXXXX
              </p>
            </div>
          ),
          action: CONFIRM_USER_EXISTS,
          // message: `Email : ${value}  Password : XXXXXXXX`,
          showButtons: true,
          success: 'pending',
          data: () => handleFieldClear(setFieldValue),
        };
        dispatch(openDetailModal(filterResponse));
      }
    }
  };

  const handleInpuChange = (event, setFieldValue) => {
    if (event?.target?.selectionStart === 0 && event?.code === 'Space') {
      event.preventDefault();
    } else {
      setFieldValue(event.target.name, event.target.value);
    }
  };

  // verify otp Buy Now

  const verifyOTP = async (phoneNumber, OTP) => {
    const data = {
      phone_number: `${'+1'}${phoneNumber}`,
      code: OTP,
    };
    const response = await dispatch(postFormData(VERIFY_OTP_BUY_NOW_URL, data));
    if (response?.data?.status_code === 200) {
      setIsOtpValid(true);
    }
  };

  const list = (anchor) =>
    !isEmailExist ? (
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role='presentation'
        className='drawer_box'
      >
        <img
          onClick={() => setState({ ...state, [anchor]: false })}
          src={icons.closeIcon}
          alt=''
          className='close_btn'
        />
        <h2>OFFERS</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationBuyNowSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, dirty, isValid, setFieldValue }) => (
            <Form className='formBody' autoComplete='off'>
              <InputLabel htmlFor='outlined-adornment-amount' error={errors.name && touched.name}>
                Name
              </InputLabel>
              <Field name='name'>
                {({ field }) => (
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    startAdornment={<InputAdornment position='start'></InputAdornment>}
                    label='Name'
                    error={errors.name && touched.name}
                    {...field}
                    onChange={(e) => setFieldValue('name', e.target.value?.trim())}
                  />
                )}
              </Field>
              <ErrorMessage name='name'>
                {(msg) => (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '11px',
                      marginTop: '5px',
                    }}
                  >
                    {msg}
                  </div>
                )}
              </ErrorMessage>
              <InputLabel htmlFor='outlined-adornment-amount' error={errors.email && touched.email}>
                Email
              </InputLabel>
              <Field name='email'>
                {({ field }) => (
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    startAdornment={<InputAdornment position='start' />}
                    error={errors.email && touched.email}
                    label='email'
                    {...field}
                    onChange={(e) => {
                      handleVerifyEmail(e.target.value, setFieldValue, errors);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage name='email'>
                {(msg) => (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '11px',
                      marginTop: '5px',
                    }}
                  >
                    {msg}
                  </div>
                )}
              </ErrorMessage>
              <div className='position-relative cell_phone'>
                <InputLabel
                  htmlFor='outlined-adornment-amount'
                  error={errors.cell_phone && touched.cell_phone}
                >
                  Cell Phone
                </InputLabel>
                <Field name='cell_phone'>
                  {({ field }) => (
                    <OutlinedInput
                      type='tel'
                      id='outlined-adornment-amount'
                      startAdornment={<InputAdornment position='start'></InputAdornment>}
                      error={errors.cell_phone && touched.cell_phone}
                      label='cellphone'
                      {...field}
                      onChange={(e) => setFieldValue('cell_phone', e.target.value?.trim())}
                    />
                  )}
                </Field>
                <ErrorMessage name='cell_phone'>
                  {(msg) => (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '11px',
                        marginTop: '5px',
                      }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
                <div>
                  <Button
                    disabled={!values.cell_phone || errors.cell_phone}
                    className='sendOTP'
                    onClick={() => sendOTP(values.cell_phone)}
                  >
                    {otpverify ? 'RE-SEND' : 'SEND CODE'}
                  </Button>
                  <div className='position-relative'>
                    <>
                      <InputLabel
                        htmlFor='outlined-adornment-amount'
                        error={errors.verify_OTP && touched.verify_OTP}
                      >
                        Verify Code
                      </InputLabel>
                      <Field name='verify_OTP'>
                        {({ field }) => (
                          <OutlinedInput
                            type='tel'
                            id='outlined-adornment-amount'
                            startAdornment={<InputAdornment position='start'></InputAdornment>}
                            error={errors.verify_OTP && touched.verify_OTP}
                            label='Verify Code'
                            {...field}
                            onKeyDown={(e) => handleInpuChange(e, setFieldValue)}
                          />
                        )}
                      </Field>
                      <ErrorMessage name='verify_OTP'>
                        {(msg) => (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '11px',
                              marginTop: '5px',
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                      <Button
                        className={isOtpValid ? 'verify-sucess' : 'verifyOTP'}
                        disabled={!values.verify_OTP || errors.verify_OTP}
                        onClick={() => verifyOTP(values.cell_phone, values.verify_OTP)}
                      >
                        {isOtpValid ? (
                          <img src={icons?.SuccessIcon} style={{ width: '30%' }} />
                        ) : (
                          'VERIFY CODE'
                        )}
                      </Button>
                    </>
                  </div>
                </div>
                <button
                  disabled={!isOtpValid || !(dirty && isValid)}
                  type='submit'
                  className={!isOtpValid || !(dirty && isValid) ? 'disabled-btn' : 'mt-4'}
                  onKeyPress={(e) => handleKeyPress(e)}
                >
                  SUBMIT OFFER
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className='mistake_box'>
          <img src={icons.Dangor} alt='' />
          <p>Do not make mistake bid, POST monitors abuse bidders</p>
        </div>
      </Box>
    ) : (
      <LoginDrawers
        onChangeState={onChangeState}
        watch_id={watch_id}
        email={email}
        isEmailExist={isEmailExist}
        handleCCCBuyNow={handleCCCBuyNow}
      />
    );
  const handleDrawerClose = () => {
    setIsOtpValid(false);
    setState({ right: false });
    dispatch({ type: VALIDATE_USER_EMAIL, payload: false });
  };
  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className='submit_offer_div'>
            {/* <p>USD $ {ask_price ? Math.floor(ask_price) : '224,000'}</p> */}
            <Button disabled={!stepValue} onClick={toggleDrawer(anchor, true)}>
              SUBMIT OFFER
            </Button>
          </div>
          <Drawer anchor={anchor} open={state[anchor]} onClose={() => handleDrawerClose()}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};
