import getAxios from '../../base/axos.config';
import { SET_APPRAISAL_DATA } from '../Types';
const api = getAxios();
export const postFormData = (url, data) => async () => {
  return await api
    .post(url, data)
    .then((response) => response)
    .catch((error) => error);
};
export const confirmPasswordApi = (url, data) => async () => {
  return await api
    .patch(url, data)
    .then((response) => response)
    .catch((error) => error);
};
export const changePasswordApi = (url, data) => async () => {
  return await api
    .put(url, data)
    .then((response) => response)
    .catch((error) => error);
};

export const handleBidderValidation = (url, watch_id, data) => async () => {
  return await api
    .post(`${url}${watch_id}/`, data)
    .then((response) => response)
    .catch((error) => error);
};
export const repostWatch = (url, id) => async () => {
  return await api
    .post(`${url}${id}/`)
    .then((response) => response)
    .catch((error) => error);
};

export const handleAppraisalData = (data) => {
  return {
    type: SET_APPRAISAL_DATA,
    payload: data,
  };
};
export const validateEmail = (url) => async () => {
  return await api
    .get(url)
    .then((response) => response?.data)
    .catch((error) => error?.data);
};
