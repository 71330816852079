export const manuFacturerOptions = [
    'Andersen Geneve',
    'Audemars Piguet',
    'Abraham-Louis Breguet',
    'A. Lange & Söhne',
    'Bell & Ross',
    'Blancpain',
    'Bovet Fleurier',
    'Breguet',
    'Breitling',
    'Bremont Watch Company',
    'Bulgari',
    'Carl F. Bucherer',
    'Cartiesr SA',
    'Chanel',
    'Chopard',
    'Chronoswiss',
    'Concord watch',
    'Corum',
    'F.P. Journe',
    'Dior',
    'Doxa S.A.',
    'George Daniels',
    'Girard-Perregaux',
    'Glashütte Original',
    'Greubel Forsey',
    'International Watch Company',
    'Jaeger-LeCoultre',
    'John Arnold',
    'Jacob & Co',
    'Konstantin Chaykin',
    'Frank Muller',
    'Romain Gauthier',
    'Hamilton Watch Company',
    'Hermès',
    'Hublot',
    'Pierre Jaquet-Droz',
    'Lang & Heyne',
    'Linde Werdelin',
    'Longines',
    'Louis Erard',
    'Louis Vuitton',
    'Maurice Lacroix',
    'MB&F',
    'MeisterSinger',
    'Ming',
    'Louis Moinet',
    'Montblanc',
    'Montegrappa',
    'H. Moser & Cie',
    'Franck Muller',
    'Omega SA',
    'Oris',
    'Panerai',
    'Parmigiani Fleurier',
    'Patek Philippe',
    'Piaget',
    'Richard Mille',
    'Rolex',
    'Roger Dubuis',
    'Daniel Roth',
    'Sinn (watchmaker)',
    'Roger W. Smith',
    'Tiffany & Co',
    'Tudor',
    'Ulysse Nardin',
    'Universal Genève',
    'Urwerk',
    'Vacheron Constantin',
    'Harry Winston',
    'Zenith',
  ];