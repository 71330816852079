import getAxios from '../../base/axos.config';
import * as TYPE from '../Types';
const api = getAxios();
export const fetchInventoryData = (url, sort) => async (dispatch) => {
  const params = {
    ...sort,
    page: sort.page || 1,
    brand_slug: sort?.brand,
  };
  delete params.brand;
  await api
    .get(url + new URLSearchParams(params)?.toString())
    .then(async (response) => {
      await dispatch({
        type: TYPE.FETCH_INVENTORY_PRODUCTS_SUCCESS,
        payload: response?.data,
      });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.FETCH_INVENTORY_PRODUCTS_ERROR, payload: error?.statusText });
    });
};
export const addToFavourite = (url, data) => async () => {
  return await api
    .post(url, data)
    .then((response) => response)
    .catch((error) => error);
};

export const inventoryFilters = (url) => async (dispatch) => {
  await api
    .get(url)
    .then(async (response) => {
      await dispatch({ type: TYPE.INVENTORY_FILTER_DATA_SUCCESS, payload: response?.data });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.INVENTORY_FILTER_DATA_ERROR, payload: error?.statusText });
    });
};

export const inventoryFilterDetail = (url) => async (dispatch) => {
  await api
    .get(url)
    .then(async (response) => {
      await dispatch({
        type: TYPE.PRODUCT_INVENTORY_DETAIL_SUCCESS,
        payload: response?.data,
      });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.PRODUCT_INVENTORY_DETAIL_ERROR, payload: error?.statusText });
    });
};
export const getWatchType = (data) => {
  return {
    type: TYPE.GET_WATCH_TYPE,
    payload: data,
  };
};
export const fetchFavoriteData = (url, sort) => async (dispatch) => {
  const params = {
    ...sort,
    page: sort.page || 1,
    brand_slug: sort?.brand || '',
  };
  delete params.brand;
  await api
    .get(url + new URLSearchParams(params)?.toString())
    .then(async (response) => {
      await dispatch({
        type: TYPE.GET_FAVORITE_SUCCESS,
        payload: response?.data,
      });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.GET_FAVORITE_ERROR, payload: error?.statusText });
    });
};
