import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CONFIRM_PASSWORD_URL } from '../../base/constants/apiEndPoints';
import { confirmPasswordApi } from '../../Redux/Actions';
import { confimPasswordSchema } from '../../base/validations';
import * as Path from '../../base/constants/RoutePath';
import './ConfirmPassword.css';
import { responseHandler } from '../../base/utils/utils';
import { UPDATE_PASSWORD } from '../../base/constants/modalTitles';

export const ConfirmPassword = () => {
  const { uidb64, token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const onSubmit = async (values, submitProps) => {
    const body = {
      new_password: values.newPassword,
      confirm_password: values.confirmPassword,
      uidb64: uidb64,
      token: token,
    };
    const response = await dispatch(confirmPasswordApi(CONFIRM_PASSWORD_URL, body));
    responseHandler(UPDATE_PASSWORD, response);
    if (response?.data.status_code === 200 || response?.data.status_code === 201) {
      navigate(Path.LOGIN_PAGE);
    }
    submitProps.setSubmitting(false);
    submitProps.resetForm();
  };

  return (
    <div>
      <section className='login_banner'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-12'>
              <div className='login_bcintent'>
                <h1>Welcome to POST</h1>
                <p>Enter detail below to create New Password</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='forgot-form'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-9 m-auto text-center'>
              <Formik
                initialValues={initialValues}
                validationSchema={confimPasswordSchema}
                onSubmit={onSubmit}
              >
                <Form className='formBody' autoComplete='off'>
                  <div className='forgot_pass'>
                    <h2>Change Password</h2>
                    <div className='form-group'>
                      <label>NEW PASSWORD</label>
                      <Field name='newPassword'>
                        {({ field }) => (
                          <input
                            type='password'
                            name='newPassword'
                            className='form-control'
                            defaultValue={initialValues.newPassword}
                            placeHolder='**********'
                            {...field}
                          />
                        )}
                      </Field>
                      <ErrorMessage name='newPassword'>
                        {(msg) => (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '11px',
                              marginTop: '2px',
                              textAlign: 'left',
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className='form-group'>
                      <label>CONFIRM PASSWORD</label>
                      <Field name='confirmPassword'>
                        {({ field }) => (
                          <input
                            type='password'
                            name='confirmPassword'
                            className='form-control'
                            defaultValue={initialValues.confirmPassword}
                            placeHolder='**********'
                            {...field}
                          />
                        )}
                      </Field>
                      <ErrorMessage name='confirmPassword'>
                        {(msg) => (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '11px',
                              marginTop: '2px',
                              textAlign: 'left',
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <button type='submit' className='forgotbtn'>
                      Save
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
