import getAxios from '../../base/axos.config';
import * as TYPE from '../Types';
const api = getAxios();
export const fetchFeaturedData = (url, sort) => async (dispatch) => {
  await api
    .get(`${url}ordering=${sort.ordering}&feed=${sort.feed}&page=${sort.page || 1}`)
    .then(async (response) => {
      await dispatch({
        type: TYPE.GET_FEATURED_SUCCESS,
        payload: response?.data,
      });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.GET_FEATURED_ERROR, payload: error?.statusText });
    });
};

export const fetchJustDroppedata = (url, sort) => async (dispatch) => {
  await api
    .get(`${url}ordering=${sort.ordering}&feed=${sort.feed}&page=${sort.page || 1}`)
    .then(async (response) => {
      await dispatch({
        type: TYPE.GET_JUST_DROPPED_SUCESS,
        payload: response?.data,
      });
    })
    .catch(async (error) => {
      await dispatch({ type: TYPE.GET_JUST_DROPPED__ERROR, payload: error?.statusText });
    });
};
export const getTransactions = (url) => async (dispatch) => {
  return await api
    .get(url)
    .then(
      async (response) =>
        await dispatch({
          type: TYPE.GET_TRANSACTIONS_SUCCESS,
          payload: response?.data?.data,
        }),
    )
    .catch(
      async (error) =>
        await dispatch({
          type: TYPE.GET_TRANSACTIONS_ERROR,
          payload: error?.statusText,
        }),
    );
};
export const getBestDeal = (url) => async (dispatch) => {
  return await api
    .get(url)
    .then(
      async (response) =>
        await dispatch({
          type: TYPE.GET_BEST_DEAL_SUCESS,
          payload: response?.data?.data,
        }),
    )
    .catch(
      async (error) =>
        await dispatch({
          type: TYPE.GET_BEST_DEAL_ERROR,
          payload: error?.statusText,
        }),
    );
};
