import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { icons } from '../../base/constants/ImageConstants';
import './JustDrop.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJustDroppedata } from '../../Redux/Actions';
import { INVENTORY_LIST_URL } from '../../base/constants/apiEndPoints';
import { AddToFavourite } from '../../base/utils/utils';
import { useDeviceDetect } from '../../hooks';
export const JustDropSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useDeviceDetect();
  const navigateRoutes = (route) => {
    navigate(route);
  };
  const apiSort = {
    ordering: '',
    feed: '',
    brand: '',
  };
  const justDroppedData = useSelector((state) => state?.landingReducer?.justDroppedData?.results);
  useEffect(() => {
    isMobile &&
      dispatch(
        fetchJustDroppedata(INVENTORY_LIST_URL, {
          ...apiSort,
          ordering: 'newest',
          feed: 'just_dropped',
        }),
      );
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 1000,
    pauseOnHover: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: 'linear',

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          className: 'center',
          centerMode: true,
          centerPadding: '30px',
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          loop: true,
          className: 'center',
          centerMode: true,
          centerPadding: '30px',
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          className: 'center',
          centerMode: false,
          centerPadding: 'none',
          infinite: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          className: 'center',
          centerMode: false,
          centerPadding: 'none',
        },
      },
    ],
  };
  return (
    <div className='slider-container just_droped_slider'>
      <Slider {...settings}>
        {justDroppedData && justDroppedData?.length > 0 ? (
          justDroppedData?.slice(0, 4)?.map((data) => (
            <div key={data?.id}>
              <div
                className='drop_fix_div'
                onClick={() => navigateRoutes(`/productview/watch/${data?.id}`)}
              >
                <div className='image_div_drop'>
                  <img src={data?.main_image || icons.Drop1} className='drop_img' />
                </div>

                <a href='#'>
                  <h5>
                    {data?.brand || 'ROLEX'} | {data?.model || '126610LV'}
                  </h5>

                  {/* <h6>Rolex Submariner Date</h6> */}

                  {/* <p>{data?.model || '126610LV'}</p> */}
                  <h3>
                    {data?.state || 'Ca'} | {data?.city || 'Whittier'}
                  </h3>
                  <span>${Math.floor(data?.ask_price)?.toLocaleString() || '22,133'}</span>
                </a>
              </div>
              <img
                src={data?.is_saved ? icons.Filled_Bookmark : icons.Bookmark}
                className='bookmark_img'
                alt='Bookmark'
                onClick={() => AddToFavourite(data?.id, true, navigate)}
              />
              {/* <img src={icons.StarIcon2} className='star_icon' alt='' /> */}
            </div>
          ))
        ) : (
          <p className='no_justDropped'>No watches available.</p>
        )}
      </Slider>
    </div>
  );
};
