export const token = 'token';
export const refresh_token = 'refresh';
export const userId = 'user_id';
export const Regular = 'Regular';
export const Dealer = 'Dealer';
export const ICO_ACCEPT = 'ico_accept';
export const CCC_ACCEPT = 'ccc_accept';
export const CCC_COMPLETE = 'ccc_complete';
export const brands = {
  PATEK_PHILIPPE: 'PATEK_PHILIPPE',
  RICHARD_MILLE: 'RICHARD_MILLE',
  VACHERON_CONSTANTIN: 'VACHERON_CONSTANTIN',
  AudemarsPiguet: 'AudemarsPiguet',
  PIAGET: 'PIAGET',
  Jaeger_LeCoultre: 'Jaeger-_LeCoultre',
  Parmigiani_Fleurier: 'Parmigiani_Fleurier',
  Rolex: 'rolex',
  Breguet: 'Breguet',
  AndersenGenevelt: 'AndersenGenevelt',
};

export const CONDITION_EXCELLENT = `This watch is marked as being in excellent condition with virtually no signs of wear or use. This is as close to a new watch as it gets.`;

export const CONDITION_GOOD = {
  p1: `This watch is marked as being in “good condition” which means it has been previously loved but has been worn sparingly and treated with care.`,

  p2: `No large dings or dents are to be expected, only small hairline scratches and wear consistent with that of a lightly worn watch.`,
};

export const CONDITION_AVERAGE = {
  p1: `This watch is marked as being in “average condition” which means that this watch has been worn with higher frequency but still retains strong condition.`,

  p2: `Expect a good amount of wear marks consistent with that of a daily wear watch. Scuffs will be more obvious and some small dings and dents may be present.`,
};

export const CONDITION_POOR = `This watch is marked as being in “poor condition”. Poor condition refers directly to the amount of surface wear on a watch and does not take into consideration “vintage” watches which will usually have more wear due to age. Watches of this condition will be heavily worn with major surface wear and possible dents varying in size.`;
