import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { icons } from '../../base/constants/ImageConstants';
import { useDispatch, useSelector } from 'react-redux';
import { GET_TRANSACTIONS_URL } from '../../base/constants/apiEndPoints';
import { getTransactions } from '../../Redux/Actions';
import './TransactionSlider.css';
export const TransactionSlider = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTransactions(GET_TRANSACTIONS_URL));
  }, []);
  const transactionData = useSelector((state) => state?.landingReducer?.transactionData);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className='slider-container fashion_slider_box'>
      <Slider {...settings}>
        {transactionData?.map((data) => (
          <div key={data?.id} className='fashion_slider'>
            <img src={data?.image || icons.FashionImage1} />
            <div className='transaction_detail'>
              <h3>
                {data?.year} | {data?.brand} | {data?.model}
              </h3>
              <span>
                HIGHEST OFFER: ${Math.round(data?.highest_offer)?.toLocaleString('en-US')}
              </span>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
